<template>
  <div>
<div v-show="false">{{searchValue}}</div>
    <h4 v-if="selected.length > 0">
      {{ selected[0].title }}
      <b-icon @click="removeItem" icon="x-circle"></b-icon>
    </h4>

    <b-form-input
      v-model="searchValue"
      class="topicSearchBox mt-2"
      type="text"
      debounce="0"
    ></b-form-input>
    <b-table v-if="searchValue.length > 0"
      id="flying"
      thead-class="no-th"
      hover
      selectable
      select-mode="single"
      @row-selected="onRowSelected"
      :fields="fields"
      :items="results"
    ></b-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      searchValue: "",
      articleList: [],
      message: "",
      results: [],
      selected: [],
      fields: ["title"],
    };
  },
  methods: {
    removeItem() {
      this.selected = [];
    },
    getWikipediaResults() {
      let vm = this;
      vm.message = "";
      axios
        .get("https://en.wikipedia.org/w/api.php", {
          params: {
            format: "json",
            action: "query",
            generator: "search",
            gsrnamespace: 0,
            gsrsearch: this.searchValue,
            gsrlimit: 10,
            prop: "pageimages|extracts",
            pilimit: "max",
            piprop: "original",
            exintro: true,
            explaintext: true,
            exsentences: 1,
            exlimit: "max",
            origin: "*",
          },
        })
        .then((res) => {
          if (res.data.query && res.data.query.pages) {
            vm.articleList = res.data.query.pages;
            vm.message = "";
          } else {
            vm.message = "No Articles Found";
            vm.articleList = [];
          }
        })
        .catch((error) => {
          console.error(error);
        });

      this.results = Object.values(this.articleList).map((el) => ({
        title: el.title,
        extract: el.extract,
        pageid: el.pageid,
      }));
    },
    onRowSelected(items) {
      this.selected = items;
      this.searchValue = "";
      this.$emit("setTopic", this.selected);
    },
    setExistingTopic() {
      if ("twenty-minute-talk-wiki" in this.$store.getters.getAllChoices) {
        this.selected = this.$store.getters.getAllChoices["twenty-minute-talk-wiki"];
      }
    },
  },
  watch: {
    searchValue() {
      this.getWikipediaResults();
    },
  },
  created() {
    this.setExistingTopic();
  },
};
</script>

<style scoped>
.form-control {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem !important;
  /* font-size: 1rem !important; */
  font-weight: 400;
  line-height: 1.5 !important;
  color: #713e9e !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #713e9e !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  float: left;
}
</style>
