<template>
  <div class="dashboard page">
    <b-container fluid="sm">
      <b-row
        ><b-col><h2></h2> </b-col>
      </b-row>
      <hr />
      <InstructionsBox></InstructionsBox>
      <div v-show="false">{{ launchKey }}</div>
      <div v-if="!show">
        We are still waiting for your data to processs. It will only take a few
        more seconds.
      </div>
      <b-row>
        <b-col sm="12" md="6"
          ><ButtonPanel
            class="mb-5"
            v-if="show"
            :completed="completedSections"
          ></ButtonPanel
        ></b-col>
        <b-col sm="12" md="6">
          <FactBox
            class="mb-5"
            @live="live"
            :narrativeFacts="narrativeFacts"
            :launchKey="launchKey"
          ></FactBox
        ></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  collection,
  getDoc,
  getDocs,
  doc,
  db,
  serverTimestamp,
  addDoc,
  setDoc,
  query,
  where,
} from "@/firebase";
import {
  websiteCollection,
  candidatesCollection,
  companiesCollection,
} from "@/db";
import { mapGetters, mapActions } from "vuex";
import InstructionsBox from "@/components/sections/InstructionsBox.vue";
//import Footer from "@/components/sections/Footer.vue";
import FactBox from "@/components/interfaces/FactBox6.vue";
import ButtonPanel from "@/components/interfaces/ButtonPanel.vue";
import terms from "@/assets/terms.json";
export default {
  components: { InstructionsBox, FactBox, ButtonPanel },
  data() {
    return {
      show: true,
      termValues: {},
      completedSections: [],
      launchKey: 0,
      done: {
        generalQuestions: false,
        specificQuestions: false,
        career: false,
        skills: false,
        blink: false,
        industries: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentPage",
      "getCurrentTagRef",
      "getFlow",
      "getCurrentAuthUser",
      "getUID",
      "getCurrentAuthUserName",
      "getAllFlowNumber",
      "getIndustryTags",
      "getExperiences",
      "getOnboardingCollection",
      "getList",
      "getCompanyList",
      "getBasicDataByName",
      "getNarrativeFacts",
    ]),
    getFirstName() {
      return this.getCurrentAuthUserName.split(" ")[0];
    },
    narrativeFacts() {
      return this.$store.getters.getNarrativeFacts;
    },
  },
  methods: {
    live(num) {
      this.launchKey = this.launchKey + num;
    },
  },
  created() {
    this.$store.dispatch("createNarrativeFacts");
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch("createNarrativeFacts");
    }, 3000);
  },
  watch: {
    narrativeFacts() {
      this.launchKey += 1;
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}
.text-right {
  float: right !important;
}
.btn-outline-purple:hover,
.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}

.btn-purple {
  background-color: #9a5bd2;
  color: white;
  width: 40% !important;
  height: 10em !important;
  line-height: 1.5 !important;
}
.btn-purple:hover,
.btn-purple:active {
  background-color: white;
  color: #9a5bd2;
  border-color: #713e9e;
}

.knowledge {
  border: 1em solid #713e9e;
  margin-top: 10em !important;
}

#how-we-know {
  margin-top: 10em !important;
}

@media only screen and (max-width: 599px) {
  /* For mobile phones: */
  .btn-purple {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 0.01em;
    padding-left: 0.01em;
    font-size: smaller;
    height: 20em !important;
    width: 48% !important;
  }
  .knowledge {
    font-size: smaller !important;
  }
}

.madlib {
  border-bottom: 3px solid #713e9e;
}

.about-you {
  line-height: 1.6;
}
</style>
