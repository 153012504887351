<template>
  <div>
      <b-container>
 <h4  class="mt-2 mb-3">Partner Dashboard for "Save The Sloths"</h4>
<hr class="mt-2 mb-3">
<h5 class="mt-4 mb-4">Your special referral URL: before.dev/ref=slothsarecool</h5>
          <b-card-group class="mt-4" deck>
              <b-card class="mt-2 mb-3">
                  <b-card-header>New Candidate Signups</b-card-header> <b-card-body><b-card-title>
        450
      </b-card-title><b-card-text>
        This is great traction for this month!
      </b-card-text></b-card-body></b-card>
      <b-card class="mt-2 mb-3">
                  <b-card-header>Candidate Active Users</b-card-header> <b-card-body><b-card-title>
        1,230
      </b-card-title><b-card-text>
        Your candidates are some of the best on the platform
      </b-card-text></b-card-body></b-card>
            <b-card class="mt-2 mb-3">
                  <b-card-header>New Company Signups</b-card-header> <b-card-body><b-card-title>
        17
      </b-card-title><b-card-text>
        This is kinda amazing!
      </b-card-text></b-card-body></b-card>
            <b-card class="mt-2 mb-3">
                  <b-card-header>Paid Active Companies</b-card-header> <b-card-body><b-card-title>
        35
      </b-card-title><b-card-text>
        They are our favorite companies!
      </b-card-text></b-card-body></b-card>
            <b-card class="mt-2 mb-3">
                  <b-card-header>Monthly Referral Revenue</b-card-header> <b-card-body><b-card-title>
        $8,750
      </b-card-title><b-card-text>
        Next payment date: April 5
      </b-card-text></b-card-body></b-card>
            <b-card class="mt-2 mb-3">
                  <b-card-header>Total Referral Revenue Paid</b-card-header> <b-card-body><b-card-title>
        $53,500
      </b-card-title><b-card-text>
        Hot Dang!
      </b-card-text></b-card-body></b-card>
          </b-card-group>
          </b-container>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.card{
   min-width: 35% !important;
   border-color: #713e9e;
}

</style>