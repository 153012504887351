<template>
  <div class="general-skills">
    <b-container>
      <OnboardingHeader :title="title"></OnboardingHeader>
        <b-card no-body>
          <b-tabs card vertical>
            <b-tab
              v-for="skill in generalSkillsJSON"
              :key="skill.gsid"
              squared
              title-link-class="text-left"
              class="text-left"
            >
              <template #title
                ><b-row
                  ><b-col cols="2" class="float-left"
                    ><i :class="skill.icon + ` h3 mr-2 text-left`"></i></b-col
                  ><b-col cols="9">{{ skill.title }} </b-col></b-row
                >
              </template>
              <b-card-text>
                <b-row class="mb-5 purple">
                  <b-container class="mb-5 px-3 py-3 purple"
                    ><h6>{{ skill.explanation }}</h6></b-container
                  ></b-row
                >
                <b-row>
                  <b-col sm="12" md="12">
                    <b-row>
                      <h6 class="text-left mb-2">
                        {{ skill.range_skill_level_question }}
                      </h6>
                      <b-form-input
                        class="range mb-3"
                        variant="purple"
                        id="range-1"
                        min="1"
                        max="5"
                        v-model="range_skill_level_value"
                        type="range"
                      ></b-form-input>
                    </b-row>
                    <b-row>
                      {{ skill.binary_preference_question }}
                      <b-button-group class="mt-2">
                        <b-button
                          variant="outline-purple"
                          @click="preference = 'yes'"
                          >Yes</b-button
                        ><b-button
                          variant="outline-purple"
                          @click="preference = 'no'"
                          >No</b-button
                        >
                      </b-button-group>
                    </b-row>
                    <b-row v-if="preference === 'yes'">
                      <h6 class="text-left mt-2 mb-2">
                        {{ skill.positive_preference_question }}
                      </h6>
                      <b-form-input
                        class="range mb-3"
                        variant="purple"
                        id="range-2"
                        v-model="positive_preference_value"
                        min="1"
                        max="5"
                        type="range"
                      ></b-form-input>
                    </b-row>
                    <b-row v-if="preference === 'no'">
                      <h6 class="text-left mt-2 mb-2">
                        {{ skill.negative_preference_question }}
                      </h6>
                      <b-form-input
                        class="range mb-3"
                        variant="purple"
                        id="range-3"
                        v-model="negative_preference_value"
                        min="0"
                        max="5"
                        type="range"
                      ></b-form-input>
                    </b-row>
                    <b-row class="mt-2">
                      Are there any metrics or things you are particularly proud
                      of regarding {{ skill.title.toLowerCase() }}?
                      <b-row>
                        <b-button
                          class="mr-2 mb-1 mt-1"
                          variant="outline-purple"
                          v-for="(metric, m) in skill.metrics"
                          :key="m"
                          >{{ metric }}</b-button
                        >
                      </b-row>
                    </b-row></b-col
                  ></b-row
                ></b-card-text
              ></b-tab
            >
          </b-tabs>
        </b-card>

      <b-button
        @click="onSubmit()"
        size="lg"
        squared
        variant="outline-purple"
        class="save-button"
        ><b>Save</b> and Return to Dashboard</b-button
      >
    </b-container>
  </div>
</template>

<script>
import OnboardingHeader from "@/components/sections/OnboardingHeader.vue";
import generalSkillsJSON from "@/assets/general-skills.json";
export default {
  components: { OnboardingHeader },
  data() {
    return {
      generalSkillsJSON: generalSkillsJSON,
      generalSkills: {},
      currentCat: 0,
      currentQuestion: 0,
      range_skill_level_value: 3,
      positive_preference_value: 3,
      negative_preference_value: 3,
      oid: 3,
      step: "4",
      title: "Your General Skills.",
      time: "Less than 3 Minutes",
      taxonomy: "skills",
      tagsRef: "skills",
      preference: "",
      currentUser: this.$store.getters.getUID,
    };
  },
  methods: {
    getCurrentQuestion() {
      return;
    },
    async onSubmit() {
      this.$store.dispatch("updateValues", this.terms);
      this.$store.dispatch("updateChoices", this.choices);
      this.$store.dispatch("updateQuestionnaires", this.currentQuestionnaire);
      this.$store.dispatch("addSection", this.questionnaireID);
      //this.$store.dispatch("changeFlow", 1);
      this.$router.push("/dashboard");
    },
  },
};
</script>
<style>
.general-skills .btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}
.general-skills .purple {
  background-color: #713e9e;
  color: #fff;
}
.general-skills .btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
}
.general-skills .btn-outline-purple.active:hover,
.general-skills .btn-outline-purple:hover {
  color: #fff;
  background-color: #9a5bd2;
}
.general-skills .btn-outline-purple.save-button {
  background-color: #fff;
  color: #713e9e;
  height: 5em;
  width: 100%;
  margin-bottom: 4em;
   border-left: 4px solid #713e9e !important;
   border-right: 4px solid #713e9e !important;
   border-bottom: 4px solid #713e9e !important;
}
.general-skills .btn-outline-purple.save-button:hover {
  background-color: #713e9e;
  color: white;
  height: 5em;
  width: 100%;
  margin-bottom: 4em;
}

.general-skills .card{
    border: 4px solid #713e9e !important;
}
.general-skills li.nav-item {
    color: #fff !important;
    background-color: #713e9e !important;
}
.general-skills li.nav-item a{
    color: #fff;
    height: 6em !important;
    padding-top:2em !important;}
.general-skills li.nav-item.active .nav-link.active a{
    color: #713e9e !important;}

.general-skills .nav-tabs .nav-link.active, .general-skills .nav-tabs .nav-item.show .nav-link {
    color: #713e9e;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.general-skills ul#__BVID__21__BV_tab_controls_ {
    padding: 0px !important;
}
</style>
