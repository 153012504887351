<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Login</div>
          {{ current }}
          <div class="card-body">
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
            <form @submit.prevent>
              <div class="form-group row">
                <label for="email" class="col-md-4 col-form-label text-md-right"
                  >Email</label
                >

                <div class="col-md-6">
                  <input
                    @keypress.enter.prevent
                    id="email"
                    type="email"
                    class="form-control"
                    name="email"
                    value
                    required
                    autofocus
                    v-model="form.email"
                  />
                </div>
              </div>

              <div class="form-group row">
                <label
                  for="password"
                  class="col-md-4 col-form-label text-md-right"
                  >Password</label
                >

                <div class="col-md-6">
                  <input
                    id="password"
                    type="password"
                    class="form-control"
                    name="password"
                    required
                    v-model="form.password"
                  />
                </div>
              </div>

              <div class="form-group row mb-0">
                <div class="col-md-8 offset-md-4">
                  <button type="submit" class="btn btn-primary">Login</button>
                </div>
              </div>
            </form>
            {{ getUserID }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  auth,
  signInWithEmailAndPassword,
  signOut,
  collection,
  addDoc,
  db,
  doc,
  getDoc,
  serverTimestamp,
} from "@/firebase";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
      },
      current: '',
      error: null,
    };
  },
  methods: {
    submit() {
      signInWithEmailAndPassword(auth, this.form.email, this.form.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log(user.displayName)
          console.log(auth.currentUser)
          console.log(auth.currentUser.uid)
          this.current = user.displayName;
          this.$store.dispatch("changeAuthUser", user);
          this.updateStore(auth.currentUser.uid);
          //const dbUser = db.collection('users').doc(user.uid).get();

          this.$router.replace({ name: "Home" });
        })
        .catch((error) => {
          this.current = "no user / wrong email"
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    },
    logout() {
      signOut(auth)
        .then(() => {
          // Sign-out successful.
        })
        .catch((error) => {
          // An error happened.
        });
    },
    async updateStore(UID_FROM_LOGIN) {
      const dbUser = await doc(db, "users", UID_FROM_LOGIN);
      const userinfo = await getDoc(dbUser);

      if (userinfo) {
        console.log("Document data:", userinfo.data());
        const userData = userinfo.data();
        if(userData.candidateUID){
          this.$store.dispatch("changeUID", userData.candidateUID);
        } 
        } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    },
  },
  computed: {
    checkCurrentUser() {
      const user = auth.currentUser;
      if (user) {
        return user.uid;
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // ...
      } else {
        // No user is signed in.
        return "No one is logged in.";
      }
    },
    ...mapGetters(["getUID", "getCurrentAuthUserName"]),
  },
};
</script>
