<template>
  <div class="tags">
    <b-container>
      <OnboardingHeader
        :title="title"
      ></OnboardingHeader>
      <TagsBox
        :tagCategory="tagsRef"
        :title="title"
        :LikeFields="LikeFields"
        :DislikeFields="DislikeFields"
        :likesText="likesText"
        :dislikesText="dislikesText"
        :oid="oid"
      ></TagsBox>
    </b-container>
  </div>
</template>

<script>
import OnboardingHeader from "@/components/sections/OnboardingHeader.vue";
import TagsBox from "@/components/interfaces/TagsBox.vue";
export default {
  components: { OnboardingHeader, TagsBox },
  data() {
    return {
      currentCat: 0,
      oid: 3,
      step: "4",
      title: "Your Specific Skills.",
      time: "Less than 3 Minutes",
      taxonomy: "skills",
      tagsRef: "skills",
      LikeFields: ["Things I enjoy doing", "Category"],
      DislikeFields: ["Things I do not enjoy doing", "Category"],
      likesText:
        'This will be a list of your skills that you enjoy doing.',
      dislikesText:
        "This will be a list of your skills that you do not enjoy doing, and would not want a position which required you to do this.",
      currentUser: this.$store.getters.getUID,
    };
  },
};
</script>
<style scoped></style>
