<template>
  <div class="temp-landing">
    <b-container class="mt-5">
      <b-img
        class="w-75"
        :src="BeforeNameLogoPurple"
        fluid
        alt="Before Logo"
      ></b-img>
      <h1 class="mt-2 tagline">the talent platform for the modern workforce</h1>
    </b-container>

 <b-container
      ><h2 class="tagline mt-5">
        <b
          >You are more than your resume, and jobs are more than to-do lists.</b
        >
      </h2></b-container
    >

    <b-container class="mt-5 form-container">
      <b-form class="mx-auto">
        <b-form-group
          class="text-left"
          label="Join our wait list."
          label-for="input-email"
          description="Replace our email address with your own."
        >
          <b-form-input
            id="input-type"
            placeholder="questions@before.dev"
            v-model="email"
            type="email"
          >
          </b-form-input>
        </b-form-group>
                <b-form-group
          class="text-left"
          label="Who are you?"
          label-for="input-type"
        >
          <b-form-select v-model="selected" :options="options"></b-form-select>
        </b-form-group>
        <b-row class="text-left">
          Choose four icons from below to represent your perfect job, and drag them under the folder.
          </b-row>
          <b-col cols="12"
            ><i class="fa-duotone large fa-folder h1 mb-4"></i></b-col>
  

          <draggable
          class="pt-4 pb-4"
          :list="personArray"
          group="icons"
          @start="drag = true"
          @end="drag = false"
        ><b-row>
          <b-col v-for="(iconb,i) in personArray" :key="i"  class="float-left" cols="3"
            ><i :class="iconb" class="fa-duotone large h1 mb-4"></i></b-col
          ></b-row>
        </draggable>
      
        <b-button
          @click="submitInvite()"
          squared
          variant="outline-purple"
          class="mt-0"
          block
          >Join the list</b-button
        >
      </b-form>
    </b-container>

   
    <b-container class="mt-5">
      <b-row>
        <draggable
          :list="iconArray"
          group="icons"
          @start="drag = true"
          @end="drag = false"
        >
          <b-col v-for="(icona,i) in iconArray" :key="i"  class="float-right" cols="2"
            ><i :class="icona" class="fa-duotone h2 mb-4"></i></b-col
          >
        </draggable>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  doc,
  addDoc,
  collection,
  serverTimestamp,
  auth,
  createUserWithEmailAndPassword,
  getDoc,
  setDoc,
  db,
} from "@/firebase";
import draggable from "vuedraggable";
import { requestsCollection } from "@/db";
import { router } from "@/router";
import BeforeNameLogoPurple from "@/assets/before_full_logo_purple.png";
export default {
  components: { draggable },
  data() {
    return {
      BeforeNameLogoPurple: BeforeNameLogoPurple,
      email: "",
      personArray:[],
      iconArray: [
        "fa-microphone",
        "fa-typewriter",
        "fa-calculator",
        "fa-thought-bubble",
        "fa-microchip",
        "fa-clipboard-list",
        "fa-toolbox",
        "fa-users-between-lines",
        "fa-money-bills",
        "fa-award",
        "fa-book-open-reader",
        "fa-brain-circuit",
        "fa-chart-column",
        "fa-clapperboard",
        "fa-clock-five",
        "fa-coffee-pot",
        "fa-elephant",
        "fa-engine",
        "fa-family-pants",
        "fa-file-certificate",
        "fa-fingerprint",
        "fa-flask-vial",
        "fa-game-board",
        "fa-graduation-cap",
        "fa-hammer",
        "fa-handshake",
        "fa-island-tropical",
        "fa-keynote",
        "fa-lightbulb-on",
        "fa-list-check",
        "fa-location-check",
        "fa-notebook",
        "fa-object-intersect",
        "fa-palette",
        "fa-person-military-to-person",
        "fa-puzzle",
        "fa-robot",
        "fa-scale-balanced",
        "fa-shapes",
        "fa-tags",
        "fa-magic-wand-sparkles",
        "fa-yin-yang",
      ],
      selected: null,
      options: [
        { value: "candidate", text: "I am a potential candidate." },
        { value: "company", text: "I am a potential employer." },
        { value: "partner", text: "I am a potential strategic partner." },
        { value: "else", text: "I am potentially something else." },
      ],
    };
  },
  methods: {
    async submitInvite() {
      const docRef = await addDoc(requestsCollection, {
        email: this.email,
        type: this.selected,
        icons: this.personArray,
        created: serverTimestamp(),
      });
      this.$store.dispatch("setPersonalIcons", this.personArray);
      this.$router.replace({ name: "ConfirmInviteList" });
    },
  },
};
</script>
<style scoped>
img.mr-3.small.img-fluid {
  max-width: 10% !important;
}
.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}
@media only screen and (max-width: 800px) {
  /* For mobile phones: */
  .temp-landing .tagline {
    font-size: large;
  }
}

@media only screen and (min-width: 800px) {
  /* For bigger screens: */
  .temp-landing .form-container {
    width: 30%;
  }
}
.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
}
.btn-outline-purple.active:hover,
.btn-outline-purple:hover {
  color: #fff;
  background-color: #9a5bd2;
}
.custom-select,
.form-control {
  color: #713e9e !important;
}
.large {
    font-size: 4em;
}
</style>
