<template>
  <div class="tags-box">
    <b-container fluid>
      <b-col class="col-sm-12">
        <b-form-tags v-model="taglist" no-outer-focus class="mb-2 tag-list">
          <template
            v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }"
          >
            <!-- Always use the tag value as the :key, not the index! -->
            <!-- Otherwise screen readers will not read the tag
               additions and removals correctly -->
            <b-card-group class="mx-auto">
              <div v-for="tag in tags" :key="tag">
                <b-card
                  :id="`${taxonomy}_${tag.replace(/\s/g, '_')}_`"
                  class="mt-4 binary"
                  body-class=""
                  ><b-row align-v="center"
                    ><b-col cols="8"
                      ><div class="text-left pl-2">{{ tag }}</div></b-col
                    >
                    <b-col cols="4" class="binary"
                      ><b-button-group vertical class=""
                        ><b-button
                          squared
                          align-v="center"
                          class="white-right-border"
                          @click="
                            pushLikes({
                              Tag: tag,
                              Taxonomy: taxonomy,
                              Remove: `x-circle-fill`,
                            }),
                              removeTag(tag)
                          "
                          variant="purple"
                          ><b-icon
                            class="text-right mt-2 mb-2 mx-auto"
                            icon="hand-thumbs-up"
                            variant="outline-purple"
                          ></b-icon
                        ></b-button>
                        <b-button
                          align-v="center"
                          class="binary"
                          squared
                          @click="
                            pushDislikes({
                              Tag: tag,
                              Taxonomy: taxonomy,
                              Remove: `x-circle-fill`,
                            }),
                              removeTag(tag)
                          "
                          variant="purple"
                          ><b-icon
                            class="text-right mt-2 mb-2 mx-auto"
                            icon="hand-thumbs-down"
                            variant="outline-purple"
                          ></b-icon></b-button></b-button-group></b-col
                  ></b-row>
                </b-card>
              </div>
            </b-card-group>
          </template>
        </b-form-tags>
      </b-col>
    </b-container>
    <b-container>
      <b-col>
        <b-row>
          <b-col class="col-sm-6">
            <div>
              <h4 class="mt-3 text-left">{{ LikeFields[0] }}:</h4>
              <b-table
                class="table-purple text-left"
                :items="likes"
                :fields="fields"
                show-empty
                thead-class="no-th"
              >
                // eslint-disable-next-line no-unused-vars
                <template #empty=""> {{ likesText }}<br /> </template>
                // eslint-disable-next-line no-unused-vars
                <template #cell(Remove)="data">
                  <b-icon
                    @click="removeRow('likes', data.index, data.item.Tag)"
                    class="text-right mt-2 mb-2 mx-auto"
                    :icon="data.value"
                  ></b-icon> </template
              ></b-table></div></b-col
          ><b-col class="col-sm-6">
            <div>
              <h4 class="mt-3 text-left">{{ DislikeFields[0] }}:</h4>
              <b-table
                class="table-purple text-left"
                :items="dislikes"
                :fields="fields"
                show-empty
                thead-class="no-th"
              >
                // eslint-disable-next-line no-unused-vars
                <template #empty=""> {{ dislikesText }}</template>
                // eslint-disable-next-line no-unused-vars
                <template #cell(Remove)="data">
                  <b-icon
                    @click="removeRow('dislikes', data.index, data.item.Tag)"
                    class="text-right mt-2 mb-2 mx-auto"
                    :icon="data.value"
                  ></b-icon> </template
              ></b-table></div
          ></b-col>
        </b-row>
      </b-col>
      <b-button
        class="mb-4 mt-4 save-button"
        size="lg"
        @click="onSubmit()"
        variant="outline-purple"
        >Save Responses and Return to Dashboard</b-button
      >
    </b-container>
  </div>
</template>

<script>
import {
  collection,
  getDoc,
  getDocs,
  doc,
  db,
  serverTimestamp,
  addDoc,
  setDoc,
} from "@/firebase";
import { candidatesCollection } from "@/db";
export default {
  props: [
    "tagCategory",
    "title",
    "LikeFields",
    "DislikeFields",
    "likesText",
    "dislikesText",
    "oid",
  ],
  data() {
    return {
      scope: "",
      currentCat: 0,
      step: "3",
      time: "Less than 3 Minutes",
      taxonomy: this.tagCategory,
      tagsRef: this.tagCategory,
      show: true,
      likes: [],
      dislikes: [],
      clicked: false,
      fields: ["Index", "Tag", "Remove"],
      completeList: [],
      taglist: [],
      savedList: {},
      currentUser: this.$store.getters.getUID,
    };
  },
  methods: {
    start() {
      if (this.savedList) {
        if ("likes" in this.savedList) {
          this.likes = this.savedList["likes"];
        }
        if ("dislikes" in this.savedList) {
          this.dislikes = this.savedList["dislikes"];
        }
        if ("uncategorized" in this.savedList) {
          this.taglist = this.savedList["uncategorized"];
        }
      } else {
        if (this.tagsRef === "experiences") {
          this.taglist = this.$store.getters.getPDL.experience.map(
            (el) => [el.title.name] + " @ " + [el.company.name]
          );
        }
        if (this.tagsRef === "skills") {
          this.taglist = this.$store.getters.getSkills;
        }
        if (this.tagsRef === "industries") {
          this.taglist = this.$store.getters.getIndustryTags;
        } else return false;
      }
    },
    pushLikes(arr) {
      this.likes.push(arr);
    },
    pushDislikes(arr) {
      this.dislikes.push(arr);
    },
    removeRow(list, index, tag) {
      this[list] = this[list].filter((item, i) => i !== index);
      this.$emit("input", this[list]);
      this.taglist.push(tag);
    },
    onSubmit() {
      /*  const candidateRef = doc(
        candidatesCollection,
        this.$store.getters.getUID
      );
      const listRef = collection(candidateRef, "lists");
      const docRef = setDoc(
        doc(listRef, this.tagsRef),
        {
          likes: this.likes,
          dislikes: this.dislikes,
          uncategorized: this.taglist,
          taxonomy: this.taxonomy,
          created: serverTimestamp(),
        },
        { merged: true }
      );
      /*if((this.likes < 0) || (this.dislikes < 0)){
         this.$store.dispatch("setOption", true , this.oid);
      } else (
                 this.$store.dispatch("setOption", false , this.oid)

      ),*/
      let listtoupload = {
        name: this.taxonomy,
        likes: this.likes,
        dislikes: this.dislikes,
        uncategorized: this.taglist,
        taxonomy: this.taxonomy,
      };
      this.$store.dispatch("changeList", {name: this.taxonomy,list: listtoupload});
  
      this.$router.push("/dashboard");
    },
    getSavedList() {
      this.savedList = this.$store.getters.getList(this.tagsRef);

      this.start();
    },
  },

  computed: {},
  created() {
    this.getSavedList();
    //this.start()
  },
};
</script>

<style scoped>
/* Like/Dislike Lists */
.table-purple {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #713e9e;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #713e9e;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #713e9e;
  color: #713e9e;
  border-color: #dfe0e1;
  background-color: white;
}

.b-table-empty-row {
  text-align: left !important;
  height: 15em !important;
}

.tag-list {
  background-color: #fff;
}

.table td {
  background: white !important;
}

.table thead th {
  display: none !important;
}

/* To Make Buttons on the Purple Background */
.btn-purple {
  background-color: #713e9e;
  color: #fff;
  height: 3rem;
  margin-right: 0 !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.like {
  border-right: white 1px solid !important;
}
output#__BVID__18___selected_tags__,
div#__BVID__18___removed_tags__ {
  display: none !important;
}

.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}
.btn-purple:hover {
  background-color: #9a5bd2;
  color: white;
}

.btn-group,
.btn-group-vertical {
  float: right !important;
}
/*card */

.card {
  position: relative;
  display: inline-block;
  flex-direction: column;
  height: 6rem;
  /*word-wrap: break-word;*/
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #713e9e;
  color: #713e9e;
}

@media only screen and (min-width: 600px) {
  .card {
    width: 30em !important;
    margin: 1em !important;
  }
  .btn-purple {
    min-width: 10rem !important;
  }
}
@media only screen and (max-width: 599px) {
  /* For mobile phones: */
  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .card {
    width: 19rem !important;
  }
}
.text-start.pl-5 {
  line-height: 1em;
}
.text-left.pl-2 {
  line-height: 1.5;
}
.card-body {
  padding: 0 !important;
}

.form-control {
  padding: 0 !important;
  font-size: 1rem;
  line-height: 1;
  border: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-new-tag {
  border-radius: 0.25rem;
}

button.btn.white-right-border.btn-purple.rounded-0 {
  border-bottom: solid white;
}

.btn-outline-purple.save-button {
  background-color: #fff;
  color: #9a5bd2;
  height: 5em;
  width: 100%;
  margin-bottom: 4em;
}
.btn-outline-purple.save-button:hover {
  background-color: #9a5bd2;
  color: white;
  height: 5em;
  width: 100%;
  margin-bottom: 4em;
}

.binary {
  padding-right: unset !important;
}
</style>
<style>
.no-th {
  display: none !important;
}
</style>
