<template>
  <div class="onboarding-questions">
    <b-container>
      <OnboardingHeader
        :title="questionnaires.find(el=>el.computedName === questionnaireID).title"
      ></OnboardingHeader>

      <h5 v-if="false">
        <em
          ><b-icon icon="check"></b-icon> indicates that you have experience in
          the option type and is only intended to help you anchor your
          understanding of the question</em
        >
      </h5>
      <div
        v-for="section in questionnaire"
        :key="`sid_` + section.sid"
      >
        <b-container class="mt-5 question">
          <b-row>
            <b-col
              cols="4"
              class="text-left"
              v-html="section.explanatory"
            ></b-col>
            <b-col cols="8">
              <h4 class="mx-auto text-center">{{ section.prompt }}</h4>
              <h6 v-if="section.choices === 'multiple'">
                Choose all the choices that apply.
              </h6>
              <h6 v-if="section.choices === 'single'">Choose one.</h6>
              <br />
              <b-button-group class="wide" vertical>
                <b-col
                  ><b-row>
                    <b-button
                      squared
                      v-for="option in section.options"
                      :key="`sid_` + section.sid + `_oid_` + option.oid"
                      @click="chooseSelection(option, section.sid, section)"
                      :pressed="option.choice"
                      class="text-left wide col-6 capitalize"
                      variant="outline-purple"
                      >{{ option.title }}</b-button
                    >
                  </b-row></b-col
                >
              </b-button-group>
              <b-row
                class="justify-content-md-center"
                v-if="section.style === 'input'"
              >
                <h3>{{ parsedMinimumSalary }}</h3>

                <b-form-input
                  class="range"
                  variant="purple"
                  id="range-1"
                  v-model="minimumSalary"
                  type="range"
                  min="35000"
                  max="350000"
                  step="10000"
                ></b-form-input>
              </b-row>
              <b-row
                v-if="
                  section.style === 'text-area' &&
                  section.section !== 'twenty-minute-talk'
                "
                class="justify-content-center"
                ><b-col cols="8">
                  <b-form-input
                    v-model="choices[section.section]"
                    placeholder="Enter something..."
                    :class="section.section"
                    debounce="500"
                    type="text"
                  ></b-form-input>
                </b-col>
              </b-row>
              <b-row
                v-if="
                  section.style === 'text-area' &&
                  section.section === 'twenty-minute-talk'
                "
                class="justify-content-center"
                ><b-col cols="8">
                  <ChooseTopic
                    :searchValue="choices[section.section]"
                    @setTopic="setTopic"
                  ></ChooseTopic>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
      <b-button
        @click="onSubmit()"
        size="lg"
        variant="outline-purple"
        class="save-button"
        ><b>Save</b> and Return to Dashboard</b-button
      >
    </b-container>
  </div>
</template>

<script>
import {
  collection,
  getDoc,
  getDocs,
  doc,
  db,
  serverTimestamp,
  addDoc,
  updateDoc,
  setDoc,
  arrayUnion,
} from "@/firebase";
import { usersCollection, candidatesCollection } from "@/db";
import OnboardingHeader from "@/components/sections/OnboardingHeader.vue";
import ChooseTopic from "@/components/interfaces/chooseTopic.vue";
export default {
  components: { OnboardingHeader, ChooseTopic },
  data() {
    return {
      sections: [],
      step: "",
      title: "",
      time: "Less than 4 Minutes",
      currentUser: this.$store.getters.getUID,
      terms: {},
      choices: {},
      minimumSalary: "",
      questionnaire: [],
      questionnaires: [
        {
          title: "General Company Questions",
          computedName: "general-company-questions",
          step: 2,
        },
        {
          title: "General Personal Questions",
          computedName: "general-personal-questions",
          step: 1,
        },
        {
          title: "Human Being Questions",
          computedName: "human-being-questions",
          step: 7,
        },
      ],
      currentQuestionnaire: "",
    };
  },
  props: ["questionnaireID"],
  methods: {
    getQuestionnaire() {
      this.questionnaire = this.$store.getters.getQuestionnaires[this.questionnaireID].sort((a,b)=> a.sid - b.sid)
      //console.log(this.$store.getters.getQuestionnaires[this.questionnaireID])
    },
    setTopic(arr) {
      this.choices["twenty-minute-talk-wiki"] = arr;
    },
    updateValues(option, action) {
      let optionsArr = Object.entries(option);

      let indiciesArr = optionsArr
        .map((op) => ({
          term: op[0] in this.terms,
          name: op[0],
          value: op[1],
        }))
        .filter((ex) => ex.term === true);

      //add individual values to list
      if (action === "add") {
        indiciesArr.map((el) => this.terms[el.name].values.push(el.value));
      } else if (action === "remove") {
        indiciesArr.map((el) => {
          let currVal = this.terms[el.name].values.indexOf(el.value);
          this.terms[el.name].values.splice(currVal, 1);
        });
      }
      let tv = this.terms;
      let test = Object.entries(tv)
        .filter((el) => el[1].values.length > 0)
        .map(
          (ex) => (ex[1].total_value = ex[1].values.reduce((a, b) => a + b))
        );

      //  let test = Object.fromEntries(
      // Object.entries(this.terms))

      //console.log(test)
      //console.log(Object.entries(tv).filter(el => el[1].total_value > 0))
      //compute total_value
      /* this.terms
        .filter((tv) => tv.values.length > 0)
        .map((mp) => (mp.total_value = mp.values.reduce((a, b) => a + b)));
      //console.log(optionsArr[1][1],": ", this.termValues.filter(ca => ca.values.length > 0).map(mp => ({title: mp.title, tv: mp.total_value})))*/
    },

    addSingleChoice(option, sid, section, ranking) {
      if (this.sections.indexOf(section) < 0) {
        this.sections.push(section);
      }

      this.choices[section] = this.choices[section] || [];
      if (this.choices[section].length > 0) {
        let currentOID = this.choices[section][0].oid;
        let removeOption = this.currentQuestionnaire[this.questionnaireID]
          .find((se) => se.section === section)
          .options.filter((el) => el.choice === true)[0];
        this.currentQuestionnaire[this.questionnaireID]
          .find((se) => se.section === section)
          .options.map((el) => (el.choice = false));

        this.updateValues(removeOption, "remove");
        this.choices[section] = [];
        option.choice = true;
        this.choices[section].push({ ...option, sid });
      } else {this.choices[section].push({ ...option, sid })
      console.log("This?", this.questionnaire.find(el => el.sid === sid).options)}
      this.updateValues(option, "add");
    },

 filterObject(obj, callback) {
  return Object.fromEntries(Object.entries(obj).
    filter(([key, val]) => callback(val, key)));
},
    removeSingleChoice(option, sid, section) {
      this.choices[section] = [];
      this.updateValues(option, "remove");
    },

    addMultipleChoice(option, sid, section, ranking) {
      if (this.sections.indexOf(section) < 0) {
        this.sections.push(section);
      }
      this.choices[section] = this.choices[section] || [];
      this.choices[section].push({ ...option, sid });
      this.updateValues(option, "add");
    },
    removeMultipleChoice(option, sid, section) {
      this.choices[section] = this.choices[section].filter(
        (el) => el.oid !== option.oid
      );
      this.updateValues(option, "remove");
    },

    chooseSelection(option, sid, section) {
      if (!option.choice && section.choices === "multiple") {
        option.choice = true;
        this.addMultipleChoice(option, sid, section.section);
      } else if (!option.choice && section.choices === "single") {
        option.choice = true;
        this.addSingleChoice(option, sid, section.section);
      } else if (option.choice && section.choices === "multiple") {
        option.choice = false;
        this.removeMultipleChoice(option, sid, section.section);
      } else if (option.choice && section.choices === "single") {
        option.choice = false;
        this.removeSingleChoice(option, sid, section.section);
      }
    },
    async onSubmit() {
      this.$store.dispatch("updateValues", this.terms);
      this.$store.dispatch("updateChoices", this.choices);
      this.$store.dispatch("updateQuestionnaires", this.currentQuestionnaire);
      this.$store.dispatch("addSection", this.questionnaireID);
      //this.$store.dispatch("changeFlow", 1);
      this.$router.push("/dashboard");
    },

    setAnchoredSalary() {
      if (this.$store.getters.getAllChoices["minimum-salary"] !== undefined) {
        this.minimumSalary =
          this.$store.getters.getAllChoices["minimum-salary"];
      } else if (
        this.$store.getters.getAllBasicData["minimum_anchored_salary"] !== null
      ) {
        this.minimumSalary =
          this.$store.getters.getAllBasicData["minimum_anchored_salary"];
      } else if (
        this.$store.getters.getState["minimum_anchored_salary"].data !== null
      ) {
        this.minimumSalary =
          this.$store.getters.getState["minimum_anchored_salary"].data;
      } else this.minimumSalary = 100000;
    },
  },

  computed: {
    parsedMinimumSalary() {
      try {
        let salaryString = this.minimumSalary.toString();
        let commaPlace = salaryString.length - 3;
        let comma = ",";
        let postfix = "";
        if (this.minimumSalary == 345000) {
          postfix = "+";
        }
        return (
          "$" +
          salaryString.slice(0, commaPlace) +
          comma +
          salaryString.slice(commaPlace) +
          postfix
        );
      } catch {
        return null;
      }
    },
  },
  watch: {
    minimumSalary() {
      this.choices["minimum-salary"] = this.minimumSalary;
    },
  },
  created() {
    this.currentQuestionnaire = this.$store.getters.getQuestionnaires;
    this.terms = this.$store.getters.getAllTermValues;
    this.choices = this.$store.getters.getAllChoices;
    this.setAnchoredSalary();
    this.getQuestionnaire()
  },
};
</script>

<style scoped>
.left {
  float: left !important;
}

.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}

.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
  border-bottom: white 1px solid !important;
}
.btn-outline-purple.active:hover,
.btn-outline-purple:hover {
  color: #fff;
  background-color: #9a5bd2;
}

.btn-purple {
  background-color: #9a5bd2;
  color: white;
}
.btn-purple:hover,
.btn-purple:active {
  background-color: white;
  color: #9a5bd2;
  border-color: #713e9e;
}

.question {
  margin-bottom: 6em !important;
}

button.btn.text-left.wide.btn-outline-purple {
  width: 30em !important;
  height: 8em !important;
}

input#input-large {
  width: 100%;
  height: 3em !important;
}

.btn-outline-purple.save-button {
  background-color: #fff;
  color: #713e9e;
  height: 5em;
  width: 100%;
  margin-bottom: 4em;
}
.btn-outline-purple.save-button:hover {
  background-color: #713e9e;
  color: white;
  height: 5em;
  width: 100%;
  margin-bottom: 4em;
}

.b-icon.text-right {
  float: right;
}

.form-control {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem !important;
  /* font-size: 1rem !important; */
  font-weight: 400;
  line-height: 1.5 !important;
  color: #713e9e !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #713e9e !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  float: left;
}

.capgitalize {
  text-transform: capitalize;
}
</style>
