<template>
  <div id="page" class="home">
    <Landing></Landing>
  </div>
</template>

<script>
// @ is an alias to /src
import Landing from '@/components/pages/Landing.vue'

export default {
  name: 'Home',
  components: {
    Landing,
  },
  data() {
      return {
      }
  }
}
</script>

<style scoped>
.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}

.btn-outline-purple:hover,
.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}



</style>
