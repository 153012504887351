<template>
  <div class="purple-nav">
    <b-navbar toggleable="md" type="dark">
      <b-navbar-brand to="/" class="logo-link"
        ><img alt="Before Logo" class="logo" src="@/assets/before_logo.png"
      /></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/" class="text-left">Home</b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <div right class="ml-auto">
          <b-col>
            
            <b-row class="">
              
              <b-navbar-nav>
                <b-nav-item
                  v-if="this.getUID"
                  class="text-left" disabled
                  >{{ getCurrentAuthUserName }}</b-nav-item
                >
                  <b-nav-item to="/dashboard" v-if="getUID" class="text-left onboarding"
                  >Dashboard</b-nav-item>
                
                <b-nav-item to="/start" v-if="!getUID" class="text-left onboarding"
                  >Sign up</b-nav-item>
                              
                <b-nav-item
                  @click="logout()"
                  v-if="this.getUID"
                  class="text-left"
                  >Log Me Out</b-nav-item
                >
                                <b-nav-item
                  to="/login"
                  v-if="!this.getUID"
                  class="text-left"
                  >Login</b-nav-item
                >
              </b-navbar-nav>
            </b-row>
          </b-col>
        </div>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import {
  collection,
  getDoc,
  getDocs,
  doc,
  db,
  serverTimestamp,
  addDoc,
  setDoc,
  query,
  where,
} from "@/firebase";
import { candidatesCollection, companiesCollection } from "@/db";
import { auth } from "@/firebase";
import { mapGetters, mapActions } from "vuex";

export default {

  data() {
    return {
      disabled: !this.getUID,
    };
  },
  methods: {
    ...mapActions([
      "changeCat",
      "changeTagRef",
      "changeFlow",
    ]),
    logout() {
      this.$store.dispatch("logout");
    window.localStorage.clear()
      this.$router.push("/start");
    },
  },
  computed: {
    ...mapGetters([
      "getCurrentPage",
      "getCurrentTagRef",
      "getFlow",
      "getCurrentAuthUser",
      "getUID",
      "getCurrentAuthUserName",
      "getAllFlow"
    ]),
    checkCurrentUser() {
      const user = auth.currentUser;
      if (user) {
        return user.uid;
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // ...
      } else {
        // No user is signed in.
        return "No one is logged in.";
      }
    },
  },
};
</script>

<style>
.btn-purple {
  background-color: #713e9e;
  color: #fff;
}

.purple-nav {
  color: white;
  font-family: "Circular-Loom", Helvetica, sans-serif !important;
  font-size: 1.2em;
}

#nav a {
  font-weight: bold;
  color: #fff !important;
}

a.onboarding.router-link-active {
  border-bottom: white solid 5px;
}

a.navbar-brand.logo-link.router-link-active {
  border-bottom: none;
}

#nav a.disabled {
  color: lightgray !important;
}
</style>
