import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import {
  collection,
  getDoc,
  getDocs,
  doc,
  db,
  serverTimestamp,
  addDoc,
  setDoc,
  query,
  where,
  updateDoc,
  onSnapshot,
} from "@/firebase";
import { usersCollection, candidatesCollection } from "@/db";
//import VuexPersistence from "vuex-persist";

//import { vuexfireMutations } from 'vuexfire'
//import candidates from "./modules/candidates";
//import user from "./modules/user";

Vue.use(Vuex);

export const store = new Vuex.Store({
  //plugins: [new VuexPersistence().plugin],
  state: {
    localhost: false,
    currentAuthUser: {},
    currentUID: "",
    currentUserType: "",
    industryTags: [],
    signInEmail: "",
    basicData: {},
    name: "",
    signupEmail: "",
    lists: {},
    pdl: {},
    questionnaires: {},
    narratives: {},
    options: [],
    userProfile: [],
    insights: [],
    narrativeFacts: {},
    terms: {},
    choices: {},
    websiteData: {},
    websiteText: {},
    professionalData: {},
    edits: {},
    sections: [],
    companyChoices: {},
    current_industry: "",
    education: [],
    interests: [],
    minimum_anchored_salary: "",
    personalIcons: [],
    flow: [
      {
        id: 0,
        status: false,
        content: [],
      },
      {
        id: 1,
        status: false,
        content: [],
      },
      {
        id: 2,
        status: false,
        content: [],
      },
      {
        id: 3,
        status: false,
        content: [],
      },
      {
        id: 4,
        status: false,
        content: [],
      },
      {
        id: 5,
        status: false,
        content: [],
      },
      {
        id: 6,
        status: false,
        content: [],
      },
      {
        id: 7,
        status: false,
        content: [],
      },
    ],
  },
  mutations: {
    SET_PERSONAL_ICONS(state, personalIcons) {
      state.personalIcons = personalIcons;
    },
    SET_NAME(state) {
      state.name = state.currentAuthUser.displayName;
    },
    LOCALHOST_CHECK(state, localhost) {
      state.localhost = localhost;
    },
    QUESTIONNAIRES_UPDATED(state, questions) {
      state.questionnaires = questions;
    },
    CHOICES_UPDATED(state, choices) {
      state.choices = choices;
    },
    COMPANY_CHOICES_UPDATED(state, companyChoices) {
      state.companyChoices = companyChoices;
    },
    VALUES_UPDATED(state, termValues) {
      state.terms = termValues;
    },
    CHOICES_ADDED(state, choices) {
      state.choices.push(choices);
    },
    SECTION_ADDED(state, section) {
      state.sections.push(section);
    },
    EDIT_ADDED(state, edit) {
      state.edits = { ...state.edits, edit };
    },
    EDITS_SYNCED(state, edits) {
      state.edits = edits;
    },
    INSIGHTS_SET(state, insights) {
      state.insights = insights;
    },
    INSIGHT_ADDED(state, insight) {
      state.insights.push(insight);
    },
    INSIGHT_REMOVED(state, insight) {
      state.insights = state.insights.filter((el) => el.insight !== insight);
    },
    CHOICE_REMOVED(state, choice) {
      state.choices = state.choices.filter((el) => el.choice !== choice);
    },
    VALUE_UPDATED(state, termValue) {
      state.terms.push(termValue);
    },
    OPTIONS_CHANGED(state, option) {
      state.options = option;
    },
    OPTION_SET(state, order) {
      state.options[order].status = true;
    },
    AUTH_USER_CHANGED(state, user) {
      state.currentAuthUser = user;
    },
    UID_CHANGED(state, UID) {
      state.currentUID = UID;
    },
    NAME_CHANGED(state, name) {
      state.name = name;
    },
    USER_TYPE_CHANGED(state, userType) {
      state.currentUser, userType;
    },
    INDUSTRY_TAGS_CHANGED(state, industryTags) {
      state.industryTags = industryTags;
    },
    SKILLS_CHANGED(state, skills) {
      state.skills = skills;
    },
    BASIC_DATA_CHANGED(state, basicData) {
      state.basicData = basicData;
    },
    BASIC_DATA_ADDED(state, basicData) {
      state.basicData.push(basicData);
    },
    BASIC_DATA_CORRECTED(state, name, data) {
      let location = state.basicData.find((el) => el.name === name);
      location.data = data;
    },
    BASIC_DATA_ITEM_REMOVED(state, name, i) {
      let ListIndex = state.basicData.map((e) => e.name).indexOf(name);
      state.basicData[ListIndex].data.splice(i, 1);
    },
    SIGN_IN_EMAIL_CHANGED(state, email) {
      state.signInEmail = email;
    },
    SIGN_UP_EMAIL_CHANGED(state, email) {
      state.signupEmail = email;
    },
    FLOW_CHANGED(state, flowid) {
      state.flow.find((el) => el.id === flowid).status = true;
    },
    ALL_FLOW_SET(state, flow) {
      state.flow = flow;
    },
    LISTS_CHANGED(state, lists) {
      state.lists = lists;
    },
    LIST_CHANGED(state, obj) {
      state.lists[obj.name] = obj.list;
    },
    PDL_CHANGED(state, pdl) {
      state.pdl = pdl;
    },
    FACTS_SYNCED(state, narrativeFacts) {
      state.narrativeFacts = narrativeFacts;
    },
    FACT_APPENDED(state, fact) {
      state.narrativeFacts.push(fact);
    },
    USER_PROFILE_SYNCED(state, profile) {
      state.userProfile = profile;
    },
    USER_PROFILE_APPENDED(state, profile) {
      state.userProfile.push(profile);
    },
    NARRATIVE_CHANGED(state, narratives) {
      state.narratives = narratives;
    },
    WEBSITE_DATA_UPDATED(state, data) {
      state.websiteData.push(data);
    },
    async WEBSITE_TEXT_UPDATED(state, obj) {
      Vue.set(state["websiteText"][obj.cat][obj.sect], [obj.item], obj.text);
      const txtRef = doc(db, "text", obj.cat);
      await updateDoc(txtRef, { [`${obj.sect}.${obj.item}`]: obj.text });
      window.localStorage.setItem("store", JSON.stringify(state));
    },
    DB_RESET(state) {
      state.basicData = {};
    },

    async DOWNLOAD_ONBOARDING(state) {
      const onboardingRef = collection(
        db,
        "candidates",
        state.currentUID,
        "onboarding"
      );
      const onboarding = await getDocs(onboardingRef);
      onboarding.forEach((doc) => {
        state[doc.id] = doc.data();
        window.localStorage.setItem("store", JSON.stringify(state));
      });
    },

    async DOWNLOAD_WEBSITE_DATA(state) {
      const WebsiteDataRef = collection(db, "website");
      const websiteData = await getDocs(WebsiteDataRef);
      websiteData.forEach((doc) => {
        state["websiteData"][doc.id] = doc.data();
        window.localStorage.setItem("store", JSON.stringify(state));
      });
    },
    async DOWNLOAD_WEBSITE_TEXT(state) {
      const websiteTextRef = collection(db, "text");
      const websiteText = await getDocs(websiteTextRef);
      websiteText.forEach((doc) => {
        state["websiteText"][doc.id] = doc.data();
        window.localStorage.setItem("store", JSON.stringify(state));
      });
    },

    async DOWNLOAD_COLLECTIONS(state) {
      const collectionsRef = collection(
        db,
        "candidates",
        state.currentUID,
        "collections"
      );
      const collections = await getDocs(collectionsRef);
      collections.forEach((doc) => {
        state[doc.id] = doc.data();
        window.localStorage.setItem("store", JSON.stringify(state));
      });
    },

    async DB_LISTEN_LISTS(state) {
      const listsRef = collection(db, "candidates", state.currentUID, "lists");
      const lists = await getDocs(listsRef);
      lists.forEach((doc) => {
        state.lists[doc.id] = doc.data();
        window.localStorage.setItem("store", JSON.stringify(state));
      });
    },
    initializeStore(state) {
      //console.log(window.localStorage.getItem("store"));
      // Check if the ID exists
      if (window.localStorage.getItem("store")) {
        // Replace the state object with the stored item
        this.replaceState(
          Object.assign(state, JSON.parse(window.localStorage.getItem("store")))
        );
      }
    },
    LOGOUT(state) {
      state.companyChoices = {};
      state.currentAuthUser = {};
      state.currentUID = "";
      state.terms = {};
      state.name = this.getCurrentAuthUserName;
      state.choices = {};
      state.lists = {};
      state.insights = {};
      state.narratives = {};
      state.basicData = {};
      state.pdl = {};
      state.questionnaires = {};
      state.signInEmail = "";
      state.signupEmail = "";
      state.narrativeFacts = {};
      state.options = {};
      state.flow = [];
      state.sections = [];
      state.professionalData = {};
    },
    //...vuexfireMutations,
  },
  actions: {
    nameSet({ commit }) {
      commit("SET_NAME");
    },
    localhostCheck({ commit }) {
      let check = false;
      if (window.location.hostname === "localhost") {
        check = true;
      }
      commit("LOCALHOST_CHECK", check);
    },
    listenDB({ commit }) {
      commit("DB_LISTEN");
    },
    logout({ commit }) {
      commit("LOGOUT");
    },
    downloadOnboarding({ commit }) {
      commit("DOWNLOAD_ONBOARDING");
    },
    setPersonalIcons({ commit }, personalIcons) {
      commit("SET_PERSONAL_ICONS", personalIcons);
    },
    addEdit({ commit }, edit) {
      commit("EDIT_ADDED", edit);
    },
    syncEdits({ commit }, edits) {
      commit("EDITS_SYNCED", edits);
    },
    updateWebsiteData({ commit }, obj) {
      commit("WEBSITE_DATA_UPDATED", obj);
    },
    updateWebsiteText({ commit }, obj) {
      commit("WEBSITE_TEXT_UPDATED", obj);
    },
    downloadWebsiteData({ commit }) {
      commit("DOWNLOAD_WEBSITE_DATA");
    },
    downloadWebsiteText({ commit }) {
      commit("DOWNLOAD_WEBSITE_TEXT");
    },
    downloadCollections({ commit }) {
      commit("DOWNLOAD_COLLECTIONS");
    },
    listenDBLists({ commit }) {
      commit("DB_LISTEN_LISTS");
    },
    resetDB({ commit }) {
      commit("DB_RESET");
    },
    addSection({ commit }, section) {
      commit("SECTION_ADDED", section);
    },
    addChoices({ commit }, choices) {
      commit("CHOICES_ADDED", choices);
    },
    removeChoice({ commit }, choice) {
      commit("CHOICE_REMOVED", choice);
    },
    addInsight({ commit }, insight) {
      commit("INSIGHT_ADDED", insight);
    },
    removeInsight({ commit }, insight) {
      commit("INSIGHT_REMOVED", insight);
    },
    async updateChoices({ commit, state }, choices) {
      const candidateRef = doc(candidatesCollection, state.currentUID);
      const onboardingRef = collection(candidateRef, "onboarding");
      const ChoicesRef = doc(onboardingRef, "choices");
      await setDoc(ChoicesRef, choices);
      commit("CHOICES_UPDATED", choices);
    },

    async updateCompanyChoices({ commit, state }, choices) {
      const candidateRef = doc(candidatesCollection, state.currentUID);
      const onboardingRef = collection(candidateRef, "onboarding");
      const CompanyChoicesRef = doc(onboardingRef, "company-choices");
      await setDoc(CompanyChoicesRef, choices);
      commit("COMPANY_CHOICES_UPDATED", choices);
    },
    setInsights({ commit }, insights) {
      commit("INSIGHTS_SET", insights);
    },
    updateValue({ commit }, termValue) {
      commit("VALUE_UPDATED", termValue);
    },
    async updateValues({ commit, state }, termValues) {
      const candidateRef = doc(candidatesCollection, state.currentUID);
      const onboardingRef = collection(candidateRef, "onboarding");
      const termsRef = doc(onboardingRef, "terms");
      await setDoc(termsRef, termValues);
      commit("VALUES_UPDATED", termValues);
    },
    changeOptions({ commit }, option) {
      commit("OPTIONS_CHANGED", option);
    },
    setOption({ commit }, order) {
      commit("OPTION_SET", order);
    },
    changeAuthUser({ commit }, user) {
      commit("AUTH_USER_CHANGED", user);
    },
    changeUID({ commit }, uid) {
      commit("UID_CHANGED", uid);
    },
    changeSignInEmail({ commit }, email) {
      commit("SIGN_IN_EMAIL_CHANGED", email);
    },
    changeSignupEmail({ commit }, email) {
      commit("SIGN_UP_EMAIL_CHANGED", email);
    },
    changeBasicData({ commit }, basicData) {
      commit("BASIC_DATA_CHANGED", basicData);
    },
    addBasicData({ commit }, basicData) {
      commit("BASIC_DATA_ADDED", basicData);
    },
    correctBasicData({ commit }, basicData, name, data) {
      commit("BASIC_DATA_CORRECTED", basicData, name, data);
    },
    removeBasicData({ commit }, basicData, name, i) {
      commit("BASIC_DATA_ITEM_REMOVED", basicData, name, i);
    },
    changeFlow({ commit }, flowid) {
      commit("FLOW_CHANGED", flowid);
    },
    changeUserType({ commit }, userType) {
      commit("USER_TYPE_CHANGED", userType);
    },
    appendFact({ commit }, fact) {
      commit("FACT_APPENDED", fact);
    },
    syncFacts({ commit }, narrativeFacts) {
      commit("FACTS_SYNCED", narrativeFacts);
    },
    appendUserProfile({ commit }, profile) {
      commit("USER_PROFILE_APPENDED", profile);
    },
    syncUserProfile({ commit }, profile) {
      commit("USER_PROFILE_SYNCED", profile);
    },
    changeLists({ commit }, lists) {
      commit("LISTS_CHANGED", lists);
    },
    async changeList({ commit, state }, obj) {
      const candidateRef = doc(candidatesCollection, state.currentUID);
      const onboardingRef = collection(candidateRef, "onboarding");
      const ListsRef = doc(onboardingRef, "lists");
      await setDoc(ListsRef, { [obj.name]: obj.list }, { merge: true });
      commit("LIST_CHANGED", obj);
    },
    async changeNarratives({ commit, state }, narratives) {
      const candidateRef = doc(candidatesCollection, state.currentUID);
      const onboardingRef = collection(candidateRef, "onboarding");
      const narrativesRef = doc(onboardingRef, "narratives");
      await setDoc(narrativesRef, narratives);
      commit("NARRATIVES_CHANGED", narratives);
    },
    changePDL({ commit }, pdl) {
      commit("PDL_CHANGED", pdl);
    },
    async updateQuestionnaires({ commit, state }, questions) {
      const candidateRef = doc(candidatesCollection, state.currentUID);
      const onboardingRef = collection(candidateRef, "onboarding");
      const questionnairesRef = doc(onboardingRef, "questionnaires");
      await setDoc(questionnairesRef, questions);
      console.log("done");
      commit("QUESTIONNAIRES_UPDATED", questions);
    },
    changeName({ commit }, name) {
      commit("NAME_CHANGED", name);
    },
    setAllFlow({ commit }, flow) {
      commit("ALL_FLOW_SET", flow);
    },
    changeIndustryTags({ commit }, industryTags) {
      commit("INDUSTRY_TAGS_CHANGED", industryTags);
    },
    createNarrativeFacts({ commit, getters }) {
      let allFacts = getters.getAllFacts;
      let narratives = getters.getNarratives;
      let topTerms = getters.getTopTerms.map((el) => el.name);
      let narrativeFacts = {};

      for (const narrative in narratives) {
        if (allFacts[narrative]) {
          narratives[narrative]["data"] = allFacts[narrative];
          narratives[narrative]["original-data"] = allFacts[narrative];

          narrativeFacts[narrative] = { ...narratives[narrative] };
        }
        if (topTerms.find((el) => el === narrative)) {
          narrativeFacts[narrative] = { ...narratives[narrative] };
        }
      }
      for (const fact in narrativeFacts) {
        let text = "";
        let posttext = "";

        if (narrativeFacts[fact].type === "length") {
          text = narrativeFacts[fact].data.length;
        } else text = narrativeFacts[fact].data;

        if (narrativeFacts[fact].prefix) {
          text = narrativeFacts[fact].prefix + text;
        }

        if (narrativeFacts[fact].type === "length" && text > 1) {
          posttext = narrativeFacts[fact].words.multiple;
        } else if (narrativeFacts[fact].type === "length" && text === 1) {
          posttext = narrativeFacts[fact].words.single;
        } else if (narrativeFacts[fact].type === "length" && text === 0) {
          posttext = " 0 " + narrativeFacts[fact].words.multiple;
        } else {
          posttext = " " + narrativeFacts[fact].words;
        }

        let phrase = narrativeFacts[fact].pretext + " " + text + " " + posttext;
        if (phrase.at(-1) !== "." && phrase.at(-2) !== ".") {
          phrase = phrase + ".";
        }
        if (narrativeFacts[fact].filter === "insight") {
          phrase = narrativeFacts[fact].text;
        }
        narrativeFacts[fact]["phrase"] = phrase;
      }

      commit("FACTS_SYNCED", narrativeFacts);
    },
  },
  modules: {
    //candidates,
    user,
  },
  getters: {
    getPersonalIcons(state) {
      return state.personalIcons;
    },
    getTopTerms(state) {
      return Object.entries(state.terms)
        .filter(Boolean)
        .filter((el) => el[1].total_value > 0)
        .map((el) => ({
          term: el[0],
          name: el[0],
          total_value: el[1].total_value,
          values: el[1].values || 0,
          average: el[1].total_value / el[1].values.length || 0,
          type: el[1].type,
          associated_term: el[1].associated_term,
          values_length: el[1].values.length,
          category: "insight",
        }))
        .filter((el) => el.values.length > 2)
        .filter((el) => el.average > 6)
        .sort((a, b) => b.total_value - a.total_value);
    },
    getAllTermValues(state) {
      return state.terms;
    },
    getInsightsTexts(state, getters) {
      return Object.entries(getters.getTopTerms)
        .map((el) => el[1].term)
        .map((em) => state.narratives[em])
        .filter(Boolean)
        .map((el) => ({
          name: el.name,
          text: el.text,
          source: "insights",
        }));
    },
    getNarrativeFactsTexts(state) {
      return Object.entries(state.narrativeFacts)
        .map((el) => Object.values(el[1]))
        .flat()
        .filter(Boolean)
        .map((el) => ({
          name: el.name,
          text: el.phrase,
          source: "narrativeFacts",
        }))
        .filter((el) => el.text);
    },
    checkNarratives(state) {
      return Object.entries(state.narratives)
        .map((es) => es[0])
        .filter((ep) => ep in state.basicData)
        .map((em) => ({ [em]: this.getThis(state.narratives[em]) })); //ugh//
    },
    getChoice: (state) => (choice) => {
      return state.choices[choice];
    },
    getChoiceBySID: (state) => (sid) => {
      return state.choices.find((term) => term === sid);
    },
    getAllEdits(state) {
      return state.edits;
    },
    getAllChoices(state) {
      return state.choices;
    },
    getOptions(state) {
      return state.options;
    },
    getAllInsights(state) {
      return state.insights;
    },
    getNarrativeFacts(state) {
      return state.narrativeFacts;
    },
    getNarrativeFactsList(state) {
      return state.narrativeFacts.map((el) => el.name);
    },
    getNarrativeFactsByName: (state) => (name) => {
      return state.narrativeFacts.find((fact) => fact.name === name);
    },
    getUserProfile(state) {
      return state.userProfile;
    },
    getAllFacts(state) {
      return {
        ...state.professionalData,
        ...state.basicData,
      };
    },
    isLocalhost(state) {
      return state.localhost;
    },
    getUserProfileList(state) {
      return state.userProfile.map((el) => el.name);
    },
    getUserProfileByName: (state) => (name) => {
      return state.userProfile.find((profile) => profile.name === name);
    },
    getUserProfileByCategory: (state) => (category) => {
      return state.userProfile.find((profile) => profile.name === category);
    },
    getUserProfileByTaxonomy: (state) => (taxonomy) => {
      return state.userProfile.find((profile) => profile.name === taxonomy);
    },
    getUserProfileByType: (state) => (type) => {
      return state.userProfile.find((profile) => profile.name === type);
    },
    getCurrentAuthUser(state) {
      return state.currentAuthUser;
    },
    isAuthenticated(state) {
      if (state.currentUID !== "") {
        return true;
      } else return false;
    },
    getCurrentAuthUserName(state) {
      return state.currentAuthUser.displayName;
    },
    getUID(state) {
      return state.currentUID;
    },
    getQuestionnaires(state) {
      return state.questionnaires;
    },
    getName(state) {
      return state.name;
    },
    getState(state) {
      return state;
    },
    getWebsiteText(state) {
      return state.websiteText;
    },
    getWebsiteData(state) {
      return state.websiteData;
    },
    getSkills(state) {
      return state.basicData["skills_list"];
    },
    getCompanySizesList(state) {
      return state.professionalData["company_sizes_list"];
    },
    getIndustryTags(state) {
      return state.industryTags;
    },
    getFlow: (state) => (id) => {
      return state.flow.find((page) => page.id === id).status;
    },
    getAllFlowNumber(state) {
      return state.flow.map((el) => el.state === true).length;
    },
    getAllFlow(state) {
      return state.flow;
    },
    getSections(state) {
      return state.sections;
    },
    getPDL(state) {
      return state.pdl;
    },
    getProfessionalData(state) {
      return state.professionalData;
    },
    getNarratives(state) {
      return state.narratives;
    },
    getLists(state) {
      return state.lists;
    },
    getAllBasicData(state) {
      return state.basicData;
    },
    getList: (state) => (taxonomy) => {
      return state.lists[taxonomy];
    },
    getListValence: (state) => (taxonomy, valence) => {
      return state.lists.find((list) => list.id === taxonomy).data;
    },
    getSignInEmail(state) {
      return state.signInEmail;
    },
    getSignupEmail(state) {
      return state.signupEmail;
    },
    getCompanyChoices(state) {
      return state.companyChoices;
    },
    getExperiences(state) {
      let experienceList = [];
      let jobs_list = state.basicData["job_titles_list"];
      jobs_list.forEach((doc, i) => {
        try {
          let title = `${doc.title.name} at ${doc.company.name}`;
          experienceList.push(title);
        } catch (e) {
          //console.log(e);
        }
      });
      return experienceList;
    },
  },
});
