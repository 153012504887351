<template>
  <div class="sign-in-new-user">

<b-container>
<h4>You are on the the <b>Before</b> waitlist!</h4>

<p>We will send you an email as soon as an invite is available.</p>

</b-container>
  </div>
</template>

<script>
import BeforeNameLogoPurple from "@/assets/before_full_logo_purple.png";
export default {
  data(){
    return{
      BeforeNameLogoPurple: BeforeNameLogoPurple,
    }
  },
    components:{
    },
computed: {
  personalIcons(){
    return this.$store.getters.getPersonalIcons
  }
}
}
</script>

<style scoped>
.xlarge {
    font-size: 8em;
}
.shareable{
  border: 3px solid #713e9e;
  padding: 1.5em
}
</style>