<template>
  <div>
    <b-container>
      <h3><b>Save the Sloths Foundation</b> - Marketing Manager Position</h3>
      <hr class="mt-2 mb-3" />

      <b-card>
        <b-card-title><span class="d-flex justify-content-start font-weight-bold">Position Details</span> <span class="d-flex justify-content-end"
              ><h6><b-badge @click="changeCat('position')" variant="outline-purple" :class="{ active: currentCat === 'position' }" class="mr-2">Position</b-badge
              >
              <b-badge @click="changeCat('company')" variant="outline-purple" :class="{ active: (currentCat === 'company') }" class="mr-2">Company</b-badge
              ><b-badge @click="changeCat('candidate')" variant="outline-purple" :class="{ active: currentCat === 'candidate' }" class="mr-2">Candidate</b-badge
              ><b-badge @click="changeCat('culture')" variant="outline-purple" :class="{ active: currentCat === 'culture' }" class="mr-2">Culture</b-badge
              ></h6></span
            ></b-card-title>
        <hr>
        <b-list-group flush>
          <b-list-group-item v-if="currentCat === 'position' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Salary: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">$75,000</b-badge
              ></span
            ></b-list-group-item
          >
           <b-list-group-item v-if="currentCat === 'position' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Benefits: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">Healthcare</b-badge
              ><b-badge variant="purple" class="mr-2">Dental</b-badge
              ><b-badge variant="purple" class="mr-2">401k</b-badge
              ></span
            ></b-list-group-item
          >
          <b-list-group-item v-if="currentCat === 'position' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Department: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">Marketing</b-badge
              ></span
            ></b-list-group-item
          >
          <b-list-group-item v-if="currentCat === 'position' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Location: </span>
            <span class="d-flex justify-content-end"
              ><b><b-badge variant="purple" class="mr-2">Hybrid</b-badge
              >– <b-badge variant="purple" class="mr-2">Minneapolis, MN</b-badge
              ></b></span
            ></b-list-group-item
          ><b-list-group-item v-if="currentCat === 'candidate' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold"> Skills:</span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">Copywriting</b-badge
              ><b-badge variant="purple" class="mr-2">SEO</b-badge
              ><b-badge variant="purple" class="mr-2">SEM</b-badge
              ><b-badge variant="purple" class="mr-2">Advertising</b-badge
              ><b-badge variant="purple" class="mr-2"
                >Social Media</b-badge
              ></span
            ></b-list-group-item
          ><b-list-group-item v-if="currentCat === 'candidate' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Interests: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">Advocacy</b-badge
              ><b-badge variant="purple" class="mr-2">Improv</b-badge
              ><b-badge variant="purple" class="mr-2">Wildlife</b-badge
              ><b-badge variant="purple" class="mr-2">Sleeping</b-badge
              ><b-badge variant="purple" class="mr-2">Humor</b-badge><b-badge variant="purple" class="mr-2">Science</b-badge><b-badge variant="purple" class="mr-2">Animals</b-badge><b-badge variant="purple" class="mr-2">Nature</b-badge></span
            ></b-list-group-item
          ><b-list-group-item v-if="currentCat === 'company' || currentCat ===  '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Company Type: </span>
            <span class="d-flex justify-content-end"
              ><b><b-badge variant="purple" class="mr-2">Non-profit</b-badge
              ></b></span
            ></b-list-group-item
          ><b-list-group-item v-if="currentCat === 'position' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold"
              >Interpersonal Level:
            </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">High</b-badge
              ></span
            ></b-list-group-item
          >
          <b-list-group-item v-if="currentCat === 'position' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Creativity Level: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">High</b-badge
              ></span
            ></b-list-group-item
          >
          <b-list-group-item v-if="currentCat === 'position' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Responsibilities: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">General Advocacy</b-badge
              ><b-badge variant="purple" class="mr-2">Education</b-badge
              ><b-badge variant="purple" class="mr-2">Donor Engagement</b-badge
              ></span
            ></b-list-group-item
          >
          <b-list-group-item v-if="currentCat === 'candidate' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Minimum Professional Experience: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">Some</b-badge
              ></span
            ></b-list-group-item
          >
          <b-list-group-item v-if="currentCat === 'position' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Room for Professional Advancement: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">Some</b-badge
              ></span
            ></b-list-group-item
          >
          <b-list-group-item v-if="currentCat === 'culture' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Vibe: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">Casual</b-badge
              ><b-badge variant="purple" class="mr-2">Laid-back</b-badge
              ></span
            ></b-list-group-item
          >
          <b-list-group-item v-if="currentCat === 'culture' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Camraderie: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">High</b-badge
              ></span
            ></b-list-group-item
          >
           <b-list-group-item v-if="currentCat === 'company' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Company Diversity: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">Medium</b-badge
              ></span
            ></b-list-group-item
          >
           <b-list-group-item v-if="currentCat === 'culture' || currentCat === '' " button> 
            <span class="d-flex justify-content-start font-weight-bold">Work-life Balance: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">High</b-badge
              ></span
            ></b-list-group-item
          >
          <b-list-group-item v-if="currentCat === 'position' || currentCat === '' " button> 
            <span class="d-flex justify-content-start font-weight-bold">Pressure: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">Low-Medium</b-badge
              ><b-badge variant="purple" class="mr-2">Normal Deadlines</b-badge
              ></span
            ></b-list-group-item
          >
<b-list-group-item v-if="currentCat === 'position' || currentCat === '' " button> 
            <span class="d-flex justify-content-start font-weight-bold">Hours: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">9 - 5</b-badge
              ><b-badge variant="purple" class="mr-2">No late nights</b-badge
              ><b-badge variant="purple" class="mr-2">Flexible</b-badge
              ><b-badge variant="purple" class="mr-2">Some evening events</b-badge
              ><b-badge variant="purple" class="mr-2">None - little travel</b-badge
              ></span
            ></b-list-group-item
          >
  <b-list-group-item v-if="currentCat === 'position' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Perks: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">Zoo Membership</b-badge
              ></span
            ></b-list-group-item
          >
            <b-list-group-item v-if="currentCat === 'company' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Mission / Meaning: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">High</b-badge
              ></span
            ></b-list-group-item
          >
          <b-list-group-item v-if="currentCat === 'position' || currentCat === '' " button>
            <span class="d-flex justify-content-start font-weight-bold">Marketing Media: </span>
            <span class="d-flex justify-content-end"
              ><b-badge variant="purple" class="mr-2">Social</b-badge
              ><b-badge variant="purple" class="mr-2">Print</b-badge
              ><b-badge variant="purple" class="mr-2">Podcasts</b-badge
              ><b-badge variant="purple" class="mr-2">Live Events</b-badge
              ><b-badge variant="purple" class="mr-2"
                >Gorilla Marketing</b-badge
              ></span
            ></b-list-group-item
          >
        </b-list-group></b-card
      >
    </b-container>
  </div>
</template>

<script>
export default {
    data(){
        return{
            currentCat:""
        }
    },
    methods:{
        changeCat(cat){

            if(this.currentCat === cat){
                this.currentCat = ''
            } else this.currentCat = cat;
        }
    }
};
</script>

<style scoped>
.badge-purple {
  background: #713e9e;
  color: white;
  min-width: 4em;
  font-size: 1em;
  padding: 8px;
}
.badge-outline-purple {
  background: white;
  color: #713e9e;
  min-width: 4em;
  font-size: 1em;
  padding: 8px;
  border: #713e9e 3px solid
}
.badge-outline-purple:hover {
  background: #713e9e;
  color: white;
  min-width: 4em;
  font-size: 1em;
  padding: 8px;
  border: #713e9e 3px solid
}
.badge-outline-purple.active  {
  background: #713e9e;
  color: white;
  min-width: 4em;
  font-size: 1em;
  padding: 8px;
  border: #713e9e 3px solid
}
.card {
  border-color: #713e9e;
}
</style>
