import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'

import lodash from "lodash";
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import '@/assets/fontawesome/all.css'
//import wysiwyg from "vue-wysiwyg";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuex from 'vuex'

import { firebaseApp, db } from './firebase'
import { env } from '../.eslintrc'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueAnalytics from 'vue-analytics';

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

import AsyncComputed from 'vue-async-computed'
import { debounce } from "debounce";
//import 'vue-wysiwyg/dist/vueWysiwyg.css'
Vue.config.productionTip = false
Vue.use(VueQuillEditor, /* { default global options } */)
//Vue.use(wysiwyg, {}); 
Vue.use(lodash, { name: "custom", lodash: lodash });
Vue.use(BootstrapVue)
Vue.use(debounce);
Vue.use(IconsPlugin)
Vue.use(env)
Vue.use(Vuex)
Vue.use(AsyncComputed)
new Vue({
  router,
  store,
  firebaseApp,
  db,
  beforeCreate() {
		this.$store.commit('initializeStore');
  },
  render: h => h(App)
}).$mount('#app')

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  window.localStorage.setItem('store', JSON.stringify(state));
});