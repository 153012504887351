var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',[_c('div',[_c('b-row',{staticClass:"mt-4",class:{
                switchbackground: _vm.checkHighlighted
              }},[_c('b-col',{staticClass:"mr-3 mt-2",attrs:{"cols":"1"},on:{"click":function($event){return _vm.highlight()}}},[(_vm.cat !== 'insight')?_c('b-icon',{attrs:{"icon":_vm.checkHover,"font-scale":"3"}}):_vm._e(),(_vm.cat === 'insight')?_c('b-img',{attrs:{"src":_vm.checkLogoColor(_vm.i)}}):_vm._e(),_c('b-icon',{staticClass:"text-center mx-auto",attrs:{"icon":"arrow-up"}})],1),_c('b-col',{attrs:{"cols":"10"}},[_vm._v(_vm._s(_vm.name)),_c('div',{staticClass:"text-left mt-2 fact",domProps:{"innerHTML":_vm._s(_vm.data.phrase)}})]),_c('b-row',[(
                    _vm.checkHighlighted &&
                    _vm.type !== 'length' &&
                    _vm.category === 'external'
                  )?_c('b-col',{staticClass:"mt-4 ml-4 whiteOnPurple",attrs:{"cols":"8"}},[_c('h5',[_vm._v("Do we get bad information? Fix the record below!")]),_c('b-form',{attrs:{"inline":""}},[_c('b-form-input',{staticClass:"fixText",attrs:{"placeholder":_vm.data.data.toString()},model:{value:(_vm.fixedText),callback:function ($$v) {_vm.fixedText=$$v},expression:"fixedText"}}),_c('b-button',{staticClass:"ml-0",attrs:{"squared":"","variant":"outline-purple"},on:{"click":function($event){return _vm.fixData()}}},[_vm._v("Change")])],1)],1):_vm._e()],1),(_vm.checkHighlighted && _vm.options)?_c('b-row',{staticClass:"mt-5"},[(_vm.checkHighlighted && _vm.options)?_c('b-row',[_c('b-container',[_c('h5',{staticClass:"ml-5"},[(_vm.overview !== undefined)?_c('div',[_vm._v(" "+_vm._s(_vm.overview)+" "),_c('hr')]):_vm._e(),_vm._v(" Where would you place yourself on the spectrum from "),_c('b',[_vm._v(_vm._s(_vm.options[0]))]),_vm._v(" to "),_c('b',[_vm._v(_vm._s(_vm.options[1]))]),_vm._v("? ")])])],1):_vm._e(),_c('h5',{staticClass:"ml-3"}),_c('b-form-input',{staticClass:"range",attrs:{"variant":"purple","id":"range-1","type":"range"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1):_vm._e(),(
                  _vm.checkHighlighted && _vm.getAllOccurences
                )?_c('div',{class:{
                  switchbackground: _vm.checkHighlighted,
                }},[_c('h5',{staticClass:"ml-3 mr-5"},[_vm._v(" Are any of these incorrect? Click on the "),_c('b-icon',{attrs:{"icon":"x-circle"}}),_vm._v(" to remove them."),_c('br'),_c('br')],1),_vm._l((_vm.getAllOccurences),function(item,q){return _c('div',{key:q},[_c('b-icon',{staticClass:"ml-5 mr-1",attrs:{"icon":"x-circle"},on:{"click":function($event){return _vm.removeItem(q)}}}),_c('span',{domProps:{"innerHTML":_vm._s(item)}})],1)})],2):_vm._e(),(
                  _vm.type === 'length' && _vm.checkHighlighted
                )?_c('div',{class:{
                  switchbackground: _vm.checkHighlighted
                }},[_c('h5',{staticClass:"ml-3 mr-5"},[_vm._v(" Are any of these incorrect? Click on the "),_c('b-icon',{attrs:{"icon":"x-circle"}}),_vm._v(" to remove them."),_c('br'),_c('br'),_vm._v("If any of them are correct, but you really don't want to be associated with them, feel free to click the Archive "),_c('b-icon',{attrs:{"icon":"archive"}}),_vm._v(" to hide it. ")],1),_vm._l((_vm.data.arr),function(item,q){return _c('div',{key:q},[_c('b-icon',{staticClass:"ml-5 mr-1",attrs:{"icon":"x-circle"},on:{"click":function($event){return _vm.removeItem(q)}}}),_c('b-icon',{staticClass:"ml-1 mr-4",attrs:{"icon":"archive"},on:{"click":function($event){return _vm.archiveItem(q)}}}),_c('span',{domProps:{"innerHTML":_vm._s(item)}})],1)})],2):_vm._e(),(_vm.checkHighlighted)?_c('b-row',[_c('b-col',{staticClass:"ml-4",attrs:{"cols":"2"}},[(_vm.options)?_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.options[0].charAt(0).toUpperCase() + _vm.options[0].slice(1))+" ")]):_vm._e()]),_c('b-col',{attrs:{"cols":"2","offset":"7"}},[(_vm.options)?_c('div',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.options[1].charAt(0).toUpperCase() + _vm.options[1].slice(1))+" ")]):_vm._e()])],1):_vm._e()],1),_c('hr')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }