<template>
  <div class="landing page">
    <b-container>
    <div class="headline">
      <h2 v-html="homepage.tagline1.text"></h2>
      <h3 v-html="homepage.tagline2.text"></h3>
    </div> 

    <h4><b>Sign up here</b></h4>
    <b-button-group vertical class="mb-5">
    <b-button
      class="mt-4 mx-auto"
      :to="homepage.button1.to"
      size="lg"
      squared
      variant="purple"
      >Candidates</b-button
    >
        <b-button
      class="mt-4 mx-auto w-100"
      :to="homepage.button1.to"
      size="lg"
      squared
      variant="purple"
      >Companies</b-button
    >
     <b-button
      class="mt-4 mx-auto w-100"
      :to="homepage.button1.to"
      size="lg"
      squared
      variant="purple"
      >Partners</b-button
    ></b-button-group><br>

    <h5 class="mt-4 mb-4 w-100">Want to learn more <b>Before</b>* you sign up?</h5>
     <b-button
      class="mt-2 mb-5"
      :to="homepage.button2.to"
      size="md"
      squared
      variant="outline-purple"
      >Read our dictionary</b-button
    >
</b-container>

<div class="home-footer">
   <b-row><b-col sm="12" md="5" offset-md="1"><p class="text-left">* <b>Warning:</b></p><p class="text-left">There may be more punny language on this site. Apologies.</p><p class="text-left">This site was created for humans, not algorithms - with fingers and short attention spans usually - so we intentionally kept it sparse.</p></b-col>
   <b-col sm="12" md="5"><p></p><p class="text-left">Don’t worry, our moms know how complex things are in the background, and they tell us that they are very proud of us.</p><p class="text-left">We wrote another line here, not because it is adding any context or using keywords like "big data" or "job search", but because the footer looked lopsided. </p></b-col></b-row><b-row><b-col sm="12" md="6" offset-md="3">
   <hr class="white-hr">
   <p>No llamas were hired in the creation of this website.</p>
   <p>All rights reserved.</p><p><b>© Before 2022</b></p></b-col></b-row> </div>
  </div>
</template>

<script>
export default {
    components: {
  },
  data() {
    return {
      page: "Landing",
      data: {}
    };
  },
  created(){
    this.homepage = this.$store.getters.getWebsiteText['homepage']
  }
};
</script>

<style scoped>
.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}

.btn-outline-purple:hover,
.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}

.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}

.btn-purple{  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}

.btn-purple:hover,
.btn-purple.active {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}

</style>
