<template>
  <div class="page">
 <b-container class="content">
      <b-card bg-variant="purple" header-text-variant="white">
        <div class="accordion" role="tablist">

          <h5 class="text-left text-white mb-4" v-html="faq.heading.text"></h5>
          <b-card 
            no-body
            v-for="question in orderFAQ"
            :key="question.qid"
            class="mb-1"
          >
          <div v-if="question.title !== undefined">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="question.slug" class="text-left" variant="purple">{{
                question.title
              }}</b-button>
            </b-card-header>
            <b-collapse
              :id="question.slug"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>  <b-card-text
                  v-html="question.definition"
                  class="text-left dark email"
                ></b-card-text>
                <div v-if="question.definition !== undefined"><hr></div>
                <b-card-text
                  v-html="question.text"
                  class="text-left dark email"
                ></b-card-text>
              </b-card-body>
            </b-collapse>
            </div>
          </b-card>
        </div>
      </b-card>
    <b-button
      class="mt-4 mb-5"
      style="min-width: 10em !important"
      to="/start"
      size="lg"
      squared
      variant="outline-purple"
      >Sign Up</b-button
    >
    </b-container>
    

   <div class="home-footer">
   <b-row><b-col sm="12" md="5" offset-md="1"><p class="text-left">Let's be honest, this isn't an actual dictionary, and to at least one of our mother's dismay, we are not professional lexicographers.</p><p class="text-left"></p><p class="text-left">That said, we believe that words and definitions matter. If we are going to have a conversation, we should really make sure that everyone else understands the words we are saying as we intend them to.</p>
   </b-col>
   <b-col sm="12" md="5">
   <p class="text-left">We may seem a bit "flippant" (<em>adjective</em>, "not showing a serious or respectful attitude."), but we would like to assure you that we are anything but.</p><p class="text-left">We don't believe that your life can be summed up by a sheet of paper. We know firsthand what it feels like to be judged by people who think they know everything about you, because they know a single detail.</p> </b-col></b-row>
   <b-row><b-col sm="12" md="6" offset-md="3">
   <hr class="white-hr">
   <p>No llamas were hired in the creation of this website.</p>
   <p>All rights reserved.</p><p><b>© Before 2022</b></p></b-col></b-row> </div>
  </div>
</template>

<script>
//import faq from "@/assets/faq.json";
export default {
data(){
    return {
        faq: ''
    }
},
computed:{
  orderFAQ(){
    return Object.entries(this.faq).filter(el => el[1].title).map(el => el[1]).sort((a,b) => a.qid-b.qid)
    
  }
},
created(){
  this.faq = this.$store.getters.getWebsiteText['faq']
}
}
</script>

<style scoped>

.dark{
    color: black;
}

.btn-purple:hover{
      background-color: #713e9e;
        color: #fff;
}
.btn-purple{
      background-color: #713e9e;
        color: #fff;
}
.bg-purple{
      background-color: #713e9e;
        color: #fff;
}

a.email {
    color: #9a5bd2 !important;
    text-decoration: none;
    background-color: transparent;
}

.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #9a5bd2;
}

.btn-outline-purple:hover,
.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}

.content{
  margin-bottom: 30%
}
</style>
