<template>
  <div class="instructions-box">
     <ContentCheck></ContentCheck>

<b-container>
      <b-card
        border-variant="primary"
        header-bg-variant="purple"
        header-text-variant="white"
        align="left"
    class="mb-5"
  >
  <template #header>
                <span class="float-left"><h4>Hi {{getFirstName}}!</h4></span><span class="float-right"><b-icon v-b-toggle.collapse-instructions icon="chevron-down"></b-icon></span>

      </template>
      <b-card-text><h4 class="mt-4">This page is your very own, personalized, dashboard.</h4> <p>
        <p>In short, we tell you what we know about you. We also tell you what we assume about you based on your responses to our different questionnaires. You tell us if we're wrong. And how important that specific thing is to you.</p><p>
        Click the <b><b-icon-chevron-down></b-icon-chevron-down></b> in the right corner to learn a little more about how things works in <b>Before</b>-land. </p></b-card-text>
    <b-collapse id="collapse-instructions">
    <b-card-text class="text-left">
      <hr class="mx-5">
      <b-row>
       <b-col> <h6 class="mt-4">Good news! We don’t need you to type anything in.<sup>1</sup></h6>
  
            <h6 class="mt-4">When you engage with each section (couple minutes of your time at most) we learn more about who you really are.</h6>
            <h6 class="mt-4"> You'll be able to see that in the our <b>"What We Know"</b> box below.</h6>            <h6 class="mt-4"> And if we make a mistake, let us know! Click on the <b-icon icon="plus"></b-icon> and tell us how you actually see yourself.</h6>
            <h6 class="mt-4">In the future, this is where we are going to show you profiles of companies who want to meet with you (based on your anonymized profile).</h6>
            </b-col><b-col><h6 class="mt-4">Remember that we are only showing your anonymized profiles to companies we think you'll like.</h6>
            <h6 class="mt-4"> So if you are an ardent environmentalist, we will not show your profile to a petrochemical company, no much how much they want to pay for your soul... err... salary.<sup>3</sup></h6>
            <h6 class="mt-4"> Still, we may make a mistake, because we're human<sup>4</sup>, and you always have the right of refusal, without them ever knowing who you are.</h6>
            <h6 class="mt-4"> We hope you enjoy this process so we can introduce you to a company and role that you will
            really enjoy.</h6></b-col>
        </b-row>
    </b-card-text>
    <b-card-footer class="text-left"> 
      <h5 class="mt-3">Random footnotes that you can ignore:</h5>
      <b-row><b-col>
      <p class="mt-3"><em><sup>1</sup>Ok, there is a single place<sup>2</sup> where we ask you to type a few words. If you choose not to do that, that's totally fine.
              </em></p>
              <p class="mt-3"><em><sup>2</sup>Also, you may choose to type in a few words to fix inaccuracies in the data.
              </em></p>
              <p class="mt-3"><em><sup>3</sup>I would wonder if the words are related, but "salary" comes from the Latin for salt (<em>sal</em>), for a Roman soldier being paid money to buy salt. </em></p><p><em>Ironically, the word "soldier" actually comes from the word for a coin, as in "someone who serves in the army for pay". </em></p>
              </b-col><b-col> <p class="mt-3"><em>"Soul" comes from Old English / Proto-Germanic languages.</em></p><p><em>Though, if we were trying to find a connection, we could always look at The Killers' lyric in "All These Things That I've Done" - "I've got soul, but I'm not a soldier".
              </em></p>
              <p class="mt-3"><em><sup>4</sup>We're big fans of having humans having conversations with humans, instead of humans being rejected by algorithms before a human even ever sees their resume.
              </em></p></b-col>
              </b-row>
              </b-card-footer>
              </b-collapse>
  </b-card>
  </b-container>
  </div>
</template>

<script>
import ContentCheck from "@/components/sections/ContentCheck.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      show: true,
      minimized: false,
    };
  },
  components: { ContentCheck },
  methods:{
    toggleView(){
    this.minimized = !this.minimized
    }
  },
  computed: {
      ...mapGetters([
      "getCurrentPage",
      "getCurrentTagRef",
      "getFlow",
      "getCurrentAuthUser",
      "getUID",
      "getCurrentAuthUserName",
      "getBasicDataByName",
    ]),
     getFirstName() {
      
      return this.getCurrentAuthUserName.split(" ")[0];
    }
  },
};
</script>

<style scoped>
.intro {
  border: 0.25em solid #713e9e;
  margin-top: 2em !important;
}
.bg-purple {
   background: #713e9e !important;
   color: #ffffff
}
.card{
  border: 0.25em solid #713e9e !important;
      border-radius: 4px;
}

.card-footer {
    padding: 0.25rem 1.25rem;
    background-color: #713e9e;
    border-top: 1px solid #713e9e;
    color: white;
}
@media only screen and (max-width: 800px) {
  /* For mobile phones: */
  .instructions-box {
    font-size: small;
    
  }
 
}
</style>
