<template>
  <div css="interstitial">
      <b-container>
<b-list-group>
  <b-list-group-item class="text-left">Looking up your education <span v-if="getCollection('education')" class="text-right">DONE</span> </b-list-group-item>
  <b-list-group-item class="text-left">Looking up your work history <span v-if="getCollection('experiences')" class="text-right">DONE</span> </b-list-group-item>
  <b-list-group-item class="text-left">Looking up your skills <span v-if="getCollection('skills')" class="text-right">DONE</span> </b-list-group-item>
  <b-list-group-item class="text-left">Looking up your interests <span v-if="getCollection('interests')" class="text-right">DONE</span> </b-list-group-item>
</b-list-group>
      </b-container>
  </div>
</template>

<script>
import {
  collection,
  getDoc,
  getDocs,
  doc,
  db,
  serverTimestamp,
  addDoc,
  setDoc,
  query,
  where,
} from "@/firebase";
import { candidatesCollection, companiesCollection } from "@/db";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      show: false,
      allCollections: [],
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentPage",
      "getCurrentTagRef",
      "getFlow",
      "getCurrentAuthUser",
      "getUID",
      "getCurrentAuthUserName",
      "getAllFlowNumber",
      "getIndustryTags",
      
      "getExperiences",
      "getOnboardingCollection",
      "getList",
    ]),
  },
  methods: {
    async getAllCategories() {
      const candidateRef = doc(
        candidatesCollection,
        this.$store.getters.getUID
      );
      const collectRef = collection(candidateRef, "collections");
      let list = [];
      const collectionRef = await getDocs(collectRef);
      collectionRef.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //console.log(doc.id, " => ", doc.data());
        list.push({ category: doc.id, data: doc.data() });
      });
      this.allCollections = list;
      this.$store.dispatch("changeCollections", list);
      this.getCompanyTags();
    },
    async getAllOnboarding() {
      const candidateRef = doc(
        candidatesCollection,
        this.$store.getters.getUID
      );
      const collectRef = collection(candidateRef, "onboarding");
      let list = [];
      const collectionRef = await getDocs(collectRef);
      collectionRef.forEach((doc) => {
        list.push({ category: doc.id, data: doc.data() });
      });
      this.$store.dispatch("changeOnboarding", list);
    },

    async getCompanyTags() {
      //let links = this.$store.getters.getCollection("experiences").data.linkedinURLSSet;

      let links =
        this.$store.getters.getCollection("experiences").data.linkedinURLSSet;
      if (links.length > 10) {
        links = links.slice(0, 9);
      }
      let companyTagList = [];
      const q = query(companiesCollection, where("linkedin_url", "in", links));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        companyTagList.push(doc.data().tags);
      });
      this.$store.dispatch("changeIndustryTags", companyTagList.flat());
    },

    async getLists() {
      let listlist = [];
      const candidateRef = doc(
        candidatesCollection,
        this.$store.getters.getUID
      );
      const collectRef = collection(candidateRef, "lists");
      const querySnapshot = await getDocs(collection(candidateRef, "lists"));
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        listlist.push({ id: doc.id, data: doc.data() });
      });
      this.$store.dispatch("changeLists", listlist);
    },
  },
  created() {
    this.getAllCategories();
    this.getAllOnboarding();
    this.getLists();
  },
};
</script>

<style></style>
