<template>
  <div class="sign-in-new-user">


<h4>Hi {{getName}}, and welcome to <b>Before</b>!</h4>
<h5>Before we start the onboarding process, we'd just like to confirm your email address.</h5>
<LoginWithEmail></LoginWithEmail>

  </div>
</template>

<script>
import LoginWithEmail from "@/views/Signup/LoginEmail.vue"
export default {
    components:{
        LoginWithEmail
    },
computed: {
    getName(){
        return this.$store.getters.getName.split(" ")[0];
    }
}
}
</script>

<style>

</style>