<template>
  <div>
    <b-container>
      <b-card no-body>
        <b-card-title
          ><b-row class="mt-4 mb-2"><b-col sm="12" md="6" class="mt-2 mb-2 font-weight-bold text-center"
            >What we know:</b-col
          >
          <b-col sm="12" md="6" class="mt-2 text-center"
            ><h6>
              <b-button
                @click="changeCat('resume')"
                variant="outline-purple"
                :class="{ active: currentCat === 'resume' }"
                class="mr-2 px-2 py-2"
                >Facts</b-button
              >
            <b-button
                @click="changeCat('insight')"
                variant="outline-purple"
                :class="{ active: currentCat === 'insight' }"
                class="mr-2 px-2 py-2"
                >Insights</b-button
              >
            </h6></b-col
          ></b-row></b-card-title
        >
        <b-card-text class="quote" v-if="Object.keys(narrativeFacts).length === 0 || currentCat === ''">
           <hr />
          <b-row>
            <b-col sm="12" md="8" offset-md="2"><h5>"There are only two kinds of people who are really fascinating - people who know absolutely everything, and people who know absolutely nothing."</h5></b-col>
          </b-row>
<b-row class="text-center  mt-2"><b-col sm="12" md="8" offset-md="2"><h6>– Oscar Wilde</h6></b-col></b-row>
         <b-row class="text-center mx-1 mt-2"> <b-col sm="12" md="8" offset-md="2"><h6>(Click on one of the buttons above to decide which one we are.)</h6></b-col> </b-row>
        </b-card-text>
        <b-card-text class="quote" v-if="Object.keys(narrativeFacts).length === 0 && currentCat !== ''">
          <b-row>
            <b-col sm="12" md="8" offset-md="2"><h5>It looks like we don't know anything... yet"</h5></b-col>
          </b-row>
           <b-row v-if="currentCat === 'insight'">
            <b-col sm="12" md="8" offset-md="2"><h5 >Have you answered any of our questions yet? This may shock you, but we aren't actually mindreaders.</h5><h5 >Though, if you <b>are</b> in the market for a talent matching agency who doesn't actually care about what you are looking for, and only about what you have email us at <a href="mailto:referrals@before.dev">referrals@before.dev</a> and we'll be happy to make a referral.</h5></b-col>
          </b-row>
          <b-row v-if="currentCat === 'resume'">
            <b-col sm="12" md="8" offset-md="2"><h5>It seems that we have not been able to get your work experience information yet. It could just be a bug, or it could be that our data partners do not have information on you.</h5><h5>You can still fill out all of the questionnaires, and we will send you an alternative method of getting us your work experience data.</h5></b-col>
          </b-row>
        </b-card-text>
        <b-list-group flush class="accordion" role="tablist" v-if="Object.keys(narrativeFacts).length !== 0 && currentCat !== ''">
        <b-list-group-item flush
            v-for="(data, i) in filteredList"
            :key="i"
          >

              <b-row class="text-left align-items-start font-weight-bold">
                <b-col sm="2"><h4><b-icon
                  v-b-toggle="'collapse-' + [data.name]"
                  icon="plus"
                ></b-icon>
                </h4>
 </b-col> <b-col sm="8" md="10">
                <h5>
                {{ data.phrase }}
              </h5></b-col>
              </b-row>
            <b-collapse
              :id="`collapse-` + [data.name]"
              accordion="my-accordion-category"
              role="tabpanel"
              class="mb-3"
            >
            <hr class="mt-4 mb-4">
              <div v-if="data['type'] === 'length'">
                <h6 class="mt-4 text-left">
                  Are any of these incorrect? Click on the
                  <b-icon icon="x-circle"></b-icon> to remove them.<br /><br />
                </h6>
                <div
                  v-for="(datum, d) in data['data']"
                  :key="d"
                  class="text-left"
                >
                  <b-icon
                    @click="removeFact(data.name, data['data'], datum)"
                    class="mr-2"
                    icon="x-circle"
                  ></b-icon>
                  <span v-if="typeof datum !== 'object'">{{ datum }}</span>
                  <span v-if="typeof datum === 'object'">{{ datum.name }}</span>
                </div>
                <span
                  v-if="
                    data['data'].length === 0 &&
                    data['name'] === 'languages_list'
                  "
                >
                  <h4 class="text-left">You must be an American.</h4></span
                >
              </div>
              <div v-if="data['type'] === 'text'" class="text-left">
                <b-row>
                  <b-col cols="12">
                    <h6>Do we get bad information? Fix the record below!</h6>
                    <b-form inline
                      ><b-form-input
                        class="fixText"
                        v-model="data['data']"
                        :placeholder="data['data'].toString()"
                      ></b-form-input>
                      <b-button squared class="ml-0" variant="outline-purple"
                        >Change</b-button
                      >
                    </b-form>
                  </b-col>
                </b-row>
              </div>

              <div v-if="data['type'] === 'options'">
                <b-row class="mt-3">
                  <b-row>
                    <b-container
                      ><h6 class="" v-if="false">
                        <div v-if="overview !== undefined">
                          {{ overview }}
                          <hr />
                        </div>
                        Where would you place yourself on the spectrum from
                        <b>{{ options[0] }}</b> to <b>{{ options[1] }}</b
                        >?
                      </h6></b-container
                    >
                  </b-row>
                  <b-row>
                    <h6 class="text-left mb-2 ">
                      How good are you at {{ data["data"] }}?
                    </h6>
                    <b-form-input
                      class="range mb-3"
                      variant="purple"
                      id="range-1"
                      v-model="value"
                      type="range"
                    ></b-form-input>
                  </b-row>
                  <b-row>
                    <h6 class="text-left mb-2  mt-2">
                      How important is it for you to have a job that focuses on
                      {{ data["data"] }}?
                    </h6>
                    <b-form-input
                      class="range  mb-3"
                      variant="purple"
                      id="range-1"
                      v-model="value2"
                      type="range"
                    ></b-form-input>
                  </b-row>
                </b-row>
              </div> </b-collapse
          ></b-list-group-item>
        </b-list-group>
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentCat: "",
      fixedText: "",
      value: 80,
      value2: 80,
    };
  },
  props:["narrativeFacts","launchKey"],
  methods: {
    removeFact(name, arr, val) {
      this.narrativeFacts[name]["data"] = arr.filter((el) => el !== val);
      //console.log(this.narrativeFacts[name]["data"]);
    },changeCat(cat){
      this.currentCat = cat;
      this.$forceUpdate();
    }
  },
  created() {
    //this.narrFacts();
  },
  
  computed: {
    narratives() {
      return this.$store.getters.getNarratives;
    },
    topTerms() {
      return this.$store.getters.getTopTerms.map((el) => el.name);
    },
    filteredList(){
      let nf = Object.fromEntries(Object.entries(this.narrativeFacts).filter(el => el[1].filter === this.currentCat));
      return nf
    }

    },
  watch: {
  }
  }
    //filter() {
    // this.reset();
    // },
</script>

<style scoped>

.btn-outline-purple {
  color: #713e9e;
  background-color: #ffffff;
  border-color: #713e9e;
}

.btn-outline-purple:hover,
.btn-outline-purple.active {
  color: #ffffff;
  background-color: #713e9e;
  border-color: #713e9e;
}
@media only screen and (max-width: 599px) {
  /* For mobile phones: */
  .list-group-item {
    position: relative;
    display: block;
    padding: 0 0 !important;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
  .quote {
    font-size: smaller !important;
  }
}


.range {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 10px; /* Specified height */
  outline: none; /* Remove outline */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.range::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #713e9e; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.range::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #713e9e; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
</style>
