<template>
  <div v-if="this.$store.getters.isLocalhost">
    <b-button variant="outline-purple" class="mr-3" to="/managetexts">Manage Texts</b-button>
    <b-button variant="outline-purple" to="/managedata">Manage Data</b-button>
    <hr>
    Content Check
    <b-nav class="mx-auto" small>
      <div v-for="item in list" :key="item.lid">
        <b-nav-item @click="showContent(item.lid)"
          ><b-icon v-if="checkContent(item.lid)" icon="check"></b-icon
          >{{ item.name }}</b-nav-item
        >
      </div>
    </b-nav>
    <hr />
    <div v-if="show">
      <h4>{{ contentName }}</h4>
      <div v-if="this.dataFromContent === undefined">Nothing to see here.</div>
      <div v-if="typeof this.dataFromContent === 'array'">
        <b-table :items="dataFromContent"></b-table>
      </div>
      <div v-if="typeof this.dataFromContent === 'string'">
        {{ dataFromContent }}
      </div>
      <div v-if="typeof this.dataFromContent === 'object'">
        <b-table :items="Object.entries(dataFromContent)"></b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      contentName: "",
      dataFromContent: [],
      list: [
        {
          lid: 0,
          name: "UID",
          vuex: "getUID",
          source: "store",
          content: [],
        },
        {
          lid: 1,
          name: "Name",
          vuex: "getName",
          source: "store",
          content: [],
        },
        {
          lid: 2,
          name: "PDL",
          vuex: "getPDL",
          source: "store",
          content: [],
        },
        {
          lid: 3,
          name: "Basic Data",
          vuex: "getAllBasicData",
          source: "store",
          content: [],
        },
        {
          lid: 4,
          name: "narrativeFacts",
          vuex: "getNarrativeFacts",
          source: "store",
          content: [],
        },
        {
          lid: 5,
          name: "Choices",
          vuex: "getAllChoices",
          content: [],
        },
        {
          lid: 6,
          name: "TermValues",
          vuex: "getAllTermValues",
          content: [],
        },
        {
          lid: 7,
          name: "Questionnaires",
          vuex: "getQuestionnaires",
          source: "store",
          content: [],
        },
        {
          lid: 8,
          name: "Skills",
          vuex: "getSkills",
          source: "basicData",
          content: [],
        },

        {
          lid: 9,
          name: "Company Sizes",
          vuex: "getCompanySizesList",
          source: "basicData",
          content: [],
        },
        {
          lid: 10,
          name: "Industry Tags",
          vuex: "getIndustryTags",
          source: "store",
          content: [],
        },
        {
          lid: 11,
          name: "Narratives",
          vuex: "getNarratives",
          source: "store",
          content: [],
        },
        {
          lid: 12,
          name: "Lists",
          vuex: "getLists",
          source: "store",
          content: [],
        },
        {
          lid: 13,
          name: "Onboarding",
          vuex: "getOnboarding",
          source: "store",
          content: [],
        },
        {
          lid: 14,
          name: "getTopTerms",
          vuex: "getTopTerms",
          source: "basicData",
          content: [],
        },
        {
          lid: 15,
          name: "Flow",
          vuex: "getAllFlow",
          source: "store",
          content: [],
        },
        {
          lid: 16,
          name: "Insights",
          vuex: "getAllInsights",
          source: "store",
          content: [],
        },
        {
          lid: 17,
          name: "Professional Data",
          vuex: "getProfessionalData",
          source: "store",
          content: [],
        },
        {
          lid: 18,
          name: "Blink",
          vuex: "getCompanyChoices",
          source: "store",
          content: [],
        },{
          lid: 19,
          name: "getWebsiteText",
          vuex: "getWebsiteText",
          source: "store",
          content: [],
        },
        {
          lid: 20,
          name: "getWebsiteData",
          vuex: "getWebsiteData",
          source: "store",
          content: [],
        },
      ],
    };
  },
  methods: {
    checkContent(lid) {
      try {
        this.list[lid].content = this.$store.getters[this.list[lid].vuex];
        if (
          Array.isArray(this.list[lid].content) &&
          this.list[lid].content.length > 0
        )
          return true;
        else if (
          typeof this.list[lid].content === "object" &&
          Object.keys(this.list[lid].content).length > 0
        )
          return true;
        else if (
          typeof this.list[lid].content === "string" &&
          this.list[lid].content.length > 0
        )
          return true;
        else return false;
      } catch (error) {
        return false;
      }
    },
    showContent(lid) {
      if (this.contentName === this.list[lid].name) {
        this.contentName = "";
        this.dataFromContent = [];
        this.show = false;
      } else {
        this.contentName = this.list[lid].name;
        this.dataFromContent = this.list[lid].content;
        this.show = true;
      }
    },
  },
};
</script>

<style scoped>
.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}
</style>
