<template>
  <div class="complete-login"></div>
</template>

<script>
// tslint:disable: no-console
import {
  auth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  doc,
  db,
  collection,
  getDoc,
} from "@/firebase";
export default {
  data() {
    return {
      current: "ahoy",
    };
  },
  methods: {
    async getEmail() {
      const dbUser = await doc(db, "candidates", this.$route.query.uid);
      const userinfo = await getDoc(dbUser);
      return userinfo.data().email;
    },
    async getFlow() {
      const candidateRef = await doc(
        db,
        "candidates",
        this.$store.getters.getUID
      );
      const collectRef = collection(candidateRef, "onboarding");
      const savedFlow = await getDoc(collectRef, "currentflow");
      this.$store.dispatch("setAllFlow", savedFlow.data());
    },
    async checkLogin() {
      this.current = "created";
      if (isSignInWithEmailLink(auth, window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let newbrowser = false;
        let email = window.localStorage.getItem("emailForSignIn");
        this.current = "got email";
        //console.log(auth,email)
        if (!email) {
          email = this.getEmail();
          newbrowser = true;
        }
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            // Signed in
            const user = result.user;
            this.user = user;
            /*console.log(user.displayName)
          console.log(auth.currentUser)
          console.log(auth.currentUser.uid)*/
            this.current = user.displayName;
            this.$store.dispatch("changeAuthUser", user);
            this.$store.dispatch("changeUID", this.getQUID);
            this.getFlow();
            this.$store.dispatch("downloadWebsiteText");
            this.$store.dispatch("downloadWebsiteData");
            this.$store.dispatch("downloadCollections");
            this.$store.dispatch("listenDBLists");
            this.$store.dispatch("downloadOnboarding");
            this.$store.dispatch("localhostCheck");
            this.$store.dispatch("nameSet");
            this.$store.dispatch("createNarrativeFacts")

            if (newbrowser) {
              this.$router.push("/dashboard");
            }
            window.localStorage.removeItem("emailForSignIn");
            this.$router.push("/dashboard");
          })
          .catch((error) => {
            this.current = "no user / wrong email";
            const errorCode = error.code;
            const errorMessage = error.message;
          });
      }
    },
  },
  computed: {
    getQUID() {
      return this.$route.query.uid;
    },

    /*checkCurrentUser() {
      const user = auth.currentUser;
      console.log("is this the current user??", user)
      if (user) {
        return user.name;
      } else {
        // No user is signed in.
        return "No one is logged in.";
      }
    },*/
    //...mapGetters(["getSignInEmail", "getCurrentUser", "getUID"])
  },
  created() {
    this.checkLogin();
  },
};
</script>

<style></style>
