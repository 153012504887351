var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(true)?_c('b-container',[_vm._l((Object.entries(_vm.websiteData['questionnaires'])
        .map(function (el) { return el[1]; })
        .sort(function (a, b) { return a.sid - b.sid; })),function(item,i){return _c('div',{key:i},[_c('b-card',{staticClass:"text-left mb-4 mx-auto",attrs:{"bg-variant":"light","text-variant":"dark","header":item.section,"header-tag":"header","footer":item.questionnaire,"footer-tag":"footer"}},[_c('b-card-title',[_c('ClickToEdit',{attrs:{"value":item.prompt}},[_vm._v(_vm._s(item.prompt))])],1),(item.options)?_c('b-card-header',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-' + [item.section]),expression:"'collapse-' + [item.section]"}]},[_vm._v(" "+_vm._s(Object.entries(item.options).length)+" Options")]):(!item.options)?_c('b-card-header',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-' + [item.section]),expression:"'collapse-' + [item.section]"}]},[_vm._v(" Click to edit text")]):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-" + [item.section]}},[_c('b-card-text',{staticClass:"text-left"},[(item.options !== undefined)?_c('b-table',{attrs:{"selectable":"","select-mode":"single","fields":_vm.fields2,"items":item.options.map(function (el) { return ({
                  oid: el.oid,
                  title: el.title,
                  _showDetails: false,
                }); })},scopedSlots:_vm._u([{key:"cell(title)",fn:function(row){return [_c('div',{on:{"click":row.toggleDetails}},[_vm._v(_vm._s(row.item.title))])]}},{key:"row-details",fn:function(row){return [_c('b-table',{attrs:{"fields":_vm.fields3,"items":Object.entries(item.options[row.item.oid])},scopedSlots:_vm._u([{key:"cell(1)",fn:function(value){return [_c('ClickToEdit',{attrs:{"value":value.item[1]}},[_vm._v(_vm._s(value.item[1]))])]}}],null,true)}),_c('hr'),_c('h5',{staticClass:"text-left"},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" Add another trait ")],1),_c('hr')]}}],null,true)}):_vm._e(),_c('hr'),_c('h5',{staticClass:"text-left"},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" Add another option ")],1),_c('hr')],1),_c('b-card-footer',{staticClass:"text-left"},[_vm._v("Explanatory Paragraph:"),_c('hr'),_c('ClickToEdit',{attrs:{"value":item.explanatory}},[_vm._v(_vm._s(item.explanatory))])],1)],1)],1)],1)}),_c('div',[_c('h3',{staticClass:"mt-5 new-question text-right"},[_c('hr'),_vm._v(" Add New Question"),_c('b-icon',{attrs:{"icon":"plus"}})],1)])],2):_vm._e(),(false)?_c('b-container',[_vm._l((Object.entries(_vm.websiteData['terms'])
        .map(function (el) { return el[1]; })
        .sort(function (a, b) { return a.tid - b.tid; })),function(item,j){return _c('b-card',{key:j,staticClass:"text-left mb-4 mx-auto",attrs:{"bg-variant":"light","text-variant":"dark","title":item.title,"header":item.type,"header-tag":"header","footer":item.associated_term,"footer-tag":"footer"}},_vm._l((Object.keys(item)),function(sect,s){return _c('b-card-text',{key:s},[_vm._v(" "+_vm._s(sect)+" : "+_vm._s(item[sect])+" ")])}),1)}),_c('hr')],2):_vm._e(),(false)?_c('b-container',[_vm._l((Object.entries(_vm.websiteData['narratives']).map(
        function (el) { return el[1]; }
      )),function(item,j){return _c('b-card',{key:j,staticClass:"text-left mb-4 mx-auto",attrs:{"bg-variant":"light","text-variant":"dark","title":item.title,"header":item.type,"header-tag":"header","footer-tag":"footer"}},_vm._l((Object.keys(item)),function(sect,s){return _c('b-card-text',{key:s},[_vm._v(" "+_vm._s(sect)+" : "+_vm._s(item[sect])+" ")])}),1)}),_c('hr')],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }