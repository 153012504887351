/* eslint-disable */
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
  getDoc,
  setDoc,
  FieldValue,
  getDocs,
  query,
  where,
  arrayUnion, onSnapshot
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  isSignInWithEmailLink
} from "firebase/auth";

import { getFunctions, httpsCallable } from "firebase/functions";
//import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';

const firebaseConfig = {
  apiKey: "AIzaSyDhys7zNBt9vlHfCpKyUez5e9jMJ9pxDEM",
  authDomain: "before-site.firebaseapp.com",
  projectId: "before-site",
  storageBucket: "before-site.appspot.com",
  messagingSenderId: "422607161543",
  appId: "1:422607161543:web:a1ae80e5b4408240d296e2",
  measurementId: "G-098PMQW0HT",
};

// Initialize Cloud Firestore through Firebase

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp);

export {
  firebaseApp,
  db,
  auth,
  functions,
  httpsCallable,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  collection,
  addDoc,
  serverTimestamp,
  signOut,
  doc,
  getDoc,
  setDoc,
  getDocs,
  updateDoc,
  FieldValue,
  onSnapshot,
  query,
  where,
  arrayUnion
};
