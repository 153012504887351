<template>
  <div>
      <b-container>
    <h2 class="text-center mb-4">Become a Partner!</h2>
<b-row>
        <b-col md="4" class="mb-4"
          >
            <h5 class="text-left">
              Do you have a community on Slack, Discord, an email list, or
              really anything else?
            </h5>
            <h5 class="text-left mt-4">
              Do you want to to help your community find jobs that they love?
            </h5>
            <h5 class="text-left mt-4 mb-4">
              Do you want to monetize your community without having to charge
              your members?
            </h5>
</b-col
        ><b-col md="8">
            <b-card>
              <b-form @submit="onSubmit">
                  <b-form-group
                    id="name-group"
                    :label="prompt.name"
                    label-align="left"
                    label-for="name-input"
                    class="mt-4"
                    >
                      <b-form-input
  
                        id="name-input"
                        v-model="form.name"
                        :label="prompt.name"
                        required
                      ></b-form-input>
                  </b-form-group> 
                  <b-form-group
                    id="email-input-group"
                    label-for="email-input"
                    
                    label-align="left"
                    :label="prompt.email"
                    class="mt-4"
                  >
                    <b-form-input

                      id="email-input"
                      v-model="form.email"
                      type="email"
                      :label="prompt.email"
                      required
                    ></b-form-input>
                  </b-form-group> 
                  <b-form-group
                    id="linkedin-input-group"
                    class="mt-4"
                    
                    label-align="left"
                    label-for="linkedin-input"
                    :label="prompt.linkedin"
                  >
                    <b-form-input

                      id="linkedin-input"
                      v-model="form.linkedin"
                      :label="prompt.linkedin"
                      type="url"
                      required
                    ></b-form-input>
                  </b-form-group> 
                  <b-form-group
                    id="community_name-group"
                    label-for="community_name-input"
                    
                    label-align="left"
                    class="mt-4"
                    :label="prompt.community_name"
                  >
                    <b-form-input

                      id="community_name"
                      v-model="form.community_name"
                      type="text"
                      :label="prompt.community_name"
                      required
                    ></b-form-input>
                  </b-form-group> 
                  <b-form-group
                    id="community_url-input-group"
                    class="mt-4"
                    label-for="community_url"
                    
                    label-align="left"
                    :label="prompt.community_url"
                  >
                    <b-form-input

                      id="community_url"
                      v-model="form.community_url"
                      type="url"
                      :label="prompt.community_url"
                      required
                    ></b-form-input>
                  </b-form-group> 
                  <b-form-group
                    id="community_size-input-group"
                    class="mt-4"
                    label-for="community_size"
                    
                    label-align="left"
                    :label="prompt.community_size"
                  >
                    <b-form-select
                      v-model="form.community_size"
                      :label="prompt.community_size"
                      :options="community_size_options"
                    ></b-form-select>
                  </b-form-group> 
                  <b-form-group
                    id="community_job_board-group"
                    class="mt-4"
                    label-for="community_job_board"
                    
                    label-align="left"
                    :label="prompt.community_job_board"
                  >
                    <b-form-select
                      v-model="form.community_job_board"
                      :label="prompt.community_job_board"
                      :options="job_board_options"
                    ></b-form-select>
                  </b-form-group> 
                  <b-form-group
                    id="community_description-group"
                    label-for="community_description-input"
                    
                    label-align="left"
                    :label="prompt.community_description"
                    class="mt-4"
                  >
                    <b-form-textarea
                      id="community_description"
                      v-model="form.community_description"
                      :label="prompt.community_description"
                      rows="6"
                      required
                    ></b-form-textarea>
                  </b-form-group>
                
                <b-row class="mt-3 mb-3">
                  <b-button type="submit" variant="outline-purple"
                    >Submit</b-button>
                </b-row>
              </b-form></b-card
            >

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  doc,
  addDoc,
  collection,
  serverTimestamp,
  auth,
  createUserWithEmailAndPassword,
  getDoc,
  setDoc,
  db,
} from "@/firebase";
import { candidatesCollection, usersCollection } from "@/db";
import { mapActions, mapGetters } from "vuex";
import { router } from "@/router";
export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        linkedin: "",
        invitecode: "",
        community_url: "",
        community_name: "",
        community_size: "",
        community_job_board: "",
        community_description: "",
        password: serverTimestamp(),
      },
      prompt: {
        email: "Your Email Address",
        name: "Your Name",
        linkedin: "Your LinkedIn Profile",
        community_name: "Community Name",
        community_url: "What's the URL of the community?",
        community_job_board: "Does your community have a job board?",
        community_size: "How many members does your community have?",
        community_description:
          "Explain your community in a few lines. What is its mission, who are the members, how long you've been around. Anything really that you think we should know.",
      },
      current: 1,
      currentCandidateQ: 1,
      show: true,
      error: null,
      community_size_options: [
        { value: "1-49", text: "1-49 members" },
        { value: "50-249", text: "50-249 members" },
        { value: "250-499", text: "250-499 members" },
        { value: "501-999", text: "501-999 members" },
        { value: "1000-2499", text: "1000-2499 members" },
        { value: "2500-9999", text: "2500-9999 members" },
        { value: "10000+", text: "10,000+ members" },
      ],
      job_board_options: [
        { value: "yes", text: "Yes" },
        { value: "no", text: "No" },
        { value: "unofficially", text: "Unofficially" },
      ],
    };
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();

      const docRef = await addDoc(candidatesCollection, {
        email: this.form.email,
        name: this.form.name,
        linkedin: this.form.linkedin,
        invitecode: this.form.invitecode,
        type: "candidate",
        created: serverTimestamp(),
      });
      this.$store.dispatch("changeName", this.form.name);
      this.$store.dispatch("changeSignupEmail", this.form.email);
      this.$store.dispatch("changeUID", docRef.id);
      this.$router.replace({ name: "ConfirmSignup" });
    },
  },
};
</script>

<style scoped>
.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}

.btn-outline-purple:hover,
.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}
</style>
