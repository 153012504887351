const namespaced = true;

const state = {
  id: null,
  name: null,
  email: null,
};

const mutations = {
  SET_USER_DATA: (state, payload) => {
    state.id = payload.id;
    state.name = payload.name;
    state.email = payload.email;
  },
  CLEAR_USER_DATA: (state) => {
    state.id = null;
    state.name = null;
    state.email = null;
  },
};

const actions = {
  setUserData(context, userData) {
    context.commit("SET_USER_DATA", userData);
  },
  clearUserData(context, userData) {
    context.commit("CLEAR_USER_DATA", userData);
  },
};

const getters = {
    loggedIn(state) {
      if (state.id)
        return true;
    },
    getUser(state) {
        return state.name;
      },
      getUserID(state) {
        return state.id;
      },
    
    getEmail(state) {
        return state.email;
      },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
}