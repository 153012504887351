var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tags-box"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-col',{staticClass:"col-sm-12"},[_c('b-form-tags',{staticClass:"mb-2 tag-list",attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-card-group',{staticClass:"mx-auto"},_vm._l((tags),function(tag){return _c('div',{key:tag},[_c('b-card',{staticClass:"mt-4 binary",attrs:{"id":(_vm.taxonomy + "_" + (tag.replace(/\s/g, '_')) + "_"),"body-class":""}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-left pl-2"},[_vm._v(_vm._s(tag))])]),_c('b-col',{staticClass:"binary",attrs:{"cols":"4"}},[_c('b-button-group',{attrs:{"vertical":""}},[_c('b-button',{staticClass:"white-right-border",attrs:{"squared":"","align-v":"center","variant":"purple"},on:{"click":function($event){_vm.pushLikes({
                            Tag: tag,
                            Taxonomy: _vm.taxonomy,
                            Remove: "x-circle-fill",
                          }),
                            removeTag(tag)}}},[_c('b-icon',{staticClass:"text-right mt-2 mb-2 mx-auto",attrs:{"icon":"hand-thumbs-up","variant":"outline-purple"}})],1),_c('b-button',{staticClass:"binary",attrs:{"align-v":"center","squared":"","variant":"purple"},on:{"click":function($event){_vm.pushDislikes({
                            Tag: tag,
                            Taxonomy: _vm.taxonomy,
                            Remove: "x-circle-fill",
                          }),
                            removeTag(tag)}}},[_c('b-icon',{staticClass:"text-right mt-2 mb-2 mx-auto",attrs:{"icon":"hand-thumbs-down","variant":"outline-purple"}})],1)],1)],1)],1)],1)],1)}),0)]}}]),model:{value:(_vm.taglist),callback:function ($$v) {_vm.taglist=$$v},expression:"taglist"}})],1)],1),_c('b-container',[_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"col-sm-6"},[_c('div',[_c('h4',{staticClass:"mt-3 text-left"},[_vm._v(_vm._s(_vm.LikeFields[0])+":")]),_c('b-table',{staticClass:"table-purple text-left",attrs:{"items":_vm.likes,"fields":_vm.fields,"show-empty":"","thead-class":"no-th"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.likesText)),_c('br')]},proxy:true},{key:"cell(Remove)",fn:function(data){return [_c('b-icon',{staticClass:"text-right mt-2 mb-2 mx-auto",attrs:{"icon":data.value},on:{"click":function($event){return _vm.removeRow('likes', data.index, data.item.Tag)}}})]}}])},[_vm._v(" // eslint-disable-next-line no-unused-vars "),_vm._v(" // eslint-disable-next-line no-unused-vars ")])],1)]),_c('b-col',{staticClass:"col-sm-6"},[_c('div',[_c('h4',{staticClass:"mt-3 text-left"},[_vm._v(_vm._s(_vm.DislikeFields[0])+":")]),_c('b-table',{staticClass:"table-purple text-left",attrs:{"items":_vm.dislikes,"fields":_vm.fields,"show-empty":"","thead-class":"no-th"},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v(" "+_vm._s(_vm.dislikesText))]},proxy:true},{key:"cell(Remove)",fn:function(data){return [_c('b-icon',{staticClass:"text-right mt-2 mb-2 mx-auto",attrs:{"icon":data.value},on:{"click":function($event){return _vm.removeRow('dislikes', data.index, data.item.Tag)}}})]}}])},[_vm._v(" // eslint-disable-next-line no-unused-vars "),_vm._v(" // eslint-disable-next-line no-unused-vars ")])],1)])],1)],1),_c('b-button',{staticClass:"mb-4 mt-4 save-button",attrs:{"size":"lg","variant":"outline-purple"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("Save Responses and Return to Dashboard")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }