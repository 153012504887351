import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import TempHome from '@/views/TempLanding.vue'
import Login from '@/views/Login/LoginEmail.vue'
import PasswordLogin from '@/views/Old/Login.vue'
import CompleteLogin from '@/views/Login/CompleteLogin.vue'
import Signup from '@/views/Signup/Start.vue'
import Dashboard from '@/views/Dashboard.vue'
import Test from '@/views/Test.vue'
import SpecificSkills from '@/views/Onboarding/SpecificSkills.vue'
import GeneralSkills from '@/views/Onboarding/GeneralSkills.vue'
import Questionnaire from '@/views/Onboarding/Questionnaire.vue'
import QuestionnaireTest from '@/views/QuestionnaireTest.vue'
import Correct from '@/components/functions/IndividualFact.vue'
import FactBox from '@/components/interfaces/FactBox4.vue'
import Start from '@/views/Signup/Start.vue'
import CompanyChoose from '@/views/Onboarding/CompanyChoose.vue'
import SignInNewUser from '@/views/Signup/SignInNewUser.vue'
import ConfirmInviteList from '@/views/Signup/ConfirmInviteList.vue'
import Interstitial from '@/views/Onboarding/Interstitial.vue'
import MarketingQuestions from '@/views/Marketing/Questions.vue'
import ManageData from '@/views/Admin/ManageData1.vue'
import ManageTexts from '@/views/Admin/ManageText.vue'
import PartnerSignup from '@/views/Partners/PartnerSignup.vue'
import PartnerDashboard from '@/views/Partners/PartnerDashboard.vue'
import CompanySignup from '@/views/Companies/CompanySignup.vue'
import CompanyDashboard from '@/views/Companies/CompanyDashboard.vue'
import CompanyPositions from '@/views/Companies/Positions.vue'
import CreatePosition from '@/views/Companies/CreatePosition.vue'
import Dictionary from '@/views/Marketing/Dictionary.vue'
import Team from '@/views/Marketing/Team.vue'
import { store } from '@/store/index.js'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'TempHome',
    component: TempHome,
    meta: {
      requiresAuth: false,
      hideNavbar: true,
    },
  },
  {
    path: '/home-1234',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
    },
  },  {
    path: '/passwordlogin',
    name: 'PasswordLogin',
    component: PasswordLogin,
    meta: {
      requiresAuth: false,
    },
  }, {
    path: '/loggedIn',
    name: 'CompleteLogin',
    component: CompleteLogin,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/partners/signup',
    name: 'PartnerSignup',
    component: PartnerSignup,
    meta: {
      requiresAuth: false,
    },
  },  {
    path: '/partners/dashboard',
    name: 'PartnerDashboard',
    component: PartnerDashboard,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/companies/signup',
    name: 'CompanySignup',
    component: CompanySignup,
    meta: {
      requiresAuth: false,
    },
  },  {
    path: '/companies/positions',
    name: 'CompanyPositions',
    component: CompanyPositions,
    meta: {
      requiresAuth: false,
    },
  },{
    path: '/companies/create-position',
    name: 'CreatePosition',
    component: CreatePosition,
    meta: {
      requiresAuth: false,
    },
  },{
    path: '/companies/dashboard',
    name: 'CompanyDashboard',
    component: CompanyDashboard,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/specific-skills',
    name: 'SpecificSkills',
    component: SpecificSkills,
    meta: {requiresAuth: true},
  },
  {
    path: '/general-skills',
    name: 'GeneralSkills',
    component: GeneralSkills,
    meta: {requiresAuth: true},
  },
  {
    path: '/test',
    name: 'Test',
    component: Test,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/factbox',
    name: 'Factbox',
    component: FactBox,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/managedata',
    name: 'ManageData',
    component: ManageData,
    meta: {requiresAuth: true},
  },
  {
    path: '/managetexts',
    name: 'ManageTexts',
    component: ManageTexts,
    meta: {requiresAuth: true},
  },
  {
    path: '/questionnaire/:questionnaireID',
    name: 'Questionniare',
    component: Questionnaire,
    props: true,
    meta: {requiresAuth: true},
  },
  {
    path: '/questionnaireTest/:questionnaireID',
    name: 'QuestionniareTest',
    component: QuestionnaireTest,
    props: true
  },
  {
    path: '/choose',
    name: 'Choose',
    component: CompanyChoose,
    meta: {requiresAuth: true},
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      requiresAuth: false,
    },
  },  
  {
    path: '/confirm',
    name: 'ConfirmSignup',
    component: SignInNewUser,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/invite-list',
    name: 'ConfirmInviteList',
    component: ConfirmInviteList,
    meta: {
      requiresAuth: false,      
      hideNavbar: true,
    },
  },
  {
    path: '/survey',
    name: 'Survey',
    component: MarketingQuestions,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/interstitial',
    name: 'Interstitial',
    component: Interstitial,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/correct',
    name: 'Correct',
    component: Correct,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/start',
    name: 'Start',
    component: Start,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/dictionary',
    name: 'Dictionary',
    component: Dictionary,
    meta: {
      requiresAuth: false,
    },
  }, {
    path: '/team',
    name: 'team',
    component: Team,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {requiresAuth: true},
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Unused/About.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/landing',
    name: 'Landing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "landing" */ '../components/pages/Landing.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/experiences',
    name: 'Experiences',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "experiences" */ '../views/Onboarding/Experiences.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/industries',
    name: 'Industries',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "industries" */ '../views/Onboarding/Industries.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/waitscreen',
    name: 'WaitScreen',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "waitscreen" */ '../views/Onboarding/WaitScreen.vue'),
    meta: {
      requiresAuth: false,
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const waitForStorageToBeReady = async (to, from, next) => {
  await store
  next()
}
router.beforeEach(waitForStorageToBeReady)

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
  if (!store.getters.isAuthenticated ) {next({ name: 'Home' })}
  else next()
} else next()
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
      document.title = to.pageTitle || 'Before';
  });
});

export default router
