<template>
  <div>
    <b-container>
      <b-card>
        <b-card-title
          ><span class="d-flex justify-content-start font-weight-bold"
            >What we know:</span
          >
          <span class="d-flex justify-content-end"
            ><h6>
              <b-badge
                @click="changeCat('facts')"
                variant="outline-purple"
                :class="{ active: currentCat === 'facts' }"
                class="mr-2"
                >Facts</b-badge
              >
              <b-badge
                @click="changeCat('preferences')"
                variant="outline-purple"
                :class="{ active: currentCat === 'preferences' }"
                class="mr-2"
                >Preferences</b-badge
              ><b-badge
                @click="changeCat('insights')"
                variant="outline-purple"
                :class="{ active: currentCat === 'insights' }"
                class="mr-2"
                >Insights</b-badge
              >
            </h6></span
          ></b-card-title
        >
        <hr />
        <b-list-group flush class="accordion" role="tablist">
          <b-list-group-item v-for="(data, i) in narratives" :key="i">
            <span
              class="d-flex justify-content-start text-left font-weight-bold"
              ><h4>
                <b-icon
                  v-b-toggle="'collapse-' + [data.name]"
                  class="mr-3"
                  icon="plus"
                ></b-icon
                >
            {{checkTopTerms(data["name"], data["text"])}}
            {{scanFactBox(basicData[data["name"]],data["pretext"] )}}
            {{getMoreData(data["name"], data["words"], data["pretext"])}} 
                 
              </h4></span
            >
            <b-collapse
              :id="`collapse-` + [data.name]"
              accordion="my-accordion-category"
              role="tabpanel"
            >
              <div v-if="data['type'] === 'length' ">
                  <div v-for="(datum, d) in getData(data['name'], data['type'])" :key="d" class="text-left">
                    <b-icon
                  class="mr-3"
                  icon="x"
                ></b-icon
                > {{datum}}
                  </div>
                  <span v-if="getData(data['name'], data['type']).length === 0 && data['name'] === 'languages_list'">
                      <h4 class="text-left">You must be an American.</h4></span> 
                  </div>
                  <div v-if="data['type'] === 'text'" class="text-left">
                    <b-icon
                  class="mr-3"
                  icon="x"
                ></b-icon
                > {{scanFactBox(basicData[data["name"]])}}
                  </div>
            </b-collapse></b-list-group-item
          >
        </b-list-group>
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentCat: "",
      factBoxData: {},
    };
  },
  methods: {
      checkTopTerms(dataname, text){
       if(this.topTerms.find(el=> el === dataname)){
           return text
       }
      },
    getData(data, type) {
      try {
       if (typeof data === "string") {
          return data;
        } else if (typeof data.data === "string") {
          return data.data;
        } else if (typeof data.data === "object") {
          try {
            return data.data[0].title;
          } catch {
            console.log(data.data);
          }
        }
      } catch {
        console.log(data);
      }
    },
    
    getMoreData(data, words, pretext) {
      try {
        /*if (words === "") {
          return ".";
        } else
        */
        if (this.professional[data]) {
          let length = this.professional[data].length;
          let posttext = "";
          if (length > 1) {
            posttext = words.multiple;
          } else if (length === 1) {
            posttext === words.single;
          }
          return pretext + " " + length + " " + posttext;
        } else if (typeof words === "object") return pretext + " 0 " + words.multiple;
        else if (typeof words === "string") return " " + words;
      } catch {
        console.log(data);
      }
    },
    scanFactBox(data, pretext) {
      try {
        if (typeof data === "string") {
          if(pretext === undefined) {
              return data
          }
          else return pretext + " " + data;
          
        } else if (typeof data === "number") {
            if(pretext === undefined) {
              return data
          }
          else 
          return pretext + " " + data;
        }else if (typeof data.data === "string") {
          return pretext + " " + data.data;
        } else if (typeof data.data === "object") {
          try {
            return pretext + " " + data.data[0].title.toLowerCase() + ".";
          } catch {
            console.log(data.data);
          }
        }
      } catch {
        console.log();
      }
    },
  },created(){
//this.checkNarratives()
  },
  computed: {
    professional() {
      return this.$store.getters.getProfessionalData;
    },
    insights() {
      return this.$store.getters.getInsightsTexts;
    },
    facts() {
      return this.$store.getters.getFactBoxDataTexts;
    },
    narratives() {
      return this.$store.getters.getNarratives;
    },
    basicData() {
      return this.$store.getters.getAllBasicData;
    },
    choices() {
      return this.$store.getters.getAllChoices;
    },
    factBox() {
      return this.$store.getters.getFactBoxData;
    },
    topTerms(){
    return this.$store.getters.getTopTerms.map(el => el.name);
    },
    alldata() {
      let insights_list = this.$store.getters.getInsightsTexts;
      let facts_list = this.$store.getters.getFactBoxDataTexts;
      let all_data_list = [...facts_list, ...insights_list];
      return all_data_list;
    },
  },watch: {
   factBoxData() {
      this.$store.dispatch("syncFacts", this.factBoxData);
    },
    //filter() {
    // this.reset();
    // },
  },
};
</script>

<style></style>
