<template>
  <div class="pt-3 page start">
    <b-container>
      <h3><b>Who are you?</b></h3>
      <h5>(Not a rhetorical question.)</h5>

      <br />
      <hr />
      <b-container class="candidate-form" fluid>
     
        <b-row class="mt-2">
          <b-col class="mt-3" sm="12" md="6">   <b-form @submit="onSubmit" v-if="show">
          <b-form-group id="name-group" label-for="name-input">
            <b-row>
              <b-form-input
                id="name-input"
                v-model="form.name"
                placeholder="Your Full Name"
                required
              ></b-form-input>
            </b-row>
          </b-form-group>

          <b-form-group
            id="email-input-group"
            label-for="email-input"
            class="mt-2"
          >
            <b-row>
              <b-form-input
                id="email-input"
                v-model="form.email"
                type="email"
                placeholder="Your Email Address"
                required
              ></b-form-input>
            </b-row>
          </b-form-group>

          <b-form-group
            id="linkedin-input-group"
            class="mt-2"
            label-for="linkedin-input"
          >
            <b-row>
              <b-form-input
                id="email-input"
                v-model="form.linkedin"
                type="url"
                placeholder="Your LinkedIn URL"
                required
              ></b-form-input>
            </b-row>
          </b-form-group>

                              <b-form-group
            id="invite-code-group"
            label-for="invite-code-input"
            class="mt-2"
          >
            <b-row>
              <b-form-input
                id="invite-code"
                v-model="form.invitecode"
                type="text"
                placeholder="Your Invite Code"
                required
              ></b-form-input>
            </b-row>
          </b-form-group>
          <b-row class="mt-3 mb-3">
            <b-button type="submit" v-if="checkInviteCode" variant="outline-purple">Submit</b-button>
            <b-button @click="submitInvite()" v-if="!checkInviteCode" variant="outline-purple">Request Invite</b-button>
          </b-row>
        </b-form></b-col>
          <b-col class="mt-3" sm="12" md="6">
          <h5 class="text-left mx-auto">
            <b>Before</b> won’t ask for your resume or a ton of other data. We
            want to learn who <em>you</em> are, what <em>you</em> like, and
            sometimes more importantly what you don’t like.
          </h5>

          <h5 class="text-left mx-auto">
            Resumes can make us try to be someone we are not, to fit into
            someone else’s box. We want to get to know the real you.
            <em>Really.</em>
          </h5>

          <h5 class="text-left mx-auto">
            <b>Before</b> will never share your private data with anyone without
            your permission. We use your LinkedIn data to create a custom
            onboarding experience for each person.
          </h5>
          </b-col>
          
        </b-row>
      </b-container>
    </b-container>
    <div class="home-footer">
   <b-row><b-col sm="12" md="5" offset-md="1"><p class="text-left">Because we were a site created for human beings, we did our research about how lazy human beings can be, compared to computers. (Mirrors may have been involved.)</p><p class="text-left">Apparently, human beings don't like filling out forms a million times. And they really don't like doing things that they don't need to do.</p></b-col>
   <b-col sm="12" md="5"><p class="text-left">So we import as much data as we can about you, and then we let you edit it down, highlight the things you like, and... ignore the things you prefer not to relive.</p><p class="text-left">We don't show your data to anyone else, and we will show you what we share about you.</p> </b-col></b-row><b-row><b-col sm="12" md="6" offset-md="3">
   <hr class="white-hr">
   <p>No llamas were hired in the creation of this website.</p>
   <p>All rights reserved.</p><p><b>© Before 2022</b></p></b-col></b-row> </div>
  </div>
</template>

<script>
import {
  doc,
  addDoc,
  collection,
  serverTimestamp,
  auth,
  createUserWithEmailAndPassword,
  getDoc,
  setDoc,
  db,
} from "@/firebase";
import { candidatesCollection, usersCollection } from "@/db";
import { mapActions, mapGetters } from "vuex";
import { router } from "@/router";
export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        linkedin: "",
        invitecode:"",
        password: serverTimestamp(),
      },
      current: 1,
      currentCandidateQ: 1,
      show: true,
      error: null,
    };
  },
 asyncComputed: {
      async checkInviteCode() {
        const invitecodesColl = collection(db,"invitecodes")
        const docRef = doc(invitecodesColl, this.form.invitecode);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() || window.location.hostname === "localhost") {
        return true
        } else {
        // doc.data() will be undefined in this case
        return false
        }
      }},
  computed: {},
  methods: {
    async onSubmit(event) {
      event.preventDefault();

      const docRef = await addDoc(candidatesCollection, {
        email: this.form.email,
        name: this.form.name,
        linkedin: this.form.linkedin,
        invitecode: this.form.invitecode,
        type: "candidate",
        created: serverTimestamp(),
      });
      this.$store.dispatch("changeName", this.form.name);
      this.$store.dispatch("changeSignupEmail", this.form.email);
      this.$store.dispatch("changeUID", docRef.id);
      this.$router.replace({ name: "ConfirmSignup" });
    },
    async submitInvite() {
      const docRef = await addDoc(db, "requests", {
        email: this.form.email,
        name: this.form.name,
        linkedin: this.form.linkedin,
        type: "request",
        created: serverTimestamp(),
      });
      this.$store.dispatch("changeName", this.form.name);
      this.$router.replace({ name: "ConfirmInviteList" });
    },
  },
};
</script>

<style scoped>
.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}

.btn-outline-purple:hover,
.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}
.directions {
  width: 80%;
}

</style>
