<template>
  <div>
      <b-row>
  <b-col cols="2" class="text-left"><b>{{item}}:</b></b-col>
                         <b-col class="text-left" cols="8">
<quill-editor
      class="text-left"
      v-if="edit && length > 20"
      :value="currentEdit"
      @blur="saveQuill($event)"
      @keyup.enter="saveQuill($event)"
      @keyup.esc="escQuill($event)"
      v-focus=""
      rows="12"
    ></quill-editor>
        <b-form-input
      class="text-left"
      v-else-if="edit && length < 20"
      :value="currentEdit"
      @blur="save($event)"
      @keyup.enter="save($event)"
      @keyup.esc="esc($event)"
      v-focus=""
    ></b-form-input>
        <b-form-input
      class="text-left"
      v-else-if="edit && typeof value === 'number'"
      :value="currentEdit"
      @blur="save($event)"
      @keyup.enter="save($event)"
      @keyup.esc="esc($event)"
      type="number"
      cols="2"
      v-focus=""
    ></b-form-input>
    <span v-else @click="edit = true" v-html="currentEdit">
    </span>
    </b-col>
                         </b-row>
                            
  </div>
</template>

<script>
export default {
  props: ["value", "source", "cat", "sect", "item", "index"],

  data() {
    return {
      edit: false,
      oldValue: this.value,
      currentEdit: "",
      currentUser: this.$store.getters.getCurrentAuthUserName,
      length: this.value.length
    };
  },
  methods: {
    save(event) {
    //console.log(this.source[this.cat][this.sect][this.item])
    //console.log(event.root.innerHTML)
      //console.log(JSON.stringify(event.editor.delta.ops))
      this.currentEdit = event.target.value;
      //this.source[this.cat][this.sect][this.item] = this.currentEdit;
      this.$store.dispatch("addEdit", {cat: [this.cat], sect: [this.sect], item: [this.item], old: this.value, new: this.currentEdit, by: this.currentUser})
      this.$store.dispatch('updateWebsiteText', {cat: this.cat, sect: this.sect, item: this.item, text: event.target.value})
      //console.log(event.target.value)
      this.edit = false;
    },
    saveQuill(event) {
         this.currentEdit = event.root.innerHTML;
      //this.source[this.cat][this.sect][this.item] = this.currentEdit;
      this.$store.dispatch("addEdit", {cat: [this.cat], sect: [this.sect], item: [this.item], old: this.value, new: this.currentEdit, by: this.currentUser})
      this.$store.dispatch('updateWebsiteText', {cat: this.cat, sect: this.sect, item: this.item, text: event.root.innerHTML})
      this.edit = false;
      },
    esc(event) {
      this.currentEdit = this.value;
      this.edit = false;
    },  escQuill(event) {
      this.edit = false;
    },
  },
  created() {
    this.currentEdit = this.value;

    //console.log(this.value, this.length, this.source, this.cat, this.sect, this.item, this.index)
  },
  watch:{
      currentEdit(){
         // console.log(this.currentEdit)
      }
  },

  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
};
</script>
