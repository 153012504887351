<template>
  <div class="company-choose">
    <b-container>
      <OnboardingHeader
        :title="title"
      ></OnboardingHeader>
      <h4 v-if="choices.length < 5">Please rank at least 5 companies</h4>
      <h4 class="mb-3">Current Count: {{ choices.length }}</h4>
      <h6 class="mb-5">
        Please note: Before has absolutely no connection to any of these
        companies. This is just for us to better understand the sort of company you would be interested in.
      </h6>
      <b-card
        v-if="card"
        border-variant="purple"
        header-bg-variant="purple"
        header-text-variant="white"
        text-variant="purple"
        align="left"
        class="mb-4 mx-auto"
        footer-tag="footer"
        footer-bg-variant="purple"
        footer-border-variant="purple"
        footer-text-variant="white"
        :header="card.companyName"
        style="max-width: 30rem"
      >
        <div class="mt-2 mb-2">{{ card.companyDescription }}</div>
        <hr />
        <div v-if="card.companySizeCategory">
          Size: {{ card.companySizeCategory }}
          <span v-if="card.companySize"
            >– {{ card.companySize }}</span
          >
        </div>
        <hr />

        <div
          v-for="(industry, x) in card.companyIndustry"
          :key="x"
          class="mb-3"
        >
          Industry: {{ industry }}
        </div>
        <div v-if="!valence">
          <h4>Would you work for this company?</h4>
          <div v-for="button in valences" :key="button.cid">
            <b-button
              @click="makeChoice(button, card)"
              style="width: 100% !important"
              variant="outline-purple"
              class="mx-auto mb-1 mt-1"
              >{{ button.text }}</b-button
            >
          </div>
        </div>
        <div v-if="valence">
          <h4>Why did you choose that??</h4>
          <div v-for="button in buttonList" :key="button.rid">
            <b-button
              @click="giveReason(button, card)"
              style="width: 100% !important"
              variant="outline-purple"
              class="mx-auto mb-1 mt-1"
              >{{ button.response }}</b-button
            >
          </div>
        </div>
      </b-card>
          <b-button class="mt-5 save-button" @click="onSubmit" lg variant="outline-purple" 
      ><b>Save</b> and Return to Dashboard</b-button
    >
    </b-container>
  </div>
</template>

<script>
import {
  collection,
  getDoc,
  getDocs,
  doc,
  db,
  serverTimestamp,
  addDoc,
  setDoc,
  query,
  where,
} from "@/firebase";
import {
  usersCollection,
  companiesCollection,
  candidatesCollection,
} from "@/db";
import Companies from "@/assets/companies.json";
import _remove from "lodash/remove";
import OnboardingHeader from "@/components/sections/OnboardingHeader.vue";
export default {
  components: { OnboardingHeader},
  data() {
    return {
      companies: Companies,
      buttonList: [],
      random: 0,
      step: "6",
      title: "Rank Random Companies",
      time: "Less than 3 Minutes (or more, if you'd like)",
      valence: "",
      choices: [],
      card:"",
      done: [],
      industries: [],
      valences: [
        { cid: 0, text: "I would work there.", valence: "positive" },
        { cid: 1, text: "No opinion.", valence: "neutral" },
        { cid: 2, text: "I wouldn't work there.", valence: "negative" },
      ],
      responses: [
        { rid: 0, valence: "negative", response: "Too Big" },
        { rid: 1, valence: "negative", response: "Too Small" },
        { rid: 2, valence: "negative", response: "Wrong Industry" },
        { rid: 3, valence: "negative", response: "Wrong Ethics" },
        { rid: 4, valence: "negative", response: "Too Boring" },
        { rid: 5, valence: "negative", response: "Other Reason" },
        { rid: 6, valence: "positive", response: "Perfect Size" },
        { rid: 7, valence: "positive", response: "Interesting Field" },
        { rid: 8, valence: "positive", response: "Interesting Company" },
        { rid: 9, valence: "positive", response: "Known for their ethics" },
        { rid: 10, valence: "positive", response: "Dream job" },
        { rid: 11, valence: "positive", response: "Other Reason" },
      ],
    };
  },
  methods: {
    makeChoice(btn, cmpny) {
      this.valence = btn.valence;
      if (btn.valence == "neutral") {
        this.choices.push({ company: cmpny, valence: this.valence });
        this.valence = "";
        _remove(this.companies, { cid: cmpny.cid });
        this.random = this.randomCard();
      } else this.buttonList = this.responses.filter(el => el.valence === btn.valence)
    },
    giveReason(btn, cmpny) {
      this.choices.push({ company:cmpny, valence: btn.valence, btn });
      this.valence = "";
      _remove(this.companies, { cid: cmpny.cid });
      this.random = this.randomCard();
    },
    randomCard() {
      let rand = Math.ceil(Math.random() * 299);
      this.random = rand;
      this.checkCard();
    },
    //ADD FUNCTIONALITY THAT ALSO USES SpecificAnswers
    checkCard(){
      let tempCard = this.companies.find((el) => el.cid === this.random)
      if(tempCard){
        this.card = tempCard
      } else this.randomCard()
    }, 
    
    getCompleted() {
      if ('choices' in this.$store.getters.getCompanyChoices){
        this.choices = this.$store.getters.getCompanyChoices['choices'];
        this.companies = this.$store.getters.getCompanyChoices['companies']
      } else {
        this.choices = [];
        this.companies = Companies;
      }
    },
/*
    checkCard(){
      if(this.companies.find((el) => el.cid === this.random)){
        this.card = this.companies.find((el) => el.cid === this.random)
      } else this.randomCard()
    },*/

    async onSubmit() {
      let toUpdate = {companies: this.companies, choices: this.choices}
      this.$store.dispatch("updateCompanyChoices", toUpdate);
      /*const candidateRef = doc(
        candidatesCollection,
        this.$store.getters.getUID
      );
      const onboardingRef = collection(candidateRef, "onboarding");
      const CompanyChoicesRef = doc(onboardingRef, "company-choices");
      await setDoc(
        CompanyChoicesRef,
        {
          companies: this.companies,
          choices: this.choices,
          created: serverTimestamp(),
        },
        { merge: true }
      );
*/

      this.$router.push("/dashboard");
    },
  },
  created() {
    this.choices = [];
    this.getCompleted();
    this.random = this.randomCard();
  },
};
</script>

<style scoped>
.bg-purple {
  background-color: #713e9e;
  color: #fff;
}

.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}
.btn-outline-purple:hover {
  background-color: #9a5bd2;
  color: white;
}

.btn-purple:hover {
  color: #9a5bd2;
  background-color: #fff;
  border-color: #9a5bd2;
}
.btn-purple {
  background-color: #713e9e;
  color: white;
}
.btn-outline-purple.save-button {
  background-color: #fff;
  color: #9a5bd2;
  height: 5em;
  width: 100%;
  margin-bottom: 4em;
}
.btn-outline-purple.save-button:hover {
  background-color: #9a5bd2;
  color: white;
  height: 5em;
  width: 100%;
  margin-bottom: 4em;
}
.card-header.bg-purple.text-white {
  font-size: 2em;
}
</style>
