import { db } from "@/firebase"
import { collection, doc, setDoc, addDoc } from "firebase/firestore"; 

// collection references
const usersCollection = collection(db,"users");
const requestsCollection = collection(db,"requests");

//user types
const candidatesCollection = collection(db,"candidates");
const clientsCollection = collection(db,"clients");
const partnersCollection = collection(db,"partners");
const employeesCollection = collection(db,"employees");

// all text for website, etc logs for all actions
const websiteCollection = collection(db,"website");
const logsCollection = collection(db,"logs");

//from stripe
const paymentsCollection = collection(db,"payments");

//all engagements
const engagementsCollection = collection(db,"engagements");

//general data
const companiesCollection = collection(db,"companies");
const schoolsCollection = collection(db,"schools")

const skillsCollection = collection(db,"skills");
const rolesCollection = collection(db,"roles");

//
const matchesCollection = collection(db,"matches");
const opportunitiesCollection = collection(db,"opportunities");

//
const marketingCollection = collection(db,"marketing");

export {
    usersCollection,
    candidatesCollection,
    clientsCollection,
    partnersCollection,
    employeesCollection,
    websiteCollection,
    logsCollection,
    paymentsCollection,
    engagementsCollection,
    companiesCollection,
    schoolsCollection,
    skillsCollection,
    rolesCollection,
    matchesCollection,
    opportunitiesCollection,
    marketingCollection,
    requestsCollection
}