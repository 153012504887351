<template>
  <div>
    <b-container v-if="true">
      <div
        v-for="(item, i) in Object.entries(websiteData['questionnaires'])
          .map((el) => el[1])
          .sort((a, b) => a.sid - b.sid)"
        :key="i"
      >
        <b-card
          class="text-left mb-4 mx-auto"
          bg-variant="light"
          text-variant="dark"
          :header="item.section"
          header-tag="header"
          :footer="item.questionnaire"
          footer-tag="footer"
        >
        <b-card-title><ClickToEdit :value="item.prompt">{{item.prompt}}</ClickToEdit></b-card-title>
          <b-card-header v-if="item.options" v-b-toggle="'collapse-' + [item.section]"
            > {{Object.entries(item.options).length}} Options</b-card-header
          ><b-card-header v-else-if="!item.options" v-b-toggle="'collapse-' + [item.section]"
            > Click to edit text</b-card-header
          >
          <b-collapse :id="`collapse-` + [item.section]" class="mt-2">
            <b-card-text class="text-left"
              ><b-table
                v-if="item.options !== undefined"
                selectable
                select-mode="single"
                :fields="fields2"
                :items="
                  item.options.map((el) => ({
                    oid: el.oid,
                    title: el.title,
                    _showDetails: false,
                  }))
                "
              >
                <template #cell(title)="row">
                  <div @click="row.toggleDetails">{{ row.item.title }}</div>
                </template>
                <template #row-details="row">
                  <b-table
                    :fields="fields3"
                    :items="Object.entries(item.options[row.item.oid])"
                  ><template #cell(1)="value">
                  <ClickToEdit :value="value.item[1]">{{value.item[1]}}</ClickToEdit>
                </template>
                  </b-table>
                  <hr />
                  <h5 class="text-left">
                    <b-icon icon="plus"></b-icon> Add another trait
                  </h5>
                  <hr />
                </template>
              </b-table>
              <hr />
              <h5 class="text-left">
                <b-icon icon="plus"></b-icon> Add another option
              </h5>
              <hr />
            </b-card-text>
            <b-card-footer class="text-left"
              >Explanatory Paragraph:<hr><ClickToEdit :value="item.explanatory">{{item.explanatory}}</ClickToEdit></b-card-footer
            >
          </b-collapse
        ></b-card>
      </div>

      <div>
      <h3 class="mt-5 new-question text-right">
      <hr />
       Add New Question<b-icon icon="plus"></b-icon>
      </h3>
      </div>
    </b-container>

    <b-container v-if="false">
      <b-card
        v-for="(item, j) in Object.entries(websiteData['terms'])
          .map((el) => el[1])
          .sort((a, b) => a.tid - b.tid)"
        :key="j"
        class="text-left mb-4 mx-auto"
        bg-variant="light"
        text-variant="dark"
        :title="item.title"
        :header="item.type"
        header-tag="header"
        :footer="item.associated_term"
        footer-tag="footer"
      >
        <b-card-text v-for="(sect, s) in Object.keys(item)" :key="s">
          {{ sect }} : {{ item[sect] }}
        </b-card-text></b-card
      >

      <hr />
    </b-container>

    <b-container v-if="false">
      <b-card
        v-for="(item, j) in Object.entries(websiteData['narratives']).map(
          (el) => el[1]
        )"
        :key="j"
        class="text-left mb-4 mx-auto"
        bg-variant="light"
        text-variant="dark"
        :title="item.title"
        :header="item.type"
        header-tag="header"
        footer-tag="footer"
      >
        <b-card-text v-for="(sect, s) in Object.keys(item)" :key="s">
          {{ sect }} : {{ item[sect] }}
        </b-card-text></b-card
      >

      <hr />
    </b-container>
  </div>
</template>

<script>
import {
  collection,
  getDoc,
  getDocs,
  doc,
  db,
  serverTimestamp,
  addDoc,
  setDoc,
  query,
  where,
} from "@/firebase";
import ClickToEdit from "@/components/ClickToEdit.vue"
import { websiteCollection } from "@/db";
export default {
  components: {ClickToEdit},
  data() {
    return {
      selected: [],
      selectedOID: null,
      currentEdit: {},
      setCategory: "",
      setSection: "",
      fields3: [
        { key: "0", label: "Variable" },
        { key: "1", label: "Value" },
      ],
      fields2: [{ key: "title", label: "Click on options to edit" }],
      fields: [
        { key: "0", label: "Section" },
        { key: "1", label: "Data" },
        "Edit",
        "Remove",
      ],
      websiteData: {},
      prompts: [],
    };
  },
  methods: {
    editData(cat, sect, index, data) {
      //console.log(cat, sect, index, data)
    },
    sections(cat) {
      return Object.keys(this.websiteData[cat]);
    },
    onRowSelected(items) {
      this.selected = items;
    },
    onRowSelectedOID(items) {
      console.log(items);
      this.selectedOID = items[0].oid;
    },
    changeText(cat, sect, index, item, edit) {
      this.websiteData[cat][sect][item] = edit;
      console.log(this.websiteData[cat][sect]);
    },
    getData(cat, sect) {
      try {
        if (
          typeof Object.entries(this.websiteData[cat][sect])[0][1] ===
            "object" &&
          Object.entries(this.websiteData[cat][sect])[0][0].length < 2
        ) {
          return Object.entries(this.websiteData[cat][sect])
            .map((el) => el[1])
            .map((ex) => ({ 0: ex.section, 1: ex.prompt, 2: ex.sid }));
        } else return Object.entries(this.websiteData[cat][sect]);
      } catch {
        console.log("nope");
      }
    },
  },
  computed: {
    checkNotEmpty() {
      if (
        this.setCategory === "" ||
        this.setSection === "" ||
        this.selected.length === 0
      ) {
        return false;
      }
      return true;
    },
    checkOptionNotEmpty() {
      if (
        this.setCategory === "" ||
        this.setSection === "" ||
        this.selected.length === 0 ||
        this.selectedOID === null
      ) {
        return false;
      }
      return true;
    },
    categories() {
      return Object.keys(this.websiteData);
    },
    getOptions() {
      return Object.entries(this.websiteData[this.setCategory][this.setSection])
        .map((el) => el[1])
        .filter((el) => el.section === this.selected.map((el) => el[0])[0])
        .map((el) => el.options)
        .flat()
        .filter(Boolean);
    },
  },
  created() {
    this.websiteData = this.$store.getters.getWebsiteData;
  },
};
</script>

<style scoped>
button.btn.btn-secondary.rounded-0 {
  width: 20em !important;
  border-bottom: 3px solid black;
}

.card {
  width: 45% !important;
  min-height: 15rem;
  float: right;
  margin-right: 2em !important;
  overflow: scroll;
}
</style>
