<template>
  <div class="page">
    <b-container class="mb-5 bios">
      <h5>
        <b>Before</b>'s core team consists of two main individuals and a
        hands-on multi-disciplinary advisory board.
      </h5>
      <div class="mt-4 mb-6">
        <b-card
          no-body
          class="mt-6 mb-4"
          style="max-width: 100%"
          border-variant="purple"
        >
          <b-row no-gutters>
            <b-col md="6">
              <b-card-img
                :src="beaver"
                alt="Image"
                class="rounded-0"
              ></b-card-img>
            </b-col>
            <b-col md="6">
              <b-card-body class="text-left" title="Founder">
                <b-card-text>
                  <p>
                    The founder, is a serial entrepreneur who has sat on both
                    sides of the hiring desk, as well as in the middle. This
                    individual was responsible for helping thousands of people
                    find jobs during an economic downturn where people had
                    nowhere to turn.
                  </p>
                  <p>
                    This individual believes in the power of community, and the
                    part it plays in people actually finding employment. This
                    person also heard thousands of times over the years from
                    potential candidates that they were ignored from jobs they
                    were well-qualified for before they even had a chance to
                    talk with someone in the company.
                  </p>
                  <p>
                    Hypersensitive to the growing issue of both conscious and
                    subconscious bias pervading the earliest steps of the hiring
                    process, the founder decided to build <b>Before</b>, as a
                    step to ensure that potential employers meet the candidates
                    who can elevate their companies.
                  </p>
                  <p>
                    The founder's spirit animal is a beaver, because the beaver
                    is hard-working, and knows that all of their work does not
                    only benefit themselves, but the community as a whole, and
                    other communities downstream.
                  </p>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>

        <b-card
          no-body
          class="mt-6"
          style="max-width: 100%"
          border-variant="purple"
        >
          <b-row no-gutters>
            
            <b-col md="6">
              <b-card-body class="text-left" title="Product">
                <b-card-text>
                  <p>
                    The person in charge of product lives their life by two sayings.</p>
                  <p> One is an adage their mother told them often as they were growing up: <b>"If all you have is a hammer, everything looks like a nail."</b>, so their spirit animal is a toolbelt. The second is a quote by Andy Warhol, <b>"The world fascinates me."</b> </p>
                  <p>(There is a third to which they adhere religiously, which is a quote by Oscar Wilde, <b>“We should treat all the trivial things of life seriously, and all the serious things of life with sincere and studied triviality"</b>, but this is a serious bio, and three quotes is too much.)
                  </p>
                  <p>
                    This individual is a developer, writer, artist, researcher, producer, creative director, and avid practioner of Japanese art of <em>tsundoku</em>.
                  </p>
                  <p>
                    For them, <b>Before</b> is personal. They abhor the fact that people's lives can be hurt by an algorithm deciding that they didn't have the correct keywords in their resume. They are also unemployable, for some strange reason. (Something about potential employers thinking they lack the ability to focus or something.)
                  </p>
                </b-card-text>
              </b-card-body>
            </b-col>
            <b-col md="6">
              <b-card-img
                :src="toolbelt"
                alt="Image"
                class="rounded-0"
              ></b-card-img>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-container>
    <div class="home-footer mt-5">
   <b-row><b-col sm="12" md="5" offset-md="1"><p class="text-left">This is not a traditional team page, without the traditional namedropping of the teams' previous successes.</p><p class="text-left">On one hand, you know nothing about these people, but on the other hand, you kinda do. </p><p class="text-left">You may have a picture in your head of what they look like, but you kinda want to have a conversation with each of them to learn more.</p><p class="text-left">The second one sounds very fascinating, if you ask me.</p><p class="text-left">And that is precisely the point of <b>Before</b>. A candidate's bio, stripped of all personal identifiers, is shown to a potential employer. </p></b-col>
   <b-col sm="12" md="5"><p class="text-left"> The employer doesn't know if they attended Harvard or a local community college. They don't know if they worked at Google or at a local cafe.</p><p class="text-left">We want to help our candidates show off the parts of them they wish to show off, not be grilled on why they have a six month gap on the resume a decade ago.</p><p class="text-left">(We would never write the phrase "They are also unemployable.", but there didn't seem to be a better way to convey how many "you seem very smart and capable, but..." conversations they've endured.)</p><p class="text-left">Has there been a single study that has shown that a break from work has had detrimental results on their ability to function within a role? If there is we haven't seen it.</p></b-col></b-row><b-row><b-col sm="12" md="6" offset-md="3">
   <hr class="white-hr">
   <p>No llamas were hired in the creation of this website.</p>
   <p>All rights reserved.</p><p><b>© Before 2022</b></p></b-col></b-row> </div>
  </div>
</template>

<script>
import beaver from "@/assets/beaver-white.png";
import toolbelt from "@/assets/toolbelt-white.png";
export default {
  data() {
    return {
      beaver: beaver,
      toolbelt: toolbelt
    };
  },
};
</script>

<style scoped>
.page{
    overflow-y:auto;
    position: relative;
}
.bios{
    min-height: 100vh;
    margin-bottom: 40% !important;
}
.card {
  border: 2px solid #713e9e !important;
}
.card-purple {
  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}

.card-img, .card-img-top, .card-img-bottom {
    background-color: #713e9e;
    flex-shrink: 0;
    width: 100%;
    padding-bottom: 5em !important;
}
</style>
