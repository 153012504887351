<template>
  <div class="dashboard">
    <b-container fluid>
      <b-row
        ><b-col
          ><h2>
            <div class="mb-4" v-if="getCurrentAuthUserName">
              Hi {{ getFirstName }}!<br />
            </div>
          </h2>
        </b-col>
      </b-row>
<h4 v-if="selected.length > 0">
        {{ selected[0].formattedUrl }}
        <b-icon @click="removeItem" icon="x-circle"></b-icon>
      </h4>
<b-container v-if="false">
        <b-col cols=12><b-form-input v-model="searchName" type="text" debounce="0"></b-form-input><b-table
        id="flying"
        thead-class="no-th"
        class="text-left"
          hover
          selectable
          select-mode="single"
          @row-selected="onRowSelected"
          :fields="fields"
          :items="results"
        ></b-table>
        </b-col>
      </b-container>
      <div v-if="false">
      <hr />
      {{value}}
      <hr>
      <h4 v-if="selected.length > 0">
        {{ selected[0].title }}
        <b-icon @click="removeItem" icon="x-circle"></b-icon>
      </h4>
      <b-container v-if="false">
        <b-col cols=6><b-form-input v-model="value" type="text" debounce="0"></b-form-input><b-table
        id="flying"
        thead-class="no-th"
          hover
          selectable
          select-mode="single"
          @row-selected="onRowSelected"
          :fields="fields"
          :items="results"
        ></b-table>
        </b-col>
      </b-container>
      </div>
      <div>
        <b-button @click="onSubmit" v-if="true"
          >Click me, nothing bad will happen, believe me.</b-button
        >
  
        <hr />

        <hr />
<div v-for="question in allquestionnaires" :key="question.sid">
  {{question.sid}} || {{question.section}} || {{question.prompt}}
  </div>
        <hr />
       
        <hr />

        <hr />
      </div>
    </b-container>
    <b-container>
  <div>
    <b-table :items="items" :fields="fields" selectable
            select-mode="single" striped responsive="sm">
      <template #cell(show_details)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
        </b-button>

        <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
        <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails">
          Details via check
        </b-form-checkbox>
      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Age:</b></b-col>
            <b-col>{{ row.item.age }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>
            <b-col>{{ row.item.isActive }}</b-col>
          </b-row>

          <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
        </b-card>
      </template>
    </b-table>
  </div>
    </b-container>
  </div>
</template>

<script>
import {
  collection,
  getDoc,
  getDocs,
  doc,
  db,
  updateDoc,
  serverTimestamp,
  addDoc,
  setDoc,
  query,
  where,
} from "@/firebase";
import axios from "axios";
import { websiteCollection } from "@/db";
import { mapGetters, mapActions } from "vuex";
import terms from "@/assets/terms.json";
import instructions from "@/assets/instructions.json"
export default {
  components: {},
  data() {
    return {
      value: "",
      fields: ['first_name', 'last_name', 'show_details'],
        items: [
          { isActive: false, age: 40, first_name: 'Dickerson', last_name: 'Macdonald'},
          { isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw' },
          {
            isActive: false,
            age: 89,
            first_name: 'Geneva',
            last_name: 'Wilson'
          },
          { isActive: true, age: 38, first_name: 'Jami', last_name: 'Carney' }
        ],
      articleList: [],
      message: "",
      searchName:'',
      terms: terms,
      allquestionnaires:{},
      instructions: instructions,
      cats: [
        ["stability", "change"],
        ["meaning", "money"],
      ],
      results: [],
      selected: [],
      //fields: ['title', 'formattedUrl'],
      reduced: [
        { stability: 14 },
        { change: 10 },
        { meaning: 17 },
        { money: 11 },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentPage",
      "getCurrentTagRef",
      "getFlow",
      "getCurrentAuthUser",
      "getUID",
      "getCurrentAuthUserName",
      "getAllFlowNumber",
      "getIndustryTags",
      
      "getExperiences",
      "getOnboardingCollection",
      "getList",
      "getCompanyList",
      "getQuestionnaires",
      "getUserProfile",
    ]),
    getFirstName() {
      return this.getCurrentAuthUserName.split(" ")[0];
    },
    getPercentage() {
      let count = 0;
      this.done.forEach((el) => {
        if (el === true) {
          count++;
        }
      });

      let percentage = Math.round((count / 6) * 100);
      return `${percentage}%`;
    },

    onboarding() {
      return this.$store.getters.getOnboarding;
    },
    categories() {
      return this.cats.map((pair, i) =>
        pair.map((category, j) => ({
          [category]: this.reduced.find((rd) => rd[category])[category],
        }))
      );
    },
    
    calculateResults() {
      const counts = {};
      let total = 0;
      /*const mapped = arr
          .filter((el) => el.option.choice === true)
          .map((el) => el.option.option)
          .filter(Boolean);*/

      let counted = this.categories.forEach((x) => {
        counts[x] = (counts[x] || 0) + 1;
        total = total + 1;
      });
      return { counts, total };
    },
  },
  methods: {
   /* askGoogleForHelp(){
            axios
        .get(`https://www.googleapis.com/customsearch/v1?key=AIzaSyDtj1YLV3jSVy2S0i_PHdJFJTctncST_v8&cx=d3047a9fdbc1b4b48&q=${this.searchName}`)
        .then((res) => {
             console.log(res.data.items)
             this.results = res.data.items
        })
        .catch((error) => {
          console.error(error);
        });
    },
    removeItem() {
      this.selected = [];
    },
    getWikipediaResults() {
      let vm = this;
      vm.message = "";
      axios
        .get("https://en.wikipedia.org/w/api.php", {
          params: {
            format: "json",
            action: "query",
            generator: "search",
            gsrnamespace: 0,
            gsrsearch: this.value,
            gsrlimit: 10,
            prop: "pageimages|extracts",
            pilimit: "max",
            piprop: "original",
            exintro: true,
            explaintext: true,
            exsentences: 1,
            exlimit: "max",
            origin: "*",
          },
        })
        .then((res) => {
          if (res.data.query && res.data.query.pages) {
            vm.articleList = res.data.query.pages;
            vm.message = "";
          } else {
            vm.message = "No Articles Found";
            vm.articleList = [];
          }
        })
        .catch((error) => {
          console.error(error);
        });

      this.results = Object.values(this.articleList).map((el) => ({
        title: el.title,
        extract: el.extract,
        pageid: el.pageid,
      }));
    },
    onRowSelected(items) {
      this.selected = items;
    },*/

    toDate(date_string) {
      if (date_string === null) {
        return new Date();
      } else {
        let year = date_string.split("-")[0];
        let month = date_string.split("-")[1] - 1;
        let new_date = new Date(year, month, "01");
        return new_date;
      }
    },
    diffBetweenDates(start_date, end_date) {
      start_date = this.toDate(start_date);
      end_date = this.toDate(end_date);
      let total_time_ms = new Date(end_date - start_date);
      let years = total_time_ms.getYear() - 70;
      let months = total_time_ms.getMonth() + years * 12;
      return months;
    },
    combineQuestionnaires(){
      this.allquestionnaires = 
      Object.values(this.$store.getters.getWebsiteData['questionnaires']).flat().sort((a,b) => a.sid - b.sid)
    },
    timeAtCompany(company_name, duration_arr) {},
    /*
      calculateResults(arr) {
        const counts = {};
        let total = 0;
        const mapped = arr
          .filter((el) => el.option.choice === true)
          .map((el) => el.option.option)
          .filter(Boolean);

        let counted = mapped.forEach((x) => {
          counts[x] = (counts[x] || 0) + 1;
          total = total + 1;
        });
        return { counts, total };
      },
  */
    /*  calculateBiValence(responseTaxonomies, responses) {
        const counts = {};
        let total = 0;
        let results = responseTaxonomies.forEach((sn) => {
          responses.forEach((ans) => {
            counts[sn] = ans[sn] + (counts[sn] || 0);
            total = ans[sn] + total;
          });
        });
        let countExists = Object.values(counts).filter(Boolean);
        return { counts, total };
      },

      //MOST (ALL?) NEED THIS
      getMax(object) {
        let maxValue = Math.max(...Object.values(object));
        let maxName = Object.keys(object).filter(
          (key) => object[key] == maxValue
        );
        let maxIndex = Object.entries(object)
          .map((el) => el[0])
          .findIndex((obj) => obj === maxName[0]);
        return { maxValue, maxName, maxIndex };
      },

      //SOME NEED THIS
      calculateRangeValue(total, max) {
        if (!max.maxIndex) {
          return max.maxValue;
        } else {
          return 100 - max.maxValue;
        }
      },*/
    onSubmit() {
      /*const websiteRef = doc(websiteCollection, "content");
      const humanRef = collection(websiteRef, "questionnaires");
      const docRef = setDoc(
        doc(humanRef, "human-being-questions"),
        {
          "human-being-questions": HumanBeingQuestions,
          created: serverTimestamp(),
        },
        { merged: true }
      );*/
      const txtRef = doc(db, "website", "questionnaires");
      this.allquestionnaires.forEach((el) => {
        let name = el.section;
        updateDoc(txtRef, { [name]: el });
      });
    },
  },
  watch: {
    searchName(){

    },
    value() {
      this.getWikipediaResults();
    },
  },
  created() {
    this.combineQuestionnaires()
  }
};
</script>

<style scoped>
.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}
.text-right {
  float: right !important;
}
.btn-outline-purple:hover,
.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}

.btn-purple {
  background-color: #9a5bd2;
  color: white;
  width: 40% !important;
  height: 10em !important;
  line-height: 1.5 !important;
}
.btn-purple:hover,
.btn-purple:active {
  background-color: white;
  color: #9a5bd2;
  border-color: #713e9e;
}

.knowledge {
  border: 1em solid #713e9e;
  margin-top: 10em !important;
}

#how-we-know {
  margin-top: 10em !important;
}

@media only screen and (max-width: 599px) {
  /* For mobile phones: */
  .btn-purple {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 0.01em;
    padding-left: 0.01em;
    font-size: smaller;
    height: 20em !important;
    width: 48% !important;
  }
  .knowledge {
    font-size: smaller !important;
  }
}

.madlib {
  border-bottom: 3px solid #713e9e;
}

.about-you {
  line-height: 1.6;
}
#flying{
  z-index: 1000;
  position: relative;
}
</style>
