<template>
  <div>
    <b-container>
            <div>
              <b-row
                :class="{
                  switchbackground: checkHighlighted
                }"
                class="mt-4"
                ><b-col cols="1" class="mr-3 mt-2" @click="highlight()">
                  <b-icon
                    v-if="cat !== 'insight'"
                    :icon="checkHover"
                    font-scale="3"
                  ></b-icon>

                  <b-img
                    v-if="cat === 'insight'"
                    :src="checkLogoColor(i)"
                  ></b-img>
                  <b-icon
                    icon="arrow-up"
                    class="text-center mx-auto"
                  ></b-icon> </b-col
                ><b-col cols="10"
                  >{{name}}<div class="text-left mt-2 fact" v-html="data.phrase"></div>
                </b-col>
                <b-row>
                  <b-col
                    cols="8"
                    v-if="
                      checkHighlighted &&
                      type !== 'length' &&
                      category === 'external'
                    "
                    class="mt-4 ml-4 whiteOnPurple"
                  >
                    <h5>Do we get bad information? Fix the record below!</h5>
                    <b-form inline
                      ><b-form-input
                        class="fixText"
                        v-model="fixedText"
                        :placeholder="data.data.toString()"
                      ></b-form-input>
                      <b-button
                        squared
                        class="ml-0"
                        @click="fixData()"
                        variant="outline-purple"
                        >Change</b-button
                      >
                    </b-form>
                  </b-col>
                </b-row>
                <b-row
                  v-if="checkHighlighted && options"
                  class="mt-5"
                >
                   <b-row v-if="checkHighlighted && options">
                  <b-container
                    ><h5 class="ml-5">
                      <div v-if="overview !== undefined">
                        {{overview}}
                        <hr>
                      </div>
                      Where would you place yourself on the spectrum from
                      <b>{{ options[0] }}</b> to <b>{{ options[1] }}</b
                      >? 
                    </h5></b-container
                  >
                </b-row>
                  <h5 class="ml-3"></h5>
                  <b-form-input
                    class="range"
                    variant="purple"
                    id="range-1"
                    v-model="value"
                    type="range"
                  ></b-form-input>
                </b-row>
                <div
                  :class="{
                    switchbackground: checkHighlighted,
                  }"
                  v-if="
                    checkHighlighted && getAllOccurences
                  "
                >
                  <h5 class="ml-3 mr-5">
                    Are any of these incorrect? Click on the
                    <b-icon icon="x-circle"></b-icon> to remove them.<br /><br />
                  </h5>
                  <div v-for="(item, q) in getAllOccurences" :key="q">
                    <b-icon
                      @click="removeItem(q)"
                      class="ml-5 mr-1"
                      icon="x-circle"
                    ></b-icon
                    ><span v-html="item"></span>
                  </div>
                  
                </div>
                <div
                  :class="{
                    switchbackground: checkHighlighted
                  }"
                  v-if="
                    type === 'length' && checkHighlighted
                  "
                >
                  <h5 class="ml-3 mr-5">
                    Are any of these incorrect? Click on the
                    <b-icon icon="x-circle"></b-icon> to remove them.<br /><br />If
                    any of them are correct, but you really don't want to be
                    associated with them, feel free to click the Archive
                    <b-icon icon="archive"></b-icon> to hide it.
                  </h5>
                  <div v-for="(item, q) in data.arr" :key="q">
                    <b-icon
                      @click="removeItem(q)"
                      class="ml-5 mr-1"
                      icon="x-circle"
                    ></b-icon
                    ><b-icon
                      @click="archiveItem(q)"
                      class="ml-1 mr-4"
                      icon="archive"
                    ></b-icon
                    ><span v-html="item"></span>
                  </div>
                  
                </div>
                <b-row v-if="checkHighlighted">
                
              <b-col cols="2" class="ml-4"
                    ><div class="text-left" v-if="options">
                      {{ options[0].charAt(0).toUpperCase() + options[0].slice(1) }}
                    </div></b-col
                  ><b-col cols="2" offset="7"
                    ><div class="text-right" v-if="options">
                      {{ options[1].charAt(0).toUpperCase() + options[1].slice(1) }}
                    </div></b-col
                  ></b-row
                >
              </b-row>
              <hr />
            </div>
    </b-container>
  </div>
</template>

<script>
import beforePunctuation from "@/assets/beforePunctuation.png";
import beforePunctuationWhite from "@/assets/beforePunctuation_white.png";
import beforePunctuationWhiteInverse from "@/assets/beforePunctuation_white_inverse.png";
export default {
  data() {
    return {
      showFix: false,
      fixedText: "",
      value: 50,
      logoColor: beforePunctuation,
      dataArr: [],
      isHovered: false,
      isHoveredi: "",
      isHoveredChart: false,
    };
  },
  props: [
    "i",
    "texts",
    "data",
    "nid",
    "name",
    "prefix",
    "words",
    "factBoxData",
    "pretext",
    "options",
    "type",
    "highlighted",
    "arrayorder",
    "show",
    "category",
    "filter",
    "cat",
    "rangeValue",
    "overview"
  ],
  methods: {
    checkArrayOrder(order) {
      if (this.arrayorder === order) {
        return true;
      } else return false;
    },
    checkLogoColor(order) {
      if (this.checkArrayOrder(order) && this.checkHighlighted) {
        return beforePunctuationWhite;
      } else if (this.isHovered && this.isHoveredi === order) {
        return beforePunctuationWhiteInverse;
      } else {
        return beforePunctuation;
      }
    },
    highlight() {
      if (this.checkHighlighted) {
        this.$emit("setHighlight", "", "");
      } else {
        this.$emit("setHighlight", this.name);
      }
    },
    removeData() {
      this.$emit("remove", this.name);
    },
    fixData() {
      this.$emit("fix", this.name, this.fixedText);
    },
    fixRangeValue() {
      this.$emit("fixRangeValue", this.name, this.value);
    },
    removeItem(i) {
      this.$emit("removeItemFromList", this.name, i);
    },
    archiveItem(i) {
      this.$emit("removeItemFromList", this.name, i);
    },
    checkArray(arr) {
      if (Array.isArray(arr)) {
        return true;
      }
      return false;
    },
  },
  computed: {
    getAllOccurences() {
      if (
        this.$store.getters.getTopTerms.filter((el) => el.term === this.name).length > 0
      ) {
       return Object.entries(this.$store.getters.getAllChoices)
          .map((el) => ({ name: el[0], data: el[1] }))
          .filter((el) => Array.isArray(el.data)).flat().map(el => ({...el.data, name: el.name })).map((el, i) => Object.values(el)).filter(el => el).flat().filter(el => el[this.name]).map(el => el.title)
      } else return null;
    },
    checkData() {
      if (typeof this.data !== "undefined") {
        return this.data;
      } else return null;
    },
    beforeLogo() {
      //console.log(this.highlighted)
      return beforePunctuation;
    },
    checkHover() {
      if (this.isHovered) {
        if (this.cat === "resume") {
          return "file-text-fill";
        } else if (this.cat === "statement") {
          return "file-ruled-fill";
        }
        return "file-text-fill";
      }
      if (!this.isHovered) {
        if (this.cat === "resume") {
          return "file-text";
        } else if (this.cat === "statement") {
          return "file-ruled";
        }
        return "file-text";
      } else return "file-text";
    },
    checkHoverChart() {
      if (this.isHoveredChart) {
        return "grid3x3-gap-fill";
      } else return "grid3x3-gap";
    },
    getDataArray() {
      if (Object.prototype.hasOwnProperty.call(this.data, "arr")) {
        if (this.data.arr !== null) {
          return this.data.arr;
        } else return this.data.arr;
      } else return this.data.arr;
    },
    checkHighlighted() {
      if (this.highlighted === this.name) {
        return true;
      } else return false;
    },
    checkFilter() {
      if (this.filter === "") {
        return true;
      } else if (this.filter === this.cat) {
        return true;
      } else if (this.filter !== this.cat) {
        return false;
      }
      return false;
    },
  },
  watch: {
    value() {
      this.fixRangeValue();
    },
  },
  mounted() {
    this.value = this.rangeValue;
  },
};
</script>
<style>
.btn-purple-dropdown {
  font-size: 1rem !important;
  color: #713e9e !important;
  font-family: "Merriweather", serif;
  background: white;
}

.under {
  border-bottom: #713e9e solid 0.25em;
  padding-bottom: 0.1em !important;
}
.text-left.mt-2.fact {
  font-size: 1.5rem;
  line-height: 1.3;
  margin-left: 0.5em;
}
@media only screen and (max-width: 800px) {
  /* For mobile phones: */
  .btn-purple-dropdown {
    font-size: medium !important;
  }
  .text-left.mt-2.fact {
    font-size: 1.2rem;
    line-height: 1.3;
    margin-left: 0.5em;
  }
}
.row {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.left {
  float: left;
  padding-left: 0.5em;
}
.right {
  float: right;
  padding-right: 0.5em;
}
.switchbackground {
  background: #713e9e !important;
  color: white;
  min-height: 15rem !important;
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}
.whiteOnPurple {
  color: white;
}
.range {
  margin-left: 3em !important;
  margin-right: 3em !important;
}
.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}
.btn-outline-purple:hover,
.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}
button.btn.ml-0.btn-outline-purple.rounded-0 {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
  margin-left: -3em !important;
}
button.btn.ml-0.btn-outline-purple.rounded-0:hover,
button.btn.ml-0.btn-outline-purple.rounded-0:active {
  color: #fff;
  background-color: #713e9e;
  border-color: white;
}

input.fixText {
  width: 80% !important;
}
</style>
