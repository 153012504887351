<template>
  <div>
      <b-container>
 <h4  class="mt-2 mb-3">Company Dashboard for "Save the Sloths Foundation"</h4>
<hr class="mt-2 mb-3">

          <b-card-group class="mt-4" deck>
            <b-card class="mt-2 mb-3">
                  <b-card-header>Active Roles</b-card-header> <b-card-body><b-card-title>
        3
      </b-card-title>   <b-list-group flush>
      <b-list-group-item button class="d-flex justify-content-between align-items-center">Marketing Manager <b-badge variant="purple" pill>12</b-badge></b-list-group-item>
      <b-list-group-item button class="d-flex justify-content-between align-items-center">VP of Communications <b-badge variant="purple" pill>7</b-badge></b-list-group-item>
      <b-list-group-item button class="d-flex justify-content-between align-items-center">Senior Engineer <b-badge variant="purple" pill>9</b-badge></b-list-group-item>
    </b-list-group></b-card-body><b-card-footer>
        These are the roles that you are actively looking for candidates to fill.
      </b-card-footer> </b-card>
            <b-card class="mt-2 mb-3">
                  <b-card-header>Suggestions</b-card-header> <b-card-body><b-card-title>
        35
      </b-card-title>    <b-list-group flush>
      <b-list-group-item button class="text-left">Marketing Manager Candidate #6</b-list-group-item>
      <b-list-group-item button class="text-left">Marketing Manager Candidate #7</b-list-group-item>
      <b-list-group-item button class="text-left">Senior Engineer Candidate #5</b-list-group-item>
    </b-list-group></b-card-body><b-card-footer>
        These are some suggestions of candidates we think you'd like to have a conversation with.
      </b-card-footer></b-card>
            <b-card class="mt-2 mb-3">
                  <b-card-header>Pending Conversations</b-card-header> <b-card-body><b-card-title>
        5
      </b-card-title></b-card-body>    <b-list-group flush>
      <b-list-group-item button class="text-left">Marketing Manager Candidate #6</b-list-group-item>
      <b-list-group-item button class="text-left">Senior Engineer Candidate #5</b-list-group-item>
      <b-list-group-item button class="text-left">VP of Communications Candidate #3</b-list-group-item>
    </b-list-group><b-card-footer>
        These are the candidates that you have expressed interest in having a conversation with, but who have not yet confirmed reciprocal interest.
      </b-card-footer></b-card>
            <b-card class="mt-2 mb-3">
                  <b-card-header>Active or Completed Conversations</b-card-header> <b-card-body><b-card-title>
        25
      </b-card-title></b-card-body>    <b-list-group flush>
      <b-list-group-item button class="text-left">Jax Doe - Marketing Manager Candidate #3</b-list-group-item>
      <b-list-group-item button class="text-left">Janet Doe - Senior Engineer Candidate #2</b-list-group-item>
      <b-list-group-item button class="text-left">Jill Doe - VP of Communications Candidate #3</b-list-group-item>
    </b-list-group><b-card-footer>
              These are the candidates that you both have mutually agreed to have a conversation with.
      </b-card-footer></b-card>
                        <b-card class="mt-2 mb-3">
                  <b-card-header>Conversation Credits Used</b-card-header> <b-card-body><b-card-title>
        25
      </b-card-title></b-card-body><b-card-footer>
        This is great traction for this month!
      </b-card-footer></b-card>
      <b-card class="mt-2 mb-3">
                  <b-card-header>Conversation Credits Remaining</b-card-header> <b-card-body><b-card-title>
        50
      </b-card-title></b-card-body><b-card-footer>
        Need more credits this month? Click here.
      </b-card-footer></b-card></b-card-group>
          </b-container>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.card{
   min-width: 40% !important;
   border-color: #713e9e;
}
.badge-purple{
  background: #713e9e;
  color: white;
  width: 4em;
  font-size: 1em;
  padding-bottom: 8px
}
</style>