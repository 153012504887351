<template>
  <div class="button-panel">
    <div>
      <b-row>
        <hr>
        <b-col class="mx-auto">
          <b-col sm="12" md="12" v-for="option in options" :key="option.oid">
          <b-row class="mx-auto">
              <b-button
              class="mt-2 mb-2 pt-4 h-100 text-left"
              size="md"
              variant="purple"
              :to="option.link"
              ><b-col
                >{{option.order}}. <b>{{option.title}}</b>
                <hr class="light">
                <p class="mt-2">
                   {{option.description}}
                </p></b-col
              ><b-col
                ><b-icon v-if="checkCompleted(option.computedName)"
                  class="text-right mt-2 mb-2 mx-auto"
                  icon="bookmark-check"
                ></b-icon
              ></b-col>
            </b-button>
           
          </b-row>
           </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Options from "@/assets/options.json"
import _has from "lodash/has";
export default {
  props: ["completed"],
  data() {
    return {
      options: [],
      pdl: [],
      experiences: [],
      generalQuestions: [],
      specificQuestions: [],
      career: [],
      skills: [],
      interests: [],
      blink: [],
      industries: [],
    };
  },
  computed: {
    ...mapGetters([
      "getCurrentPage",
      "getCurrentTagRef",
      "getFlow",
      "getCurrentAuthUser",
      "getUID",
      "getCurrentAuthUserName",
      "getAllFlowNumber",
      "getIndustryTags",
      
      "getExperiences",
      "getOnboardingCollection",
      "getList",
      "getOptions",
    ]),
  },
  methods: {
    setOptions(){
        this.options = Options;
    },
    checkCompleted(option){
      let isCompleted = this.completed.find(el => el === option);
      return isCompleted
    },
    generalQuestionsData() {
      this.generalQuestions =
        this.$store.getters.getOnboardingCollection("general-questions");
    },
    specificQuestionsData() {
      this.specificQuestions =
        this.$store.getters.getOnboardingCollection("specific-questions");
    },
    blinkData() {
      this.blink =
        this.$store.getters.getOnboardingCollection("company-choices");
    },
    careerData() {
      this.career = this.$store.getters.getList("experiences");
    },
    skillsData() {
      this.skills = this.$store.getters.getList("skills");
    },
    industriesData() {
      this.industries = this.$store.getters.getList("industries");
    },
    start() {
      this.setOptions()
      //this.industriesData();
      //this.skillsData();
      //this.careerData();
      //this.blinkData();
      //this.specificQuestionsData();
      //this.generalQuestionsData();
    },
  }
  ,
  created() {
    this.start();
  },
  updated() {
    this.start();
  },
};
</script>

<style scoped>
.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}
.text-right {
  float: right !important;
}
.btn-outline-purple:hover,
.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}

.btn-purple {
  background-color: #713e9e;
  color: white;
  line-height: 1.5 !important;
}
.btn-purple:hover,
.btn-purple:active {
  background-color: white;
  color: #713e9e;
  border-color: #713e9e;
}

.btn-purple hr{
  border-top: 2px white solid !important;
} 
.btn-purple:hover hr{
  border-top: 2px #713e9e solid !important;
} 
@media only screen and (max-width: 800px) {
  /* For mobile phones: */
  .btn-purple {
    padding-top: 1em;
    padding-bottom: 2em;
    padding-right: 0.01em;
    padding-left: 0.01em;
    font-size: medium;
  }
  .knowledge {
    font-size: smaller !important;
  }
}


</style>
