<template>
  <div class="header">
      <b-container fluid>
      <h3><b>{{title}}</b></h3>

      <br />
      <hr />
      </b-container>
  </div>
</template>

<script>
export default {
    props:["step","title", "time"]

}
</script>

<style scoped>
.btn-purple {
  background-color: #9a5bd2;
  color: white;
}
.btn-purple:hover,
.btn-purple:active {
  background-color: white;
  color: #9a5bd2;
  border-color: #713e9e;
}
hr{
    width: 50%
}
</style>