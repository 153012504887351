<template>
  <div>
    <b-container>
      <hr />
      <div class="accordion" role="tablist">
        <div v-for="(category, i) in categories" :key="i">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle="'collapse-' + [category]"
                @click="
                  (setCategory = category), (setSection = ''), (selected = [])
                "
                variant="purple"
                class="text-left"
                >{{ category }}</b-button
              >
            </b-card-header>
            <b-collapse
              :id="`collapse-` + [category]"
              accordion="my-accordion-category"
              role="tabpanel"
              class="mt-2"
            >
              <b-card
                v-for="(section, j) in Object.keys(textData[category]).sort()"
                :key="j"
              >
                <b-button
                  block
                  v-b-toggle="'collapse-' + [section]"
                  @click="
                    (setCategory = category),
                      (setSection = section),
                      (selected = [])
                  "
                  variant="outline-purple"
                  class="text-left"
                  >{{ section }}</b-button
                >

                <b-collapse
                  :id="`collapse-` + [section]"
                  accordion="my-accordion-section"
                  role="tabpanel"
                  class="mt-2"
                >

             <div class="text-left" v-for="(key, k) in Object.keys(textData[category][section]).sort()" :key="k">
                <ClickToEdit :value="textData[category][section][key]" :source="textData" :cat="category" :sect="section" :item="key" :index="k"></ClickToEdit>
             </div>
               
                </b-collapse>
              </b-card>
            </b-collapse>
          </b-card>
        </div>
      </div>
      <hr />
    </b-container>
  </div>
</template>

<script>
import {
  collection,
  getDoc,
  getDocs,
  doc,
  db,
  serverTimestamp,
  addDoc,
  setDoc,
  query,
  where,
} from "@/firebase";
import { websiteCollection } from "@/db";
import ClickToEdit from "@/components/interfaces/CustomClickToEdit.vue"
export default {
    components: {ClickToEdit },
  data() {
    return {
        edit: false,
      selected: [],
      selectedOID: null,
      currentEdit: {},
      setCategory: "",
      setSection: "",
      fields: [
        { key: "1", label: "Data" }
      ],
      textData: {},
      prompts: [],
    };
  },
  methods: {
    editData(cat, sect, index, data) {
      //console.log(cat, sect, index, data)
    },
    sections(cat) {
      return Object.keys(this.textData[cat]);
    },
    onRowSelected(items) {
      this.selected = items;
    },
    onRowSelectedOID(items) {
      this.selectedOID = items[0].oid;
    },
    changeText(cat, sect, index, item, edit) {
      this.textData[cat][sect][item] = edit;
      console.log(this.textData[cat][sect]);
    },
    getData(cat, sect) {
      try {
        if (
          typeof Object.entries(this.textData[cat][sect])[0][1] === "object" &&
          Object.entries(this.textData[cat][sect])[0][0].length < 2
        ) {
          return Object.entries(this.textData[cat][sect])
            .map((el) => el[1])
            .map((ex) => ({ 0: ex.section, 1: ex.prompt, 2: ex.sid }));
        } else return Object.entries(this.textData[cat][sect]);
      } catch {
          console.log()
      }
    },
  },
  computed: {
    checkNotEmpty() {
      if (
        this.setCategory === "" ||
        this.setSection === "" ||
        this.selected.length === 0
      ) {
        return false;
      }
      return true;
    },
    checkOptionNotEmpty() {
      if (
        this.setCategory === "" ||
        this.setSection === "" ||
        this.selected.length === 0 ||
        this.selectedOID === null
      ) {
        return false;
      }
      return true;
    },
    categories() {
      return Object.keys(this.textData);
    },
    getOptions() {
      return Object.entries(this.textData[this.setCategory][this.setSection])
        .map((el) => el[1])
        .filter((el) => el.section === this.selected.map((el) => el[0])[0])
        .map((el) => el.options)
        .flat()
        .filter(Boolean);
    },
  },
  created() {
    this.textData = this.$store.getters.getWebsiteText;
  //this.$store.dispatch("syncEdits", {})
  },
};
</script>

<style scoped>
button.btn.btn-secondary.rounded-0 {
  width: 20em !important;
  border-bottom: 3px solid black;
}
.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}
.text-right {
  float: right !important;
}
.btn-outline-purple:hover,
.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}

.btn-purple {
  background-color: #9a5bd2;
  color: white;
  line-height: 1.5 !important;
}
.btn-purple:hover,
.btn-purple:active {
  background-color: white;
  color: #9a5bd2;
  border-color: #713e9e;
}
</style>
