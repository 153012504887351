<template>
  <div>
    <b-container>
   <b-col sm="12" md="6" offset-md="3">
        <b-card header="Login" header-bg-variant="purple" class="mx-auto">
          <b-card-body>
            <div v-if="error" class="alert alert-danger">{{ error }}</div>
            <div v-if="success" class="alert alert-success">{{ success }}</div>
            <b-form @submit="onSubmit" @submit.prevent v-if="show">
              <b-form-group
                label-for="email"
                label="Please enter the email associated with your Before profile:"
              >
                <b-form-input
                  id="email"
                  type="email"
                  class="form-control input-email"
                  name="email"
                  debounce="500"
                  required
                  v-model="email"
                ></b-form-input>

                <b-button type="submit" variant="purple" class="mt-3">
                  Get Link To Login
                </b-button>
              </b-form-group>
            </b-form>
          </b-card-body>
        </b-card>
   </b-col>
    </b-container>
    <div v-if="success" class="home-footer">
   <b-row><b-col sm="12" md="6" offset-md="3"><p class="text-left">Because it doesn't make any sense to anyone besides the person who wrote it, "cookies" are little files left on browsers (which we do not use, besides briefly during the login process, which we promptly delete). Cookie monster eats cookies, but if you've ever seen him eat, he makes a lot of crumbs. Which is one of the annoying side aspects of the passwordless sign-in, that a tab is left open. So like a Roomba, we automatically clean it up for you after you leave the page.</p></b-col>
  </b-row><b-row><b-col sm="12" md="6" offset-md="3">
   <hr class="white-hr">
   <p>No llamas were hired in the creation of this website.</p>
   <p>All rights reserved.</p><p><b>© Before 2022</b></p></b-col></b-row> </div>
  </div>
</template>

<script>
import {
  auth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signOut,
  collection,
  addDoc,
  db,
  doc,
  getDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
} from "@/firebase";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      email: "",
      UID: "",
      error: null,
      success: null,
      show: true,
    };
  },

  asyncComputed: {
    async getUIDfromEmail() {
      if (this.checkEmail()) {
        this.success = "";
        this.error = "";
        const q = query(
          collection(db, "users"),
          where("email", "==", this.email)
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.docs.length > 0) {
          // console.log(querySnapshot.docs[0].data())
          this.UID = querySnapshot.docs[0].data().candidateUID;
          return true;
        } else {
          this.UID = "";
          return false;
        }
      }
    },
  },
  methods: {
    checkEmail() {
      const emailValidate = /^\S+@\S+\.\S+$/;
      return this.email.match(emailValidate) ? true : false;
    },
    autoClose() {
      console.log("this is running!!!!")
      window.open("", "_self", "").close();
    },
    onSubmit() {
      //console.log(this.UID)
      if (this.UID.length > 0) {
        sendSignInLinkToEmail(auth, this.email, {
          url: `${location.origin}/loggedIn?uid=` + this.UID,
          handleCodeInApp: true,
        })
          .then(() => {
            window.localStorage.setItem("emailForSignIn", this.email);
            this.success =
              "Please check your email for an easy-to-click link to complete your login. (This tab will close itself automatically. It's like a Cookie Monster and a Roomba in one.)";
            this.error = "";
            this.show = false;
            setTimeout(function () {
             window.open("", "_self", "").close();
            }, 15000);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            this.email = "";
            this.error =
              "You typed an incorrect email address. Please try again.";
          });
      } else if (this.UID.length === 0) {
        this.email = "";
        this.error = "You typed an incorrect email address. Please try again.";
      }
    },
    logout() {
      signOut(auth)
        .then(() => {
          // Sign-out successful.
        })
        .catch((error) => {
          // An error happened.
        });
    },
    //  ...mapActions("user", ["setUserData"]),
  },
  computed: {
    checkCurrentUser() {
      const user = auth.currentUser;
      return user;
    },
  },
  /* nonUser(){
      if(!this.UID){
        return "You typed the an incorrect email address."
      }
     else return null
     }}
     /*checkIfUID(){
       if(this.UID.length > 0){
         console.log(this.UID.length)
         return true
       } else console.log(this.UID.length)
       return false
     }
     }*/
};
</script>
<style scoped>
.bg-purple {
  background-color: #713e9e;
  color: #fff;
}
.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}
.btn-outline-purple:hover {
  background-color: #9a5bd2;
  color: white;
}

.btn-purple:hover {
  color: #9a5bd2;
  background-color: #fff;
  border-color: #9a5bd2;
}
.btn-purple {
  background-color: #713e9e;
  color: white;
  width: 75%;
}

.input-email {
  width: 100% !important;
}
</style>
