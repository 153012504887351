<template>
  <div class="pt-3">
    <div v-if="getQuestion[0].id < 12">
      <b-container>
        <b-row class="justify-content-md-center">
          <div class="mb-1">
            <h3 v-html="getQuestion[0].question"></h3>
          </div>
        </b-row>
        <b-container>
          <div v-for="(response, i) in getQuestion[0].responses" :key="i">
            <b-row class="mb-1">
              <b-button
                v-on:click="current = response.destination"
                size="lg"
                squared
                variant="outline-dark"
                v-html="response.label"
              ></b-button>
            </b-row>
          </div>
        </b-container>
      </b-container>
    </div>
    <div v-if="getQuestion[0].id === 12">
      <b-container>
        <div class="question mb-3">
          <h3 v-html="getQuestion[0].title"></h3>
        </div>
      </b-container>
      <b-container>
        <b-row class="candidate-form">
          <b-col sm="6">
            <b-container>
              <div class="text-start">
                <h6>
                  We never show your LinkedIn profile to anyone, but using your
                  LinkedIn profile we are able to customize an onboarding
                  process that makes sense for you.
                </h6>
                <h6>
                  With <b>Before</b> you never need a resume or to input a ton
                  of information or figure out any margins or fonts. We're about
                  the conversation.
                </h6>
                <h6>
                  It's important to understand what you have done, but even more
                  important to know what you want to be.
                </h6>
                <h6>
                  Resumes sometimes make us try to be someone we are not, simply
                  because we want to make ourselves what we think that the
                  company wants.
                </h6>
                <h6>We want to get to know the real you. Really.</h6>
              </div></b-container
            >
          </b-col>
          <b-col sm="6">
            <b-container>
              <b-form @submit="onSubmit" v-if="show">
                <b-form-group id="input-group-2" label-for="input-2">
                  <b-row>
                    <b-form-input
                      id="input-2"
                      v-model="form.name"
                      placeholder="Your Name"
                      required
                    ></b-form-input>
                  </b-row>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label-for="input-1"
                  class="mt-2"
                >
                  <b-row>
                    <b-form-input
                      id="input-1"
                      v-model="form.email"
                      type="email"
                      placeholder="Your Email Address"
                      required
                    ></b-form-input>
                  </b-row>
                </b-form-group>

                <b-form-group
                  id="input-group-3"
                  class="mt-2"
                  label-for="input-3"
                >
                  <b-row>
                    <b-form-input
                      id="input-3"
                      v-model="form.linkedin"
                      type="url"
                      placeholder="Your LinkedIn URL"
                      required
                    ></b-form-input>
                  </b-row>
                </b-form-group>
                <b-row class="mt-3">
                  <b-button type="submit" variant="outline-light"
                    >Submit</b-button
                  >
                </b-row>
              </b-form>
              {{getCandidates}}
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-if="getQuestion[0].id === 13">
      <b-container>
        <div class="align-left mb-3">
          <h4 v-html="getQuestion[0].text"></h4>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import questions from "@/assets/questions.json";
import { doc, addDoc, collection, serverTimestamp } from "@/firebase";
import { usersCollection } from "@/db"
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      page: "Questions",
      questions: questions,
      form: {
        email: "",
        name: "",
        linkedin: "",
      },
      current: 1,
      currentCandidateQ: 1,
      show: true,
    };
  },
  computed: {
    getQuestion() {
      return this.questions.filter((question) => question.id === this.current);
    },
    ...mapGetters({getCandidates: "candidates/getCandidates"}),
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      

      const docRef = await addDoc(usersCollection, {
        email: this.form.email,
        name: this.form.name,
        linkedin: this.form.linkedin,
        type: "candidate",
        status: "interested",
        created: serverTimestamp(),
      }).then(this.current = 13)
     
    },
  }
};
</script>

<style scoped>.btn-outline-purple {
  color: #713e9e;
  background-color: #fff;
  border-color: #713e9e;
}

.btn-outline-purple:hover,
.btn-outline-purple.active {
  color: #fff;
  background-color: #713e9e;
  border-color: #713e9e;
}
</style>
