<template>
  <div id="app">
    <div id="nav">
      {{getCurrentPage}}
<Navbar v-if="!$route.meta.hideNavbar" ></Navbar>
    </div>
    <router-view :key="$route.fullPath" class="mt-5"></router-view>
  </div>
</template>
<script>
import Navbar from "@/components/sections/Navbar.vue";
export default {
  components: {
    Navbar,
  },
    computed:{
    getCurrentPage () {
    return this.$store.state.currentPage
}
  },
  beforeCreate(){
     this.$store.dispatch("downloadWebsiteText");
  }
}
  </script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Alegreya&family=Merriweather:wght@300;700;900&display=swap');

html, body {
    height: 100%;
}

#app {
  font-family: 'Merriweather', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: white;
  color: #713E9E;
    height: auto !important;
    min-height: 100vh;
    position: relative;
  /*  overflow: scroll;*/
    overflow-y: scroll;
    overflow-x: hidden;
  /*color: #2c3e50;*/
}

#nav {
  padding: 5px;
  background-color: #713E9E;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.logo{
  width: 9rem !important;
}

.sr-only {
  display: none !important;
}
.links a{
  color: white;
}

.white-hr{
  background: white;
  margin-right: 2em;
  margin-left: 2em;
  margin-top: 2em;
}

.home-footer{
padding: 1.5em;
  margin-top: 15rem;
  color: #fff;
  background-color: #713e9e;
      bottom: 0;
    width: 100%;
}

@media only screen and (min-width: 600px) {
 .home-footer{
position: absolute;
}
}
</style>